import { useState, useEffect } from 'react'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import TableHeader from './TableHeader'
import CustomerInvoiceRows from './CustomerInvoiceRows'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import MessageBanner from '../../../../components/MessageBanner'
import { invoiceTableHeaders } from './inviceTableHeaders'
import { BASEURL } from '../../../../global-resources'
import { Link } from 'react-router-dom'
import WeeklyInvoices from './WeeklyInvoices'

const CreateCustomerInvoices = () => {
	const [editedFields, setEditedFields] = useState([])
	const [submitLoading, setSubmitLoading] = useState(false)
	const [offset, setOffset] = useState(0)
	const [pageLimit, setPageLimit] = useState(75)
	const [responsesMessage, setResponseMessage] = useState([])
	const [loading2, setLoading2] = useState(false)
	const { authFetch } = FetchWithAuth()

	const [sums, setSums, setReload, loading, onLine] = useInitialLoad(
		`/carriers/dhl/customer_invoices/sums?sent=false` // false in production
	)

	const [invoiceDates, setInvoiceData] = useInitialLoad(
		`/carriers/dhl/customer_invoices/grouped?field=billed_date`
	)
	const [openLineCount, setOpenCount, setReload3] = useInitialLoad(
		`/carriers/dhl/customer_invoicelines/aggregates`
	)

	const fetchNextPage = (offset) => {
		if (offset >= 0) {
			setOffset(offset)
			// setReload2((prev) => prev + 1)
		}
	}

	const submitCorrectedCells = async () => {
		const allResponses = await Promise.allSettled([
			editedFields.map(
				async (invoice) =>
					await authFetch(`${BASEURL}/carriers/dhl/customer_invoices/${invoice.id}`, {
						m: 'PATCH',
						b: JSON.stringify(invoice),
						h: { 'Content-Type': 'application/json' },
					})
			),
		])

		allResponses[0].value.forEach((promise, index) =>
			promise.then((res) => {
				if (res.ok) {
					setEditedFields([])
					setResponseMessage(['Successfully Updated'])
				} else {
					setResponseMessage([res.statusText])
				}
			})
		)
	}

	async function handleCreateInvoice() {
		const response = await authFetch(`${BASEURL}/carriers/dhl/customer_invoices/create`, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
		})
		const jsonRes = await response.json()
		if (response.ok) {
			setResponseMessage('Successfully created Invoices')
			setReload((prev) => prev + 1)
			setReload3((prev) => prev + 1)
		} else {
			setResponseMessage(jsonRes[0])
		}
	}
	if (!onLine) {
		return (
			<MessageBanner
				message={['No Internet Connection']}
				setResponseMessage={setResponseMessage}
			></MessageBanner>
		)
	}

	return loading || !sums ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : (
		<div>
			<>
				{responsesMessage.length > 0 ? (
					<>
						<MessageBanner
							message={responsesMessage}
							setResponseMessage={setResponseMessage}
						></MessageBanner>
					</>
				) : null}
			</>
			{!openLineCount ? (
				'No Sums Data'
			) : (
				<>
					<div className="error-table_submit-button">
						<button
							className="wiggle-button center-button"
							onClick={submitCorrectedCells}
							disabled={editedFields.length < 1} // disable button if user never touched cell
						>
							<span className="bn54span">
								{submitLoading ? (
									<i className="fas fa-spinner check-icon loading-icon"></i>
								) : (
									<>Submit {editedFields.length} Invoice changes</>
								)}
							</span>
						</button>
					</div>
					<div className="create-invoice-container">
						<button disabled={!sums.length > 0} className="carrier-menu-buttons">
							<Link
								to={{
									pathname: '/carriers/dhl/create_quickbooks_invoices',
									state: { sums },
								}}
							>
								Quickbooks Invoice
							</Link>
						</button>
						<div>Open Lines:{openLineCount.open_lines}</div>
						<button
							disabled={openLineCount.open_lines == 0}
							className="carrier-menu-buttons"
							onClick={() => handleCreateInvoice()}
						>
							Create Invoices
						</button>
					</div>
					{sums.length < 1 ? null : (
						<div className="invoice-table ">
							<h3 className="table-header">Open Invoices</h3>
							<table>
								<TableHeader
									tableData={sums}
									headers={Object.keys(invoiceTableHeaders)}
									headerObject={invoiceTableHeaders}
								/>
								<CustomerInvoiceRows
									tableData={sums}
									headers={Object.keys(invoiceTableHeaders)}
									setEditedFields={setEditedFields}
									setResponseMessage={setResponseMessage}
									rowButtonName={'Send'}
									// setReload={setReload2}
									submitCorrectedCells={submitCorrectedCells}
									canHighlightRow={true}
									setSums={setSums}
								/>
							</table>
						</div>
					)}
					<h3 className="table-header" style={{ marginLeft: '1%' }}>
						Invoices History
					</h3>
					{invoiceDates.length < 1
						? null
						: invoiceDates.map((week) => {
								return (
									<WeeklyInvoices
										invoiceDates={invoiceDates}
										setEditedFields={setEditedFields}
										setResponseMessage={setResponseMessage}
										setReload={setReload}
										week={week}
									/>
								)
						  })}
				</>
			)}
		</div>
	)
}

export default CreateCustomerInvoices
