export let disputeTableHeaders = {
	invoice_line__invoice__inv_no: 'Invoice #',
	ship_date: 'Ship Date',
	invoice_line__customer_confirmation_number: 'Customer Conf #',
	invoice_line__delivery_confirmation_number: 'Delivery Conf #',
	invoice_line__shipping_service__code: "Service Code",
	charge_name: 'Charge Description',
	amount: 'Disputed Amount',
	stated_charge: 'Carrier Charge',
	correct_charge: 'A1S Charge',
	invoice_line__dhl_credits: 'DHL Credit',
}
