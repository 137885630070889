import { useRef } from 'react'

const OsmRateCardUpload = ({ setRateCardFile, rateCardFile, setErrors, setSuccess }) => {
	const manifestFileInputRef = useRef(null)

	const handleFileChange = (file) => {
		setRateCardFile([...rateCardFile, file[0]])
		setErrors('')
		setSuccess('')
	}

	const removeFile = () => {
		setErrors('')
		setSuccess(false)
		setRateCardFile([])
	}
	function rateUploadList(params) {
		return rateCardFile.map((file, index) => {
			return <div>{file.name}</div>
		})
	}

	return (
		<>
			<div className="file-upload-warper">
				<div
					className={'file-upload'}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<div className="upload-file">
						<label htmlFor="manifest-upload" className="custom-file-upload">
							{'Update'}
						</label>
						<input
							id="manifest-upload"
							type="file"
							accept=".csv,.xlsx"
							onChange={(e) => handleFileChange(e.target.files)}
							multiple
						/>
						<input id="manifest-upload" type="file"></input>
						<label className="file-name">
							{rateCardFile.length > 0 ? `${rateCardFile.length} files` : ' 0 Files '}
						</label>
						<label className="custom-file-upload" onClick={removeFile}>
							Cancel
						</label>
					</div>
				</div>
			</div>
			{!rateCardFile ? null : (
				<div className="rate-card-upload-list">{rateUploadList()}</div>
			)}
		</>
	)
}

export default OsmRateCardUpload
