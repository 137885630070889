import React, { useState } from 'react'
import GetHighlightedText from './GetHighlightedText'
import { Link } from 'react-router-dom'

function CustomerCards({ customers, searchInput, checked }) {
	let searchNameResults = customers.filter(
		(user) =>
			user.name.toLowerCase().includes(searchInput.toLocaleLowerCase()) &&
			user.active == checked
	)

	// WHEN EMAIL AND PHONE NUMBER DATA ARE COMING THROW UNCOMMENT
	// THE BELOW LINES AND MAP OVER THE (searchEmailResults instead of NameResults)
	// searchPhoneResults = searchNameResults.filter((user) =>
	// 	user.billing_contact_phone.includes(searchInput)
	// )

	// let searchEmailResults = searchPhoneResults.filter(user =>
	//     user.email.toLowerCase().includes(searchInput.toLocaleLowerCase()))

	return searchNameResults.map(
		({ id, name, billing_contact_email, billing_contact_phone }) => {
			return (
				<div key={id} class="customer-card">
					<div class="customer-data icon">
						<i class="fas fa-building cardIcon"></i>
					</div>

					<div class="customer-data">
						<Link to={`/customers/${id}`}>
							<GetHighlightedText text={name} highlight={searchInput} />
						</Link>
					</div>
					<div class="customer-data">
						<i class="fas fa-phone-alt cardIcon"></i>
						<a class="contact-data-link" href="tel:+05890000111">
							{billing_contact_phone}
						</a>
					</div>
					<div class="customer-data">
						<i class="fas fa-envelope cardIcon"></i>
						<a class="contact-data-link" href="mailto:YourName@YourSite.com">
							{billing_contact_email}
						</a>
					</div>
				</div>
			)
		}
	)
}

export default CustomerCards
