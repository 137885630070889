import React, { useState } from 'react'
import { AxisOptions, Chart } from 'react-charts'
import ResizableBox from './ResizableBox'

type invoiceInfo = {
	date: string
	amount: number
}

type Series = {
	label: string
	data: invoiceInfo[]
}

const CustomerInvoiceChart = ({ invoices }: any) => {
	const [offset, setOffset] = useState(0)
	const [limit, setLimit] = useState(53)
	const [maxOffset, setMaxOffset] = useState(invoices.length)
	const desirableData = invoices.map((invoice: any) => {
		return { amount: parseInt(invoice.total_charges), date: invoice.billed_date }
	})

	const data: Series[] = [
		{
			label: 'Invoice Amount',
			data: desirableData.slice(offset, offset + limit),
		},
	]
	const primaryAxis = React.useMemo(
		(): AxisOptions<invoiceInfo> => ({
			getValue: (datum) => datum.date,
		}),
		[]
	)

	const secondaryAxes = React.useMemo(
		(): AxisOptions<invoiceInfo>[] => [
			{
				getValue: (datum) => datum.amount,
			},
		],
		[]
	)
	const handleNext = () => {
		setOffset(offset + limit)
	}

	const handleBack = () => {
		setOffset(offset - limit)
	}

	return (
		<>
			<div className="pagination-effects">
				<button
					className="paginate-button"
					onClick={() => handleBack()}
					disabled={offset == 0}
				>
					Prev
				</button>
				<div className="paginate-page-number">
					Chart <b>{offset / limit + 1}</b> Of <b>{Math.ceil(maxOffset / limit)}</b>
				</div>
				<button
					className="paginate-button"
					onClick={() => handleNext()}
					disabled={offset + limit > maxOffset}
				>
					Next
				</button>
			</div>
			<ResizableBox>
				<Chart
					options={{
						data,
						primaryAxis,
						secondaryAxes,
					}}
				/>
			</ResizableBox>
		</>
	)
}

export default CustomerInvoiceChart
