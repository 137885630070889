import React, { createContext, useContext } from "react"
import { useAuth2 } from "./auth-context"

const UserContext = createContext()

const UserProvider = props => (
  <UserContext.Provider value={useAuth2().loggedIn} {...props} />
)

const useLoggedIn = () => useContext(UserContext)

export {useLoggedIn, UserProvider}