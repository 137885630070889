import React, { useState } from 'react'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import OsmInvoiceRows from './OsmInvoiceRows'
import OsmInvoicesHeaders from './OsmInvoiceHeaders'
import { Link } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import PopUpPage from '../../../../components/PopUpPage'
import NewInvoice from './NewInvoice'

const OsmInvoiceList = () => {
	const [invoices, setInvoices] = useInitialLoad('/osm/invoices')
	const [searchInput, setSearchInput] = useState('')
	const [checked, setChecked] = useState(true)

	return !invoices ? null : (
		<div className="osm-invoice-list">
			<h1>OSM Invoice</h1>

			<Link to="invoices/new" className="wiggle-button center-div">
				<button className="wiggle-button center-div">
					<span className="bn54span">Add Invoice</span>
				</button>
			</Link>

			<OsmInvoicesHeaders />
			<div className="invoice-cards">
				<OsmInvoiceRows invoices={invoices} searchInput={searchInput} />
			</div>

			<Switch>
				<Route path={`/carriers/osm/invoices/new`}>
					<PopUpPage>
						<NewInvoice setInvoices={setInvoices} />
					</PopUpPage>
				</Route>
			</Switch>
		</div>
	)
}

export default OsmInvoiceList
