import React from "react"
import { useHistory } from "react-router-dom"

function BackButton() {
  let history = useHistory()

  return (
    <div className="back-button-container">
      <button
        className="back-button backButton"
        onClick={() => history.goBack()}>
        <i className="fas fa-arrow-left"></i>
        Back
      </button>
    </div>
  )
}

export default BackButton
