import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import useInitialLoad from '../../hooks/useInitialLoad'
import CustomerCards from './components/CustomerCards'
import AddNewCustomer from './components/AddNewCustomer'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../components/ErrorFallback'

const CustomersPage = () => {
	const [customers, setCustomers] = useInitialLoad('/customers')
	const [searchInput, setSearchInput] = useState('')
	const [checked, setChecked] = useState(true)

	return !customers ? null : (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onReset={() => {
				setCustomers([])
			}}
		>
			<div className="customer-list">
				<div className="search-container">
					<div>
						<div>Search By Name</div>
						<input
							className="customer-search"
							type="text"
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
						/>
						<i className="fas fa-search"></i>
					</div>

					<div>
						<Link to="/customers/new">
							<button className="wiggle-button">
								<span className="bn54span">Add Customer</span>
							</button>
						</Link>
					</div>

					<div>
						<div>Active</div>
						<label className="switch">
							<input
								type="checkbox"
								checked={checked}
								onChange={(checked) => setChecked((checked) => !checked)}
							></input>
							<span className="slider round"></span>
						</label>
					</div>
				</div>

				<div>
					<div className="column-headers">
						<div className="column-headers-div">
							<div className="column-names icon"></div>
							<div className="column-names">Company Name</div>
							<div className="column-names">Billing Contact Number</div>
							<div className="column-names">Billing Email</div>
						</div>
					</div>

					<div class="customer-cards">
						<CustomerCards
							customers={customers}
							searchInput={searchInput}
							checked={checked}
						/>
					</div>
				</div>
				<Switch>
					<Route path={`/customers/new`}>
						<AddNewCustomer setCustomers={setCustomers} customers={customers} />
					</Route>
				</Switch>
			</div>
		</ErrorBoundary>
	)
}

export default CustomersPage
