import React, { useState } from 'react'
import TermPageUploadFile from './TermPageUploadFile'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import _ from 'lodash'
function AccordionChildren({
	service,
	term,
	handleDownload,
	setRateCardTerms,
	currentlyAdding,
	setCurrentlyAdding,
}) {
	const { authFetch } = FetchWithAuth()
	const [loading, setLoading] = useState(false)

	return (
		<div
			class={
				currentlyAdding.serviceID === service.id && currentlyAdding.termID === term.id
					? 'carrier-service clicked_accordion-row'
					: 'carrier-service'
			}
			key={term.id}
		>
			<div class="carrier-service-title service-data">
				{service.name} ({service.code})
			</div>

			{service.rate_card ? (
				<div
					class="carrier-tier-download"
					onClick={() => handleDownload(service.rate_card.id, service.name)}
				>
					Download
				</div>
			) : null}
			<TermPageUploadFile
				term={term}
				service={service}
				setRateCardTerms={setRateCardTerms}
				currentlyAdding={currentlyAdding}
				setCurrentlyAdding={setCurrentlyAdding}
			/>
		</div>
	)
}

export default AccordionChildren
