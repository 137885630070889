import React, { useState, useEffect } from 'react'
import invoicePage from './style/invoicePage.css'
import { Switch, Route } from 'react-router-dom'
import { useParams } from 'react-router'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import useInitialLoad from '../../../hooks/useInitialLoad'
import InfoSection from './components/InfoSection'
import LegacyInvoiceProcessButtons from './components/LegacyInvoiceProcessButtons'
import InvoiceProcessButtons from './components/InvoiceProcess'
import InvoiceTable from './components/InvoiceTable'
import MessageBanner from '../../../components/MessageBanner'

const InvoicePage = () => {
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	const url = `/api/dhl/invoices/${id}`
	const [info, setInfo] = useInitialLoad(url)
	const [errorMessage, setErrorMessage] = useState([])

	async function toggleProcessed() {
		const response = await authFetch(BASEURL + url, {
			m: 'PATCH',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ processed: !info.processed }),
		})
		if (!response.ok) {
			setErrorMessage(response.statusText)
		} else {
			const data = await response.json()
			setInfo(data)
		}
	}

	return !info ? (
		<h1>Loading...</h1>
	) : (
		<>
			{errorMessage.length > 0 ? (
				<MessageBanner
					type="error"
					message={errorMessage}
					calloutType="Error"
					setResponseMessage={setErrorMessage}
				/>
			) : null}
			<div class="info-actions">
				<InfoSection url={url} info={info} toggleProcessed={toggleProcessed} />
				<InvoiceProcessButtons />
			</div>

			<div className="new-invoice-lines" style={{ marginTop: '2%', marginRight: '2.7%' }}>
				<LegacyInvoiceProcessButtons />
			</div>

			<Switch>
				<Route path={`/carriers/dhl/invoices/${id}/show_unprocessed/:processName`}>
					<InvoiceTable url={url} invoiceId={id} />
				</Route>
			</Switch>
		</>
	)
}

export default InvoicePage
