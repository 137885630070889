import React, { useEffect, useState } from 'react'
import { FetchWithAuth } from '../../hooks/FetchWithAuth'
import { BASEURL } from '../../global-resources'
import { ScanEmailsBtn } from './InvoiceButtons'
import { InvoiceTable } from './InvoiceTable'
import useInitialLoad from '../../hooks/useInitialLoad'

const EmailStatus = ({ setErrorMessage }) => {
	const { authFetch } = FetchWithAuth()
	const [statuses, setStatuses] = useState()
	const [loading, setLoading] = useState(false)

	// useEffect(() => {
	// 	const fetchNSet = async () => {
	// 		const response = await authFetch(BASEURL + '/api/dhl/gmail_reports_statuses')
	// 		const data = await response.json()
	// 		setStatuses(data)
	// 	}
	// 	fetchNSet()
	// }, [])

	// const { shipping_variance, overlabeled, marked_up } = statuses || {}

	const scanEmails = async () => {
		setLoading(true)
		const response = await authFetch(BASEURL + '/api/dhl/process_emails')
		setLoading(false)
		if (response.ok) {
			const data = await response.json()
			setStatuses(data)
		} else {
			setErrorMessage([response.statusText])
		}
	}

	// const displayStatus = (bool) => (!!bool ? 'up to date' : 'not up to date')

	return (
		<div className="report-status-box">
			<button onClick={scanEmails}>
				{loading ? <i class="fas fa-spinner check-icon loading-icon"></i> : 'Scan Emails'}
			</button>
		</div>
	)
}

export default EmailStatus
