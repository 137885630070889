import React, { useState } from "react";
import PropTypes from "prop-types";
import PopUpPage from "../../../components/PopUpPage";
import { FetchWithAuth } from "../../../hooks/FetchWithAuth";
import { BASEURL } from "../../../global-resources";


function EditCarrierService({ carrierServiceId, setCarrierServices, carrier }) {
  const [active, setActive] = useState(carrier.active_flag);
  const [loading, setLoading] = useState(false);
  const [mailerID, setMailerID] = useState(carrier.mailer_id);
  const [pickUpNum, setPickUpNum] = useState(carrier.shipper_id_value);
  const { authFetch } = FetchWithAuth();

  function handleChange(e) {
    if (e.target.name === "mailerID") setMailerID(e.target.value);
    if (e.target.name === "pickUpNum") setPickUpNum(e.target.value);
  }
  async function handleSubmit() {
    const url = `/pricing/customer-carriers/${carrierServiceId}`;
    const data = {
      mailer_id: mailerID,
      shipper_id_value: pickUpNum,
      active_flag: active,
    };

    setLoading(true);
    const response = await authFetch(`${BASEURL}${url}`, {
      m: "PATCH",
      b: JSON.stringify(data),
      h: { "Content-Type": "application/json" },
    });
    setLoading(false);
    const responseData = await response.json();
    if (response.ok) {
      setCarrierServices((initialState) => {
        const carrierIndex = initialState.findIndex((customerCarrier) => {
          return customerCarrier.carrier_name === carrier.carrier_name;
        });
        initialState[carrierIndex] = responseData;
        return initialState;
      });
    }
  }

  return (
		<PopUpPage>
			<div className="edit-carrier-service">
				<h1>Edit Carrier Service</h1>
				<label htmlFor="mailerID">Mailer ID</label>
				<input
					className="feedback-input valid-input"
					name="mailerID"
					value={mailerID}
					onChange={(e) => handleChange(e)}
				></input>
				<label htmlFor="pickUpNum">{carrier.shipper_id_name}</label>
				<input
					className="feedback-input valid-input"
					name="pickUpNum"
					value={pickUpNum}
					onChange={(e) => handleChange(e)}
				></input>
				<label class="check-box-container">
					Active
					<input
						type="checkbox"
						checked={active ? 'checked' : null}
						onChange={() => setActive((active) => !active)}
					></input>
					<span class="checkmark"></span>
				</label>
				<button onClick={handleSubmit}>
					{loading ? (
						<i class="fas fa-spinner check-icon loading-icon"></i>
					) : (
						'Submit Changes'
					)}
				</button>
			</div>
		</PopUpPage>
	)
}

EditCarrierService.propTypes = {
  carrierServiceId: PropTypes.number,
  setCarrierServices: PropTypes.func,
  carrier: PropTypes.object,
};

export default EditCarrierService;
