import React, { useState } from "react"

function AddCarrierServiceForm({
  carriers,
  handleChange,
  handleSubmit,
  loading,
}) {
  const [formData, setFormData] = useState({
    carrierID: "",
    mailerID: "",
    shipperIdValue: "",
    is_active: true,
    shipper_id_name: "",
  })

  function handleChange(e) {
    if (e.target.name === "carrierID")
      setFormData({
        ...formData,
        shipper_id_name: e.target.selectedOptions[0].id,
      })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  function carrierOptionsFrag() {
    const options = carriers.map((carrier) => {
      return (
        <option id={carrier.shipper_id_name} value={carrier.id}>
          {carrier.name}
        </option>
      )
    })
    return options
  }

  function toggleActive() {
    setFormData({ ...formData, ["is_active"]: !formData.is_active })
  }

  return (
		<>
			<label htmlFor="selectCarrier">Choose A Carrier</label>
			<select
				name="carrierID"
				class="valid-input feedback-input"
				onChange={(e) => handleChange(e)}
			>
				<option id="shipper id name" value={false}>
					Select A Carrier
				</option>
				{carrierOptionsFrag()}
			</select>

			<lable className="add-carrier-label" htmlFo="mailerID">
				Mailer ID
			</lable>
			<input
				placeholder="Enter numbers here"
				type="text"
				name="mailerID"
				class="valid-input feedback-input"
				value={formData.mailerID}
				onChange={(e) => handleChange(e)}
			></input>
			<lable className="add-carrier-label" htmlFor="shipperIdValue">
				{formData.shipper_id_name ? formData.shipper_id_name : 'Pickup Number'}
			</lable>
			<input
				placeholder="Enter numbers here"
				type="text"
				name="shipperIdValue"
				class="valid-input feedback-input"
				value={formData.pickupNumber}
				onChange={(e) => handleChange(e)}
			></input>
			<label class="check-box-container">
				Active
				<input
					type="checkbox"
					disabled={!formData.carrierID.length > 0 || formData.carrierID === 'false'}
					checked={formData.is_active ? 'checked' : null}
					onChange={() => toggleActive()}
				></input>
				<span class="checkmark"></span>
			</label>
			<button
				class="tier_submit-button"
				onClick={() => handleSubmit(formData)}
				disabled={!formData.carrierID.length > 0 || formData.carrierID === 'false'}
			>
				{loading ? <i class="fas fa-spinner check-icon loading-icon"></i> : 'Submit'}
			</button>
		</>
	)
}

export default AddCarrierServiceForm
