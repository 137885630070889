import React, { useState } from 'react'
import { FetchWithAuth } from '../../hooks/FetchWithAuth'
import useInitialLoad from '../../hooks/useInitialLoad'
import TableHeader from '../Carriers/dhl/components/TableHeader'
import TableRow from '../Carriers/dhl/components/TableRow'
const tableHeaders = ['carrier_name', 'terminus', 'tier']

function TiersList() {
	const { authFetch } = FetchWithAuth()
	const url = `/pricing/ratecards`
	const [tiers, setTiers] = useInitialLoad(url)

	function getFilteredList() {
		const dhlTiers = tiers.filter((tier) => tier.carrier_name === 'DHL')
		const osmTiers = tiers.filter((tier) => tier.carrier_name === 'OSM')
		const uspsTiers = tiers.filter((tier) => tier.carrier_name === 'USPS')
		return [dhlTiers, osmTiers, uspsTiers]
	}

	return (
		<div className="tier-list">
			{!tiers ? (
				<i id="table-loader" class="fas fa-spinner check-icon loading-icon large"></i>
			) : (
				<div className="tiers-list">
					{getFilteredList().map((carrier, index) => {
						return (
							<table className="styled-table">
								<TableHeader headers={tableHeaders} />
								<tbody>
									{carrier.map((row, rowNumber) => {
										return (
											<TableRow
												headers={tableHeaders}
												row={row}
												url={url}
												rowNumber={rowNumber}
												isRowAlink={true}
											/>
										)
									})}
								</tbody>
							</table>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default TiersList
