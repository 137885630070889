import { Switch, Route } from 'react-router-dom'
import OsmBillProcess from './components/OsmBillProcess'
import ShippingServices from '../../../components/ShippingServices'
import OsmInvoice from './components/OsmInvoice'
import OsmInvoiceList from './components/OsmInvoiceList'
import OsmPage from './OsmPage'
const OsmRoutes = () => {
	return (
		<Switch>
			<Route path="/carriers/osm/legacy-process">
				<OsmBillProcess />
			</Route>
			<Route path="/carriers/osm/shipping_services">
				<ShippingServices carrier={'osm'} carrierId={4} />
			</Route>
			<Route path="/carriers/osm/invoices/:id(\d+)">
				<OsmInvoice />
			</Route>
			<Route path="/carriers/osm/invoices">
				<OsmInvoiceList />
			</Route>
			<Route path="/carriers/osm">
				<OsmPage />
			</Route>
		</Switch>
	)
}

export default OsmRoutes
