import useOffsetDimensions from '../../../../hooks/useOffsetDimensions'
import DhlShipmentErrorDetailCategory from './DhlShipmentErrorDetailCategory'
// import { useParams } from 'react-router-dom'
import {
	categoryOrderAndNames,
	dhlErrorShipmentsTableMap,
} from '../dhlErrorShipmentsTableMap'

const ShipmentDetailView = ({ shipmentsSearchResults, setShipmentsSearchResults }) => {
	const { ref, divWidth } = useOffsetDimensions()
	// const { id } = useParams()
	// some times the shipment result will return a parent along with a childe line we only want the parent which will be the if there are tow will be the second and index 1
	let shipmentIndexInDataArray = shipmentsSearchResults[1] ? 1 : 0
	function renderInfoSections() {
		return categoryOrderAndNames.map((categoryHeader, categoryIndex) => {
			return (
				<DhlShipmentErrorDetailCategory
					shipmentData={shipmentsSearchResults[shipmentIndexInDataArray]}
					categoryHeader={categoryHeader}
					categoryIndex={categoryIndex}
					selectedCard={0}
					setShipmentsSearchResults={setShipmentsSearchResults}
					isDetailPage={true}
				/>
			)
		})
	}

	return (
		<>
			<div ref={ref} className="error-shipment-header-warper">
				<div className="error-shipment-headers" style={{ minWidth: divWidth }}>
					{shipmentsSearchResults.length < 1 ? (
						'No Data To Display'
					) : (
						<>
							<div className="error-shipment-header-name">Stated</div>
							<div className="error-shipment-header-name">Calculated</div>
							<div className="error-shipment-header-name">Manual</div>
						</>
					)}
				</div>
			</div>
			{shipmentsSearchResults.length < 1 ? null : (
				<div className="shipment-details-container">{renderInfoSections()}</div>
			)}
		</>
	)
}

export default ShipmentDetailView
