import React, { useState, useRef } from 'react'
import { BASEURL } from '../../../global-resources'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import downloadCSVfromJsonResponse from '../../../Functions/downloadCSVfromJsonResponse'

const FileUpload = () => {
	const { authFetch } = FetchWithAuth()
	const fileInputRef = useRef(null)
	const [fileToUpload, setFileToUpload] = useState()
	const [errors, setErrors] = useState()
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleFileChange = (file) => {
		setFileToUpload(file[0])
		setErrors('')
	}

	const removeFile = () => {
		setErrors('')
		setSuccess(false)
		fileInputRef.current.value = ''
		setFileToUpload('')
	}

	const handleUpload = async () => {
		const formData = new FormData()
		formData.set('manifest', fileToUpload)
		console.log(formData)
		const response = await authFetch(`${BASEURL}/quoter/quote`, {
			m: 'POST',
			b: formData
		})

		if (response.status !== 400 && !response.ok) {
			setErrors(response.statusText)
		}

		if (response.status === 400) {
			const data = await response.json()
			setErrors(data.errors[0])
		}
		if (response.ok) {
			fileInputRef.current.value = ''
			setFileToUpload('')
			setSuccess(true)
			const data = await response.json()
			data.files.forEach(async (file) => {
				downloadCSVfromJsonResponse(file)
			})
		}
	}

	return (
		<>
			<div className="file-upload-warper">
				<div
					className={'file-upload'}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<div className="upload-file">
						<label htmlFor="quote-upload" className="custom-file-upload">
							{'Update'}
						</label>
						<input
							id="quote-upload"
							type="file"
							accept=".csv,.xlsx"
							ref={fileInputRef}
							onChange={(e) => handleFileChange(e.target.files)}
						/>
						<input id="file-upload" type="file"></input>
						<label className="file-name">
							{fileToUpload ? fileToUpload.name : 'File Name'}
						</label>
						<label className="custom-file-upload" onClick={removeFile}>
							Cancel
						</label>
					</div>
					<div className="bn5" onClick={handleUpload}>
						{loading ? (
							<i className="fas fa-spinner check-icon loading-icon"></i>
						) : errors ? (
							<span className="error-text">{errors}</span>
						) : success ? (
							<span className="success-text">Success: 2 files will now download </span>
						) : (
							'Upload'
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default FileUpload
