import DhlDisputesTableCell from './DhlDisputesTableCell'

const DhlDisputesTableRow = ({
	tableData,
	tableHeaders,
	buttonName,
	felid_to_toggle,
}) => {
	const createTableRow = () => {
		return tableData.map((dataObject, objectIndex) => {
			return (
				<tr
					// style={{ backgroundColor: 'lightblue' }}  highlight cell if it has bean send to dhl
					key={objectIndex}
					className="disputes-tooltip-container"
					// onContextMenu={(e) => setShow(true)}   context menu can potently be used to toggle info like is a shipment was send to DHL for a dispute in a 2 click step instead of a 3 step process
				>
					{tableHeaders.map((key) => {
						return (
							<DhlDisputesTableCell
								td={dataObject[key]}
								dataObject={dataObject}
								tableHeaders={tableHeaders}
								columHeader={key}
								buttonName={buttonName}
								felid_to_toggle={felid_to_toggle}
							/>
						)
					})}
					{!dataObject.notes ? null : (
						<span className="disputes-tooltip-text">Note: {dataObject.notes}</span>
					)}
				</tr>
			)
		})
	}
	return <tbody>{createTableRow()}</tbody>
}
export default DhlDisputesTableRow
