export async function copyTextToClipboard(text) {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text)
	} else {
		function handler(event) {
			event.clipboardData.setData('text/plain', text)
			event.preventDefault()
			document.removeEventListener('copy', handler, true)
		}

		document.addEventListener('copy', handler, true)
		document.execCommand('copy')
	}
}
