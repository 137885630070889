import React from "react";

const GetHighlightedText = ({text, highlight}) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
        <span>
            {
                parts.map(part => {
                    return (
                        part.toLowerCase() === highlight.toLowerCase()
                            ? <span class="highlight-text">{part}</span>
                            : part
                    )
                })
            }
        </span>
    )
}

export default GetHighlightedText 