import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useInitialLoad from "../../../hooks/useInitialLoad";
import { FetchWithAuth } from "../../../hooks/FetchWithAuth";
import { BASEURL } from "../../../global-resources";
import PopUpPage from "../../../components/PopUpPage";
import _ from "lodash";

function EditTier({
  carrierId,
  carrierName,
  carrierServiceId,
  setCarrierServices,
}) {
  const { terminus, method } = useParams();
  const { authFetch } = FetchWithAuth();
  const url = `/pricing/ratecards?carrier=${carrierId}&terminus=${terminus}`;
  const [tiers, setTiers] = useInitialLoad(url);
  const [isButtonSelected, setIsButtonSelected] = useState("switch");
  const [switchedTier, setSwitchedTier] = useState();
  const [newTier, setNewTier] = useState();
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    if (e.target.name === "newTier") setNewTier(e.target.value);
    if (e.target.name === "selectTier") {
      const tierId = e.target.value;
      setSwitchedTier(tierId);
      if (tierId === "false") setSwitchedTier();
    }
  }

  function handleSubmit() {
    setLoading(true);
    if (isButtonSelected === "switch") {
      linkTierToCarrierService();
    } else {
      creatRateCard();
    }
  }

  const creatRateCard = async () => {
    const url = `/pricing/ratecards`;
    const data = {
      tier: newTier,
      carrier: carrierId,
      terminus: terminus,
    };
    const response = await authFetch(`${BASEURL}${url}`, {
      m: "POST",
      b: JSON.stringify(data),
      h: { "Content-Type": "application/json" },
    });
    const responseD = await response.json();
    if (response.ok) {
      if (isButtonSelected === "create")
        linkTierToCarrierService(responseD.id, true);
    }
  };

  const linkTierToCarrierService = async (
    tierId = switchedTier,
    updateSwitchOptions = false
  ) => {
    const url = `/pricing/customer-carriers/${carrierServiceId}`;
    const data = {
      [`${terminus}_rate_card`]: tierId,
    };
    const response = await authFetch(`${BASEURL}${url}`, {
      m: "PATCH",
      b: JSON.stringify(data),
      h: { "Content-Type": "application/json" },
    });
    const responseD = await response.json();
    if (response.ok) {
      setCarrierServices((initialState) => {
        const newState = _.cloneDeep(initialState);
        const tierIndex = newState.findIndex((customerCarrier) => {
          return customerCarrier.carrier_name === carrierName;
        });
        const rateCardTier = [`${terminus}_rate_card_tier`];
        newState[tierIndex][rateCardTier] =
          responseD[`${terminus}_rate_card_tier`];
        return newState;
      });
      if (updateSwitchOptions) {
        setTiers((initialState) => {
          const newState = _.cloneDeep(initialState);
          const newTierObj = {
            carrier_name: carrierName,
            id: tierId,
            terminus: terminus,
            tier: responseD[`${terminus}_rate_card_tier`],
          };
          newState.push(newTierObj);
          return newState;
        });
      }
      setLoading(false);
    }
  };

  function tierOptionsFrag() {
    const options = tiers.map((tier) => {
      return <option value={tier.id}>tier {tier.tier}</option>;
    });
    return options;
  }

  return (
    <PopUpPage>
      <>
        <div class="pop-up-header">
          {method} {carrierName} {terminus} tier
        </div>
        <>
          <div class="tier-selection-buttons">
            <button
              class={
                isButtonSelected === "switch"
                  ? "select-tier-button active_button"
                  : "select-tier-button"
              }
              onClick={() => setIsButtonSelected("switch")}
            >
              Switch
            </button>
            <button
              class={
                isButtonSelected === "create"
                  ? "create-tier-button active_button"
                  : "create-tier-button"
              }
              onClick={() => setIsButtonSelected("create")}
            >
              Create
            </button>
          </div>

          <div class="tier-form">
            {isButtonSelected === "switch" ? (
              !tiers ? (
                <i
                  id="table-loader"
                  class="fas fa-spinner check-icon loading-icon large"
                ></i>
              ) : (
                <>
                  <label htmlFor="selectTier">Choose a Tier</label>
                  <select
                    name="selectTier"
                    class="valid-input feedback-input"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value={false}>Select A Tier</option>
                    {tierOptionsFrag()}
                  </select>
                </>
              )
            ) : (
              <>
                <lable for="newTier">New Tier</lable>
                <input
                  placeholder="type a custom tier name here"
                  type="text"
                  name="newTier"
                  class="valid-input feedback-input"
                  value={newTier}
                  onChange={(e) => handleChange(e)}
                ></input>
              </>
            )}
            <button
              disabled={
                (!switchedTier && isButtonSelected === "switch") ||
                (!newTier && isButtonSelected === "create")
              }
              class="tier_submit-button"
              onClick={() => handleSubmit()}
            >
              {loading ? (
                <i class="fas fa-spinner check-icon loading-icon"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </>
      </>
    </PopUpPage>
  );
}

export default EditTier;
