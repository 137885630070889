import { useState } from 'react'
import OsmManifestUploadInput from './OsmManifestUploadInput'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import downloadCSVfromJsonResponse from '../../../Functions/downloadCSVfromJsonResponse'
import OsmRateCardUpload from './OsmRateCardUpload'

const OsmQuoter = () => {
	const { authFetch } = FetchWithAuth()
	const [manifestFile, setManifestFile] = useState()
	const [rateCardFile, setRateCardFile] = useState([])
	const [success, setSuccess] = useState(false)
	const [errors, setErrors] = useState()
	const [loading, setLoading] = useState(false)
	async function handleUpload() {
		const formData = new FormData()
		formData.set('manifest', manifestFile)
		for (let i = 0; i < rateCardFile.length; i++) {
			if (i < 1) {
				formData.set('rate_cards', rateCardFile[0])
			} else {
				console.log('in second rate card file')
				formData.append('rate_cards', rateCardFile[i])
			}
		}

		const response = await authFetch(`${BASEURL}/quoter/osm/summary`, {
			m: 'POST',
			b: formData
		})
		// console.log(response)

		if (response.status !== 400 && !response.ok) {
			setErrors(response.statusText)
		}
		if (response.status === 400) {
			const data = await response.json()
			setErrors(data.errors[0])
		}
		if (response.ok) {
			setRateCardFile([])
			setSuccess(true)

			const data = await response.json()
			console.log(data)
			downloadCSVfromJsonResponse(data.file)
		}
	}

	return (
		<>
			<div className="osm-manifest-upload">
				<div>Manifest Upload</div>
				<OsmManifestUploadInput
					manifestFile={manifestFile}
					setManifestFile={setManifestFile}
					setSuccess={setSuccess}
					setErrors={setErrors}
				/>
			</div>
			<div className="osm-rate-card-upload">
				<div>Rate Card Uploads</div>
				<OsmRateCardUpload
					setSuccess={setSuccess}
					setErrors={setErrors}
					rateCardFile={rateCardFile}
					setRateCardFile={setRateCardFile}
				/>
			</div>
			<div className="osm-rate-card-upload"></div>
			<div className="bn5 osm-upload" onClick={handleUpload}>
				{loading ? (
					<i className="fas fa-spinner check-icon loading-icon"></i>
				) : errors ? (
					<span className="error-text">{errors}</span>
				) : success ? (
					<span className="success-text">Success: a summery file will now download </span>
				) : (
					'Upload'
				)}
			</div>
		</>
	)
}

export default OsmQuoter
