const downloadCSVfromJsonResponse = (file) => {
	let blob = new Blob([file.body], { type: file.type })
	const DownloadUrl = URL.createObjectURL(blob)
	const anchorElement = document.createElement('a')
	anchorElement.href = DownloadUrl
	anchorElement.download = `${file.name}`
	anchorElement.click()
	anchorElement.parentNode.removeChild(anchorElement)
	URL.revokeObjectURL(DownloadUrl)
}

export default downloadCSVfromJsonResponse
