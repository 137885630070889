import React, { useState } from 'react'
import { useAuth2 } from '../context/auth-context'

export const LoginPage = () => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const auth = useAuth2()

	const handleUsernameChange = (e) => {
		setUsername(e.target.value)
	}
	const handlePasswordChange = (e) => {
		setPassword(e.target.value)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!(username || password)) return
		const { loggedIn } = (await auth.login({ username, password })) || {}
		if (loggedIn) {
			return
		} else {
			setError('bad creds')
		}
	}

	return (
		<div className="login-panel">
			<form>
				<h3>Welcome</h3>
				<input
					className="mb-3"
					onChange={handleUsernameChange}
					placeholder="username"
					value={username}
				/>
				<input
					className="mb-3"
					type="password"
					onChange={handlePasswordChange}
					placeholder="password"
					value={password}
				/>
				<button variant="primary" type="submit" onClick={handleSubmit}>
					log in
				</button>
				<p>{error}</p>
			</form>
		</div>
	)
}
