import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RateCards from './RateCards'
import ratecards from './ratecards.css'
import TiersList from './TiersList'

const RateCardRouts = () => {

    return (
			<Switch>
				<Route path="/pricing/tiers">
					<TiersList />
				</Route>
				<Route path="/ratecards/:id">
					<RateCards />
				</Route>
			</Switch>
		)
}


export default RateCardRouts