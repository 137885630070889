import { useEffect, useState } from 'react'
import { useAuthFetch } from '../hooks/useAuthFetch'
import { BASEURL } from '../global-resources'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import { options1 } from '../../src/settingFiles/snackbarSettings'
const useInitialLoad = (url) => {
	const history = useHistory()
	const { authFetch } = useAuthFetch()
	const [openSnackbar, closeSnackbar] = useSnackbar(options1)
	const [data, setData] = useState()
	const [Reload, setReload] = useState(0)
	const [loading, setLoading] = useState(false)
	const [onLine, setOnLine] = useState(true)

	useEffect(() => {
		if (!navigator.onLine) {
			setOnLine(false)
			openSnackbar(<h2>{`please connect your computer to a internet connection`}</h2>, [
				9500,
			])
			// return history.push('/NoInternet')
		}
		setLoading(true)
		const fetchNSet = async () => {
			const data = await authFetch(BASEURL + url)
			setLoading(false)
			setData(data)
		}
		fetchNSet()
	}, [Reload])
	return [data, setData, setReload, loading, onLine]
}

export default useInitialLoad
