import { useEffect, useRef, useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import _ from 'lodash'

const DhlShipmentErrorDetailEdit = ({
	shipmentId,
	cellData,
	cellName,
	selectedCard,
	shipmentData,
	setResponseMessage,
	setShipmentsSearchResults,
	highlight,
	isDetailPage = false,
}) => {
	const { authFetch } = FetchWithAuth()
	const [editOpen, setEditOpen] = useState(false)
	const [cellInputState, setCellInputState] = useState(cellData)
	const [servicesList, setServicesList] = useInitialLoad(
		`/pricing/shippingservices?carrier=${3}` // 3 is the DHL id
	)

	useEffect(() => {
		setCellInputState(cellData)
		setEditOpen(false)
	}, [selectedCard, cellData])

	function filterServiceList() {
		return servicesList.filter((service) => {
			if (service.terminus == shipmentData.terminus) return service
		})
	}

	function formatCellData() {
		if (cellName == 'shipping_service_id_manual') {
			return servicesList.map((service) => {
				if (service.id == cellData) {
					return service.name
				}
			})
		} else {
			return cellData
		}
	}

	async function handleSubmit() {
		const patchUrl = `${BASEURL}/carriers/dhl/invoicelines/`
		const response = await authFetch(`${patchUrl}${shipmentId}`, {
			m: 'PATCH',
			b: JSON.stringify({
				id: shipmentId,
				[cellName]: !cellInputState ? null : cellInputState,
			}),
			h: { 'Content-Type': 'application/json' },
		})
		const jRes = await response.json()
		if (response.ok) {
			setShipmentsSearchResults((prevState) => {
				let newState = _.cloneDeep(prevState)
				// since this component is used by tow different
				if (isDetailPage) {
					newState = [jRes]
					console.log(newState)
				} else {
					newState[selectedCard] = jRes
				}
				return newState
			})
			setResponseMessage([['Successfully Updated.']])
			setEditOpen(false)
		} else {
			setResponseMessage([[Object.values(jRes)[0][0]]])
		}
	}

	if (!servicesList) {
		return 'loading'
	}
	function handleChange(e) {
		setCellInputState(e.target.value)
	}

	function carrierOptionsFrag() {
		const options = filterServiceList().map((service) => {
			return (
				<option id={service.id} value={service.id}>
					{service.name}
				</option>
			)
		})
		return options
	}
	return !editOpen ? (
		<div
			className="error-detail-warper"
			style={{ backgroundColor: highlight ? 'rgb(255 150 150)' : 'transparent' }}
		>
			<div className="cell-data_edit">{formatCellData()}</div>
			<i
				class="fa-solid fa-pencil cell-edit_icon"
				onClick={() => setEditOpen(!editOpen)}
			></i>
		</div>
	) : cellName == 'shipping_service_id_manual' ? (
		<div className="">
			{/* <label htmlFor="selectCarrier">Choose A Carrier</label> */}
			<select
				name="carrierID"
				className="error-dhl-dropdown"
				onChange={(e) => handleChange(e)}
			>
				<option id="shipper id name" value={false}>
					Select A Carrier
				</option>
				{carrierOptionsFrag()}
			</select>
			<i class="fa-solid fa-check error-input-icons" onClick={handleSubmit}></i>
			<i
				class="fa-solid fa-xmark error-input-icons"
				onClick={() => setEditOpen(false)}
			></i>
		</div>
	) : (
		<div className="error-detail-input">
			<input
				className="detail-input-edit"
				value={cellInputState}
				onChange={handleChange}
			></input>
			<i class="fa-solid fa-check error-input-icons" onClick={handleSubmit}></i>
			<i
				class="fa-solid fa-xmark error-input-icons"
				onClick={() => setEditOpen(false)}
			></i>
		</div>
	)
}

export default DhlShipmentErrorDetailEdit
