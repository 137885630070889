import { Link } from 'react-router-dom'
import InvoiceIcon from './InvoiceIcon'

const InvoiceRows = ({ invoices }) => {
	const renderInvoiceRowsFrag = () => {
		return invoices.map(({ id, invoice_number, date, invoice_total, shipments_file }) => {
			return (
				<div key={id} class="customer-card">
					<InvoiceIcon />
					<div class="customer-data">
						<Link to={`invoices/${id}`}>{invoice_number}</Link>
					</div>
					<div class="customer-data">{date}</div>
					<div class="customer-data">{invoice_total}</div>
				</div>
			)
		})
	}
	return <>{renderInvoiceRowsFrag()}</>
}

export default InvoiceRows
