import React, { useState, useEffect } from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function InvoiceLines({ url, invoiceId }) {
	const { processName } = useParams()
	const [tableData, setTableData] = useInitialLoad(
		url + `/lines?unprocessed=${processName}`
	)
	console.log(tableData)
	const tableHiderUrl = `/carriers/dhl/invoices/${invoiceId}`

	const tableHeaders = [
		'pickup_number',
		'pickup_number_errors',
		'customer_confirmation_number',
		'delivery_confirmation_number',
		'originaldelcon',
		'internal_tracking_num',
		'bol_number',
		'terminus',
		'formatted_zip_code',
		'pickup_number_override',
		'customer_name',
	]
	useEffect(() => {
		document.getElementById('invoice-table').scrollIntoView({ behavior: 'smooth' })
	}, [tableData])

	return (
		<div id="invoice-table">
			{!tableData ? (
				<i id="table-loader" class="fas fa-spinner check-icon loading-icon large"></i>
			) : (
				<div class="invoice_table">
					<div class="table-hider" style={{ background: 'rgba(255, 1, 1, 0.5)' }}>
						<Link to={tableHiderUrl}>
							<i class="fas fa-times"></i>
						</Link>
					</div>
					<table className="styled-table">
						<TableHeader headers={tableHeaders} />
						<tbody>
							{tableData.map((row, rowNumber) => {
								return (
									<TableRow
										headers={tableHeaders}
										row={row}
										url={url}
										rowNumber={rowNumber}
									/>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</div>
	)
}
