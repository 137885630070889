import React from 'react'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import FileUpload from './FileUpload'
import OsmQuoter from './OsmQuoter'

function FileQuoter() {
	return (
		<>
			<div className="dhl-quoter-warper" style={{ marginTop: '10vh' }}>
				<h2>DHL Quoter</h2>
				<FileUpload />
			</div>
			<div className="osm-quoter-warper">
				<h2>OSM Quoter</h2>
				<OsmQuoter />
			</div>
		</>
	)
}

export default FileQuoter
