import useInitialLoad from '../../../hooks/useInitialLoad'
import { Link } from 'react-router-dom'

function CustomersUsingGivenTier({ tier_terminus, tier_id }) {
	const url = `/customers?carrier_services__${tier_terminus}_rate_card=${tier_id}&field=id&field=name`

	const [customerList, _] = useInitialLoad(url)

	function renderCustomerListFrag() {
		return customerList.map((customer) => {
			return (
				<div class="customer-invoice-card list-data_customer-using-list">
					<Link
						to={`/customers/${customer.id}`}
						className="customer-name_customer-using-list"
					>
						{customer.name}
					</Link>
				</div>
			)
		})
	}

	return !customerList ? null : (
		<div className="customer-filter_rate-cards">
			<fieldset className="customer-filter-fieldset_rate-cards">
				<legend className="customers-using-rate-card-legend">
					Customers Using This Rate Card
				</legend>

				<div className="customer-invoice-list customer-using-card">
					<div className="card-headers customer-using-card">
						<span className="customer-name_customer-using-list">Customer Name</span>
					</div>

					{renderCustomerListFrag()}
				</div>
			</fieldset>
		</div>
	)
}

export default CustomersUsingGivenTier
