import React, { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase'
import MessageBanner from '../../../../components/MessageBanner'

function ShipmentSearch({ setShipmentsSearchResults, lastInvoiceId }) {
	const { authFetch } = FetchWithAuth()
	const [searchInput, setSearchInput] = useState('')
	const [searchBy, setSearchBy] = useState('customer_confirmation_number')
	const [loading, setLoading] = useState(false)
	const [messageResponse, setMessageResponse] = useState([])

	const handleChange = (e) => {
		setSearchInput(e.target.value)
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		const response = await authFetch(
			// invoice_id is just for development
			`${BASEURL}/carriers/dhl/invoicelines?${searchBy}=${searchInput}&invoice_id__gt=${
				parseInt(lastInvoiceId) - 5
			}`
		)
		setLoading(false)
		if (!response.ok) {
			setMessageResponse(response.statusText)
		}
		const json_response = await response.json()
		if (!json_response.length > 0) {
			setMessageResponse('No Shipments found')
		}
		setShipmentsSearchResults(json_response)
	}

	return (
		<div className="shipment-search">
			{!messageResponse.length > 0 ? null : (
				<MessageBanner
					message={messageResponse}
					setResponseMessage={setMessageResponse}
				/>
			)}
			<div class="sec-center">
				<input class="dropdown" type="checkbox" id="dropdown" name="dropdown" />

				<label class="for-dropdown" for="dropdown">
					<div className="dropdown-default">
						Search By
						<i class="fa-solid fa-arrow-down uil"></i>
					</div>
					<div className="dropdown-choice-text">{snakeCaseToTitleCase(searchBy)}</div>
				</label>

				<div class="section-dropdown">
					<a href="#" onClick={() => setSearchBy('customer_confirmation_number')}>
						customer confirmation #
					</a>
					<a href="#" onClick={() => setSearchBy('delivery_confirmation_number')}>
						delivery confirmation #
					</a>
					<a
						href="#"
						onClick={() => setSearchBy('original_delivery_confirmation_number')}
					>
						original delcon #
					</a>
				</div>
			</div>
			<form className="searchForm" onsubmit="event.preventDefault();" role="search">
				<label className="searchFormLabel" for="search">
					Search for stuff
				</label>
				<input
					className="searchFormInput"
					id="search"
					type="search"
					placeholder="Search..."
					value={searchInput}
					onChange={handleChange}
				/>
				<button className="searchFormButton" onClick={handleSubmit}>
					{loading ? <i class="fas fa-spinner check-icon loading-icon"></i> : 'Go'}
				</button>
			</form>
		</div>
	)
}

export default ShipmentSearch
