import React, { useState } from 'react'
import PopUpPage from '../../../../components/PopUpPage'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import _ from 'lodash'

function CreateShippingService({ setServicesList }) {
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	const [loading, setLoading] = useState(false)
	const [carriersList, _] = useInitialLoad('/carriers')
	const [success, setSuccess] = useState(false)
	const [errors, setErrors] = useState('')
	const validate = (values) => {
		const errors = {}
		if (!values.max_weight_oz) {
			return
		} else if (values.max_weight_oz.toString().length > 15) {
			errors.max_weight_oz = 'Oops max weight exceeded 15 digits'
		}
		return errors
	}

	const formik = useFormik({
		initialValues: {
			carrier: '',
			name: '',
			code: '',
			terminus: '',
			min_weight_oz: '',
			max_weight_oz: '',
		},
		validate,
		onSubmit: async (values) => {
			setLoading(true)
			const url = `${BASEURL}/pricing/shippingservices`
			const response = await authFetch(url, {
				m: 'POST',
				b: JSON.stringify(values),
				h: { 'Content-Type': 'application/json' },
			})

			const data = await response.json()
			if (response.status !== 400 && !response.ok) {
				setErrors(response.statusText)
			}

			if (response.status === 400) {
				const errorMessage = Object.values(data)[0][0]
				setErrors(errorMessage)
			}
			if (response.ok) {
				setServicesList((prevState) => {
					const newState = [...prevState]
					newState.push(data)
					return newState
				})
				setErrors('')
				setSuccess(true)
			}
			setLoading(false)
		},
	})
	function carrierOptionsFrag() {
		const options = carriersList.map((carrier) => {
			return <option value={carrier.id}>{carrier.name}</option>
		})
		return options
	}

	return !carriersList ? (
		<i class="fas fa-spinner check-icon loading-icon large"></i>
	) : (
		<PopUpPage>
			<h1>Add Shipping Services</h1>
			<form onSubmit={formik.handleSubmit} style={{ marginTop: '4%' }}>
				{/* <div className="centered-input"></div> */}
				<div class="side-by-side-input-fields">
					<div class="right-input-fields">
						<label htmlFor="name">name</label>
						<input
							className={
								!formik.errors.name ? 'valid-input feedback-input' : 'feedback-input'
							}
							id="name"
							name="name"
							type="text"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						<label htmlFor="code">Code</label>
						<input
							className={
								!formik.errors.code ? 'valid-input feedback-input' : 'feedback-input'
							}
							id="code"
							name="code"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.code}
						/>

						<label htmlFor="terminus">terminus</label>
						<input
							className={
								!formik.errors.terminus ? 'valid-input feedback-input' : 'feedback-input'
							}
							id="terminus"
							name="terminus"
							type="text"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.terminus}
						/>
					</div>
					<div class="left-input-fields">
						<label htmlFor="selectCarrier">Choose A Carrier</label>
						<select
							style={{ width: '100%' }}
							name="carrier"
							class="valid-input feedback-input"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.carrier}
						>
							<option value="">select a carrier</option>
							{carrierOptionsFrag()}
						</select>

						<label htmlFor="min_weight_oz">min weight oz</label>
						<input
							className={
								!formik.errors.min_weight_oz
									? 'valid-input feedback-input'
									: 'feedback-input'
							}
							id="min_weight_oz"
							name="min_weight_oz"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.min_weight_oz}
						/>
						<label htmlFor="max_weight_oz">max weight oz</label>
						<input
							className={
								!formik.errors.max_weight_oz
									? 'valid-input feedback-input'
									: 'feedback-input'
							}
							id="max_weight_oz"
							name="max_weight_oz"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.max_weight_oz}
						/>
						{formik.touched.max_weight_oz && formik.errors.max_weight_oz ? (
							<div className="form-error-message">{formik.errors.max_weight_oz}</div>
						) : null}
					</div>
				</div>
				<div className="centered-button">
					<button disabled={formik.values.carrier === ''} type="submit">
						{loading ? (
							<i class="fas fa-spinner check-icon loading-icon"></i>
						) : errors ? (
							<span className="error-text">{errors}</span>
						) : success ? (
							<span className="success-text"> Successfully Created Service</span>
						) : (
							'Submit'
						)}
					</button>
				</div>
			</form>
		</PopUpPage>
	)
}

export default CreateShippingService
