const ColumnHeaders = ({ children }) => {
	return (
		<div class="column-headers">
			<div class="column-headers-div">{children}</div>
		</div>
	)
}

const DhlInvoicesHeaders = () => {
	return (
		<ColumnHeaders>
			<div class="column-names icon"></div>
			<div class="column-names">Invoice Number</div>
			<div class="column-names">Carrier</div>
			<div class="column-names">Date</div>
			<div class="column-names">Price</div>
			<div class="column-names">Processed</div>
		</ColumnHeaders>
	)
}

export default DhlInvoicesHeaders
