import React, { useEffect, useState } from 'react'
import MessageBanner from '../MessageBanner'
import { FetchWithAuth } from '../../hooks/FetchWithAuth'
import { BASEURL } from '../../global-resources'

export default function EditableDecimalField({ url, field }) {
	const [[key, val]] = Object.entries(field)
	const [clicked, setClicked] = useState(false)
	const [value, setValue] = useState()
	const { authFetch } = FetchWithAuth()
	const [errorMessage, setErrorMessage] = useState([])

	useEffect(() => {
		setValue(val)
	}, [val])

	const submitNewInvoiceAmount = async () => {
		const response = await authFetch(BASEURL + url, {
			m: 'PATCH',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ [key]: parseFloat(value) }),
		})
		if (!response.ok) {
			setErrorMessage([response.statusText])
		} else {
			const data = await response.json()
			setValue(data[key])
			setClicked(false)
		}
	}
	function changeAmount(event) {
		setValue(event.target.value)
	}
	return (
		<>
			{errorMessage.length > 0 ? (
				<MessageBanner
					type="error"
					message={errorMessage}
					calloutType="Error"
					setResponseMessage={setErrorMessage}
				></MessageBanner>
			) : null}

			{clicked.invoiceAmount ? (
				<div class="inputWithIcon">
					<i class="fas fa-check check-icon" onClick={submitNewInvoiceAmount}></i>
					<i class="fas fa-times" onClick={() => setClicked(!clicked)}></i>
					<input
						class="order_info_data info-input"
						type="number"
						value={value}
						placeholder="Type a new Invoice Amount"
						onChange={changeAmount}
					/>
				</div>
			) : (
				<div class="order_info_data edit-enabled">
					Invoice Amount: <span class="order_info_data_values">{value}</span>
					<span
						onClick={() => setClicked({ ...clicked, invoiceAmount: true })}
						class="fas fa-pencil-alt edit-icons"
					></span>{' '}
				</div>
			)}
		</>
	)
}
