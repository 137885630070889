import '../dhl/style/errorTable.css'
import '../dhl/style/invoicePage.css'
import { Switch, Route } from 'react-router-dom'
import ErrorTable from './components/ErrorTable'
import InvoicePage from './InvoicePage'
import ShippingServices from '../../../components/ShippingServices'
import CreateCustomerInvoices from './components/CreateCustomerInvoices'
import DhlDisputes from './components/DhlDisputes'
import DhlInvoices from './components/DhlInvoices'
import UnprocessedShipments from './components/UnprocessedShipments'
import UnBilled from './components/UnBilled'
import DhlPage from './components/DhlPage'
import DhlMenuLinks from './components/DhlMenuLinks'
import DhlShipmentErrorsPage from './components/DhlShipmentErrorsPage'
import CreateQuickbooksInvoices from './components/CreateQuickbooksInvoices'
import ShipmentsLookUp from './components/ShipmentsLookUp'

const DhlRoutes = () => {
	return (
		<>
			<DhlMenuLinks />
			<Switch>
				<Route path="/carriers/dhl/invoices/errors/:id">
					{/* this is the old error page and can still be sued if needed */}
					{/* <ErrorTable /> */}
					<DhlShipmentErrorsPage />
				</Route>
				<Route path="/carriers/dhl/shipment-detail/:id?">
					<ShipmentsLookUp />
				</Route>

				<Route path="/carriers/dhl/invoices/:id">
					<InvoicePage />
				</Route>
				<Route path="/carriers/dhl/shipping_services">
					<ShippingServices carrier={'dhl'} carrierId={3} />
				</Route>
				<Route path="/carriers/dhl/create_customer_invoices">
					<CreateCustomerInvoices />
				</Route>
				<Route path="/carriers/dhl/disputes">
					<DhlDisputes />
				</Route>
				<Route path="/carriers/dhl/invoice_list">
					<DhlInvoices />
				</Route>
				<Route path="/carriers/dhl/unprocessed_shipments_list">
					<UnprocessedShipments />
				</Route>
				<Route path="/carriers/dhl/unbilled">
					<UnBilled />
				</Route>
				<Route path="/carriers/dhl/create_quickbooks_invoices">
					<CreateQuickbooksInvoices />
				</Route>
				<Route path="/carriers/dhl">
					<DhlPage />
				</Route>
			</Switch>
		</>
	)
}

export default DhlRoutes
