import { useHistory } from "react-router-dom"

function PopUpPage({ children }) {
    let history = useHistory()
  return (
    <div class="window-overlay" onClick={() => history.goBack()}>
      <div class="window" onClick={(e) => e.stopPropagation()}>
        <div class="sign-up-form">
          <a href="javascript:history.back()">
            <i class="far fa-times-circle"></i>
          </a>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PopUpPage
