import { useRef } from 'react'

const OsmManifestUploadInput = ({
	setManifestFile,
	manifestFile,
	setErrors,
	setSuccess
}) => {
	const manifestFileInputRef = useRef(null)

	const handleFileChange = (file) => {
		setManifestFile(file[0])
		setErrors('')
		setSuccess('')
	}

	const removeFile = () => {
		setErrors('')
		setSuccess(false)
		manifestFileInputRef.current.value = ''
		setManifestFile('')
	}

	return (
		<div className="file-upload-warper">
			<div
				className={'file-upload'}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				<div className="upload-file">
					<label htmlFor="rate-card-upload" className="custom-file-upload">
						{'Update'}
					</label>
					<input
						id="rate-card-upload"
						type="file"
						accept=".csv,.xlsx"
						ref={manifestFileInputRef}
						onChange={(e) => handleFileChange(e.target.files)}
					/>
					<input id="rate-card-upload" type="file"></input>
					<label className="file-name">
						{manifestFile ? manifestFile.name : 'File Name'}
					</label>
					<label className="custom-file-upload" onClick={removeFile}>
						Cancel
					</label>
				</div>
			</div>
		</div>
	)
}

export default OsmManifestUploadInput
