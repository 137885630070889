import { useState } from 'react'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import downloadCSVfromJsonResponse from '../../../../Functions/downloadCSVfromJsonResponse'
import useInitialLoad from '../../../../hooks/useInitialLoad'
const ProfitReport = () => {
	const { authFetch, loading } = FetchWithAuth()
	// const [profitReport, setProfitReport] = useInitialLoad(
	// 	`/carriers/dhl/profit_report/preview`
	// 	)
	// 	if (profitReport) {
	// 		console.log(profitReport)
	// 	}

	// const CSVToJSON = (csv) => {
	// 	const lines = csv.split('\n')
	// 	const keys = lines[0].split(',')
	// 	return lines.slice(1).map((line) => {
	// 		return line.split(',').reduce((acc, cur, i) => {
	// 			const toAdd = {}
	// 			toAdd[keys[i]] = cur
	// 			return { ...acc, ...toAdd }
	// 		}, {})
	// 	})
	// }
	async function handleDownload() {
		const response = await authFetch(`${BASEURL}/carriers/dhl/profit_report/download`)
		const data = await response.json()
		if (response.ok) {
			downloadCSVfromJsonResponse(data.file)
		}
	}
	function ConvertStringToHTML(str) {
		let parser = new DOMParser()
		let doc = parser.parseFromString(str, 'text/html')
		return doc.body
	}
	return (
		<>
			<button className="profit-report-button" onClick={handleDownload}>
				{loading ? (
					<i class="fas fa-spinner check-icon loading-icon"></i>
				) : (
					'Profit Report'
				)}
			</button>
			{/* {profitReport ? ConvertStringToHTML(profitReport) : null} */}
		</>
	)
}

export default ProfitReport
