import { useState } from 'react'
import { Link } from 'react-router-dom'
import UnprocessedShipmentsTable from './UnproccesdShipmentsTable'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import MessageBanner from '../../../../components/MessageBanner'
import { BASEURL } from '../../../../global-resources'
import classNames from 'classnames'

function UnprocessedShipments() {
	const { authFetch } = FetchWithAuth()
	const [responseMessage, setResponseMessage] = useState([])
	const [offset, setOffset] = useState(0)
	const [pageLimit, setPageLimit] = useState(50)
	const [loading2, setLoading2] = useState(false)
	const [loading3, setLoading3] = useState(false)
	const [tableData, setTableData, setReload, loading] = useInitialLoad(
		`/carriers/dhl/invoicelines?processed=false&limit=${pageLimit}&offset=${offset}`
	)
	const [shipmentsData, setShipmentsData, setReload2, loading4] = useInitialLoad(
		'/carriers/dhl/invoicelines/process'
	)

	const handleProcessShipments = async () => {
		setLoading2(true)
		const response = await authFetch(`${BASEURL}/carriers/dhl/invoicelines/process`, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
		})
		setLoading2(false)
		if (response.ok) {
			const data = await response.json()
			// console.log(data)
			// setShipmentsData(data)
			setReload((prev) => prev + 1)
			setReload2((prev) => prev + 1)
			setResponseMessage([`Successfully Processed ${data.processed} Shipments`])
		} else {
			setResponseMessage([response.statusText])
		}
	}
	async function handleCreateInvoiceLines(params) {
		setLoading3(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/customer_invoicelines/create`,
			{
				m: 'POST',
				h: { 'Content-Type': 'application/json' },
			}
		)
		setLoading3(false)
		const data = await response.json()
		if (
			response.ok &&
			data.customer_invoice_lines_created == data.carrier_invoice_lines_updated
		) {
			setReload2((prev) => prev + 1)
			setResponseMessage([
				`Successfully Created ${data.customer_invoice_lines_created} Invoice Lines`,
			])
		} else {
			setResponseMessage([response.statusText])
		}
	}

	return (
		<>
			{responseMessage.length > 0 ? (
				<MessageBanner
					message={responseMessage}
					setResponseMessage={setResponseMessage}
				></MessageBanner>
			) : null}

			<div className="unprocessed-page">
				{!shipmentsData ? (
					<i class="fas fa-spinner check-icon loading-icon large"></i>
				) : (
					<>
						<div className="info-section_unprocessed-page">
							<div className="info_details_unprocessed-page">
								<div className="info_details_numbers">
									{shipmentsData.unprocessed_shipments.toLocaleString()}
								</div>
								<div className="info_details_text">{'unprocessed shipments'}</div>
							</div>
							<div className="info_details_unprocessed-page">
								<Link
									className="info_details_numbers"
									to={
										shipmentsData.unbilled_shipments > 0 ? '/carriers/dhl/unbilled' : '#'
									}
								>
									{shipmentsData.unbilled_shipments}
								</Link>
								<div className="info_details_text">{'UnBilled'}</div>
							</div>
							<div className="info_details_unprocessed-page">
								<div className="info_details_numbers">{shipmentsData.errors}</div>
								<div className="info_details_text">{'Errors'}</div>
							</div>
						</div>
						<div className="buttons_unprocessed-page">
							<div
								className={classNames({
									'unprocessed-shipments': true,
									'deactivate-button': shipmentsData.unprocessed_shipments < 1,
								})}
								// onClick={() => handleProcessShipments()}
								onClick={()=>alert("this button doesn't work anymore. please use the one in the new site")}
							>
								{loading2 ? (
									<i class="fas fa-spinner check-icon loading-icon"></i>
								) : (
									'Process Shipments'
								)}
							</div>
							<div
								className={classNames({
									'unprocessed-shipments': true,
									'deactivate-button': shipmentsData.unbilled_shipments < 1,
								})}
								onClick={() => handleCreateInvoiceLines()}
							>
								{loading3 ? (
									<i class="fas fa-spinner check-icon loading-icon"></i>
								) : (
									'Create Invoice Lines'
								)}
							</div>

							<Link
								className={classNames({
									'unprocessed-shipments': true,
									'deactivate-button': shipmentsData.errors < 1,
								})}
								to={'/carriers/dhl/invoices/errors/0'}
							>
								Proceed To Fix Errors <i className="fa-solid fa-arrow-right"></i>
							</Link>
						</div>
					</>
				)}
				<UnprocessedShipmentsTable
					offset={offset}
					setOffset={setOffset}
					pageLimit={pageLimit}
					tableData={tableData}
					setReload={setReload}
					loading={loading}
				/>
			</div>
		</>
	)
}

export default UnprocessedShipments
