import { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
const QuickbooksCustomerInfo = ({ access_token, setResponseMessage }) => {
	const { authFetch } = FetchWithAuth()
	const [customerInfo, setCustomerInfo] = useState([])
	const [loading, setLoading] = useState(false)

	async function fetchData() {
		setLoading(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/viewCustomersQuickbooksInfo?access_token=${access_token}`
		)

		if (response.ok) {
			const jsonResponse = await response.json()
			if (jsonResponse.fault == undefined) {
				setCustomerInfo(jsonResponse.QueryResponse.Customer)
			} else {
				setResponseMessage(['Quckbooks ', jsonResponse.fault.type, ' Error'])
			}
		} else {
			setResponseMessage([response.statusText])
		}
		setLoading(false)
	}

	function renderListFrag() {
		return customerInfo.map((customer) => {
			return (
				<>
					<div>
						<span>ID: </span>
						<span>{customer.Id}</span>
					</div>
					<div>
						<span>Company Name: </span>
						<span>{customer.CompanyName ? customer.CompanyName : 'no data'}</span>
					</div>
					<div>
						<span>Emails: </span>
						<span>
							{customer.PrimaryEmailAddr ? customer.PrimaryEmailAddr.Address : 'no data'}
						</span>
					</div>
					<br></br>
				</>
			)
		})
	}

	return (
		<div>
			<h3>Customer Info</h3>
			{customerInfo.length > 0 ? (
				<div>{renderListFrag()}</div>
			) : (
				<button disabled={access_token.length != 991} onClick={fetchData}>
					{loading ? (
						<i class="fas fa-spinner check-icon loading-icon"></i>
					) : (
						'Load Quickbooks Info'
					)}
				</button>
			)}
		</div>
	)
}

export default QuickbooksCustomerInfo
