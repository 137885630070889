import useInitialLoad from '../../../../hooks/useInitialLoad'
import EmailStatus from '../../../../components/Invoices/EmailStatus'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import DhlInvoicesHeaders from './DhlInvoicesHeaders'
import DhlInvoiceRows from './DhlInvoiceRows'
import MessageBanner from '../../../../components/MessageBanner'
import { useState } from 'react'
import InvoiceChart from './InvoiceChart'

const DhlInvoices = () => {
	const [invoices, setInvoices, setReload] = useInitialLoad('/api/dhl/invoices')
	const { authFetch } = FetchWithAuth()
	const [errorMessage, setErrorMessage] = useState([])
	const [loading, setLoading] = useState(false)
	const handleFtpScan = async (e) => {
		setLoading(true)
		e.preventDefault()
		const response = await authFetch(BASEURL + '/api/dhl/scan_ftp')
		setLoading(false)
		if (response.ok) {
			const { unprocessed_invoices } = response
			// setInvoices([...invoices, ...unprocessed_invoices])
			setReload((prev) => prev + 1)
		} else {
			setErrorMessage([response.statusText])
		}
	}

	return !invoices ? null : (
		<div className="dhl-invoice-list">
			{errorMessage.length > 0 ? (
				<MessageBanner message={errorMessage} setResponseMessage={setErrorMessage} />
			) : null}
			<div className="invoice-actions_container">
				<EmailStatus setErrorMessage={setErrorMessage} />
				<button disabled={loading} onClick={handleFtpScan}>
					{loading ? (
						<i class="fas fa-spinner check-icon loading-icon"></i>
					) : (
						'Scan FTP for new invoices'
					)}
				</button>
			</div>
			<h1>Invoices</h1>
			<div className="invoice-chart">
				<InvoiceChart invoices={invoices} />
			</div>
			<DhlInvoicesHeaders />
			<div className="invoice-cards">
				<DhlInvoiceRows invoices={invoices} />
			</div>
		</div>
	)
}

export default DhlInvoices
