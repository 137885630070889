import { useContext } from 'react'
import HeaderContext from '../../context/HeaderVisibleContext'
export const ZenventoryTable = ({ data }) => {
	const { prevScrollPos, setPrevScrollPos, visible, setVisible } =
		useContext(HeaderContext)

	const sorted = [...data].sort((a, b) => {
		return new Date(b.timestamp) - new Date(a.timestamp)
	})

	return (
		<table>
			<thead>
				<tr>
					<th style={{ top: visible ? '76px' : '-2px' }}>Timestamp</th>
					<th style={{ top: visible ? '76px' : '-2px' }}>Frequency</th>
					<th style={{ top: visible ? '76px' : '-2px' }}>Result</th>
				</tr>
			</thead>
			<tbody>
				{sorted.map((single) => {
					return (
						<tr key={single.id}>
							<td>{Date(single.timestamp)}</td>
							<td>{single.frequency}</td>
							<td>{single.result}</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}
