import React, { useState, useRef, useEffect } from 'react'
import { BASEURL } from '../../../global-resources'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import _ from 'lodash'

const TermPageUploadFile = ({
	term = 0,
	service = {},
	currentlyAdding,
	setRateCardTerms = (f) => f,
	setCurrentlyAdding = (f) => f,
}) => {
	const { authFetch } = FetchWithAuth()
	const fileInputRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const [fileToUpload, setFileToUpload] = useState()
	const [errors, setErrors] = useState()
	const [success, setSuccess] = useState(false)

	function handleClick() {
		setCurrentlyAdding({
			...currentlyAdding,
			isAdding: true,
			serviceID: service.id,
			termID: term.id,
		})
	}

	function handleFileChange(file) {
		setFileToUpload(file[0])
		setErrors()
	}
	function removeFile() {
		setCurrentlyAdding({ ...currentlyAdding, isAdding: false, serviceID: '', termID: '' })
		setErrors()
		fileInputRef.current.value = ''
		setFileToUpload()
		setLoading(false)
		setSuccess(false)
	}
	function handleUpload() {
		if (service.rate_card) {
			handleUpdate()
		} else {
			handleAdd()
		}
	}
	const handleUpdate = async () => {
		setLoading(true)
		const formData = new FormData()
		const data = {
			rate_card: fileToUpload,
		}
		Object.entries(data).forEach(([key, value]) => {
			formData.set(key, value)
		})
		const response = await authFetch(
			`${BASEURL}/pricing/ratecardtermservices/${service.rate_card.id}`,
			{
				m: 'PATCH',
				b: formData,
			}
		)
		setLoading(false)
		if (response.status !== 400 && !response.ok) {
			setErrors(response.statusText)
		}
		if (response.ok) {
			fileInputRef.current.value = ''
			setFileToUpload()
			setSuccess(true)
		}
		const responseObject = await response.json()
		if (response.status === 400) {
			setErrors(responseObject.errors[0])
		}
	}

	const handleAdd = async () => {
		setLoading(true)
		const formData = new FormData()
		const data = {
			shipping_service_id: service.id,
			tier_term_id: term.id,
			rate_card: fileToUpload,
		}
		Object.entries(data).forEach(([key, value]) => {
			formData.set(key, value)
		})

		const response = await authFetch(`${BASEURL}/pricing/ratecardtermservices`, {
			m: 'POST',
			b: formData,
		})

		const responseObject = await response.json()
		if (response.status === 400) {
			console.log(responseObject)
			setErrors(responseObject.errors[0])
		}
		if (response.status !== 400 && !response.ok) {
			setErrors(response.statusText)
		}

		if (response.ok) {
			setRateCardTerms((prevState) => {
				const newState = _.cloneDeep(prevState)
				const termIndex = newState.findIndex((term) => {
					return term.id === parseInt(responseObject.tier_term)
				})
				const ourTerm = newState[termIndex]
				const serviceIndex = ourTerm.services_list.findIndex((service) => {
					return service.id === parseInt(responseObject.shipping_service)
				})
				const ourService = ourTerm.services_list[serviceIndex]
				ourService.rate_card = responseObject
				return newState
			})
			fileInputRef.current.value = ''
			setFileToUpload()
		}
		setLoading(false)
	}

	return (
		<>
			<div
				className={
					currentlyAdding.serviceID === service.id && currentlyAdding.termID === term.id
						? 'carrier-tier-add con-tooltip right clicked_accordion-add'
						: 'carrier-tier-add con-tooltip right'
				}
				onClick={() => handleClick()}
			>
				{service.rate_card ? 'Update' : 'Add'}
				<div
					className={
						currentlyAdding.serviceID === service.id && currentlyAdding.termID === term.id
							? 'tooltip visible'
							: 'tooltip'
					}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<div className="upload-file">
						<label
							htmlFor={`${term.start_date}${service.name}`}
							className="custom-file-upload"
						>
							{service.rate_card ? 'Update' : 'Add'}
						</label>
						<input
							id={`${term.start_date}${service.name}`}
							type="file"
							accept=".csv"
							ref={fileInputRef}
							onChange={(e) => handleFileChange(e.target.files)}
						/>
						<input id="file-upload" type="file"></input>
						<label className="file-name">
							{fileToUpload ? fileToUpload.name : 'File Name'}
						</label>
						<label className="custom-file-upload" onClick={removeFile}>
							Cancel
						</label>
					</div>
					<div className="bn5" onClick={handleUpload}>
						{loading ? (
							<i className="fas fa-spinner check-icon loading-icon"></i>
						) : errors ? (
							<span className="error-text">{errors}</span>
						) : success ? (
							<span className="success-text">successfully uploaded</span>
						) : (
							'Upload'
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default TermPageUploadFile
