import React, { useState } from "react"
import useInitialLoad from "../../../hooks/useInitialLoad"
import { Switch, Route } from "react-router-dom"
import { Link } from "react-router-dom"
import CustomerCarrierCards from "./CustomerCarrierCards"
import AddCarrierService from "./AddCarrierService"

function CustomerCarriers({ customer_id }) {
  const [carrierServices, setCarrierServices] = useInitialLoad(
    `/pricing/customer-carriers?customer=${customer_id}`
  )

  return (
    <>
      <Switch>
        <Route path={`/customers/${customer_id}/carrier_services/add`}>
          <AddCarrierService
            customer_id={customer_id}
            setCarrierServices={setCarrierServices}
          />
        </Route>
      </Switch>

      {!carrierServices ? null : (
        <fieldset class="carriers-container">
          <legend>Carriers</legend>
          <div class="carrier-data">
            <CustomerCarrierCards
              carrierServices={carrierServices}
              setCarrierServices={setCarrierServices}
              customer_id={customer_id}
            />
          </div>
          <div className="center-item">
            <Link
              to={`/customers/${customer_id}/carrier_services/add`}
              className="add-carrier-service pop-out-link">
              Add New Carrier Service
            </Link>
          </div>
        </fieldset>
      )}
    </>
  )
}

export default CustomerCarriers
