import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CustomerPage from './components/CustomerPage'
import CustomersPage from './CustomersPage'



function CustomersRouts() {
    return(
        <>
        <Switch>
            <Route path='/customers/:id(\d+)'><CustomerPage/></Route>
            <Route path='/customers'><CustomersPage/></Route>
        </Switch>
        </>
    )
}

export default CustomersRouts