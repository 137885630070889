import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LoginPage } from '../components/LoginPage';

export const PublicRoutes = () => {
    return (
            <Switch>
                <Route exact path='/login'>
                    <LoginPage/>
                </Route>

                <Route path="/">
                    <Redirect to='/login' />
                </Route>
            </Switch>
    )
}