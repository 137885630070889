export const categoryOrderAndNames = [
	'zone_info',
	'customer_info',
	'service',
	'weight',
	'our_package_cost_info',
	'comp_package_cost_info',
	'customer_package_cost_info',
	'surcharges',
	'billing_info',
	'disputes_and_flags',
]

export const dhlErrorShipmentsTableMap = [
	{
		zone_info: [
			['country', null, null],
			['zip_code', 'zip3', null],
			['zone', 'verified_zone', null],
		],
	},
	{
		customer_info: [
			['customer_confirmation_number', null, null],
			['delivery_confirmation_number', null, null],
			['originaldelcon', 'original_delivery_confirmation_number', null],
			['pickup_number', 'variance_pickup_number', 'manual_pickup_number'],
			[null, 'pickup_number_source', null],
			[null, 'pickup_number_errors', null],
			[null, 'customer_id', null],
			[null, 'customer_id_flag', null],
			// [null, 'customer__name', null],
		],
	},
	{
		service: [
			['service_code', 'shipping_service_id_computed', 'shipping_service_id_manual'],
			['material_or_vas_desc', null, null],
			[null, 'service_provider', null],
		],
	},
	{
		weight: [
			['actweight', null, null],
			['height', 'final_height_computed', 'final_height_manual'],
			['length', 'final_length_computed', 'final_length_manual'],
			['width', 'final_width_computed', 'final_width_manual'],
			[null, 'final_weight', null],
			[null, 'final_weight_type', null],
			[
				'billweight',
				'shipping_service_weight_computed',
				'shipping_service_weight_manual',
			],
		],
	},
	{
		our_package_cost_info: [
			[null, 'dhl_tier_id', null],
			[null, 'dhl_tier_id', null],
			[null, 'dhl_rate_card_id', null],
			[null, 'dhl_rate_card_zone', null],
			[null, 'dhl_rate_card_bill_weight', null],
			['our_cost', 'calculated_our_cost', null],
			[null, 'our_package_cost_flags', null],
		],
	},
	{
		comp_package_cost_info: [
			[null, 'comp_tier_id', null],
			[null, 'comp_term_id', null],
			[null, 'comp_rate_card_id', null],
			[null, 'comp_rate_card_bill_weight', null],
			[null, 'comp_rate_card_zone', null],
			[null, 'comp_package_cost', null],
			[null, 'comp_package_cost_flags', null],
		],
	},
	{
		customer_package_cost_info: [
			[null, 'tier_id', null],
			[null, 'tier_term_id', null],
			[null, 'rate_card_id', null],
			[null, 'customer_rate_card_zone', null],
			[null, 'customer_rate_card_bill_weight', null],
			[null, 'customer_package_cost', null],
			[null, 'customer_package_cost_flags', null],
		],
	},
	{
		surcharges: [
			['zfuel', null, null],
			['zsc3_special_handling', 'usps_provider_surcharge', null],
			['zsc7_sc_irreg', 'domestic_per_lb_surcharge', null],
			['extra_length_surcharge', 'domestic_extra_length_surcharge', null],
			['peaksurcharge', 'au_nz_surcharge', null],
			['min_pickupchg', null, null],
			['zsc1_content_endorsement', null, null],
			['zsc2_unassignable_addrs', null, null],
			['zsc4_late_arrival', null, null],
			['zsc5_usps_qualif', null, null],
			['zsc6_client_srd', null, null],
			['zrm1_ret_unassn_chg', null, null],
			['zrm2_ret_unprocess_chg', null, null],
			['zrm3_ret_recall_disc_chg', null, null],
			['zrm4_ret_dup_mail_chg', null, null],
			['zrm5_ret_cont_assur_chg', null, null],
			['zrm6_move_update_return', null, null],
			['zrm7_gst_tax', null, null],
			['zrm8_hst_tax', null, null],
			['zrm9_pst_tax', null, null],
			['zrm10_vat_tax', null, null],
			['zrm11_duties', null, null],
			['zrm12_tax', null, null],
			['zrm13_paper_invoice_feenew', null, null],
			['zrm14_screening_feenew', null, null],
			['zrm15_non_auto_flats', null, null],
			['zrm16_orm_d', null, null],
			['zzws1pri_dropoff', null, null],
			['zzws2pri_sort', null, null],
			['zzws3pri_stamp', null, null],
			['zzws4pri_machine', null, null],
			['zzws5pri_manifest', null, null],
			['zzws6pri_bpm', null, null],
			['zzws7pri_new', null, null],
			['zzws8pri_new', null, null],
			['zzws9pri_new', null, null],
			[null, 'sum_of_stated_surcharges', null],
			[null, 'total_surcharges', null],
		],
	},
	{
		billing_info: [
			[null, 'customer_total_surcharges', null],
			[null, 'billable_amount', null],
			[null, 'billed_amount', null],
			[null, 'billable_balance', null],
		],
	},
	{
		disputes_and_flags: [
			[null, 'dhl_total_charges_stated', null],
			[null, 'dhl_credits', 'dhl_credits'],
			[null, 'dhl_total_charges_calculated', null],
			[null, 'missing_zip_code_disputable', null],
			[null, 'missing_customer_disputable', null],
			[null, 'non_applicable_surcharges_disputable', null],
			[null, 'package_charge_disputable', null],
			[null, 'domestic_per_lb_disputable', null],
			[null, 'domestic_extra_length_disputable', null],
			[null, 'domestic_extra_volume_disputable', null],
			[null, 'au_nz_per_lb_disputable', null],
			[null, 'usps_provider_surcharge_disputable', null],
			[null, 'total_disputables', null],
			[null, 'total_disputed', null],
			[null, 'disputable_balance', null],
			[null, 'flag_summary', null],
			[null, 'has_flags', null],
		],
	},
]
