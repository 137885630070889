import { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import TableHeader from './TableHeader'
import { disputeTableHeaders } from './disputeTableHeaders'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import MessageBanner from '../../../../components/MessageBanner'
import DhlDisputesTableRow from './DhlDisputesTableRow'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import downloadCSVfromJsonResponse from '../../../../Functions/downloadCSVfromJsonResponse'
import DisputesPopUp from './DisputesPopUp'

const DhlDisputes = () => {
	const { authFetch } = FetchWithAuth()
	const [tableData, setTableData, setReload, loading] = useInitialLoad(
		'/carriers/dhl/disputes'
	)
	const [responseMessage, setResponseMessage] = useState([])
	// console.log(tableData)
	const handleDownload = async () => {
		const response = await authFetch(`${BASEURL}/carriers/dhl/disputes/download`)
		const data = await response.json()
		if (response.ok) {
			downloadCSVfromJsonResponse(data.file)
		}
	}

	return loading ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : !tableData ? null : (
		<>
			<Switch>
				<Route path={`/carriers/dhl/disputes/:id/note`}>
					<DisputesPopUp tableData={tableData} setTableData={setTableData} />
				</Route>
			</Switch>
			{responseMessage.length > 0 ? (
				<>
					<MessageBanner
						message={Object.values(responseMessage[0])}
						setResponseMessage={setResponseMessage}
					></MessageBanner>
				</>
			) : null}
			{!tableData.filter((dispute) => dispute.is_sent == false).length > 0 ? null : (
				<>
					<div className="dispute-table error-table">
						<div className="table-title">Disputes To Send</div>
						<table>
							<TableHeader
								headers={Object.keys(disputeTableHeaders)}
								headerObject={disputeTableHeaders}
								// isCheckbox={true}
								// checkboxName={'Mark'}
							/>
							<DhlDisputesTableRow
								tableData={tableData.filter((dispute) => dispute.is_sent == false)}
								tableHeaders={Object.keys(disputeTableHeaders)}
								buttonName={'Sent'}
								felid_to_toggle={'is_sent'}
							/>
						</table>
					</div>
					<div className="disputes-download">
						<span onClick={() => handleDownload()}>Download UnSent Disputes</span>
					</div>
				</>
			)}
			{/* if there is no history don't display anything */}
			{!tableData.filter((dispute) => dispute.is_sent == true && dispute.is_open == true)
				.length > 0 ? null : (
				<>
					<div className="table-title">Disputes Sent</div>
					<div className="error-table">
						<table>
							<TableHeader
								headers={Object.keys(disputeTableHeaders)}
								headerObject={disputeTableHeaders}
								// isCheckbox={true}
								// checkboxName={'Mark'}
							/>
							<DhlDisputesTableRow
								tableData={tableData.filter(
									(dispute) => dispute.is_sent == true && dispute.is_open == true
								)}
								tableHeaders={Object.keys(disputeTableHeaders)}
								buttonName={'Done'}
								felid_to_toggle={'is_open'}
							/>
						</table>
					</div>
				</>
			)}
			{!tableData.filter((dispute) => dispute.is_sent == true && dispute.is_open == false)
				.length > 0 ? null : (
				<>
					<div className="table-title">Dispute History</div>
					<div className="error-table">
						<table>
							<TableHeader
								headers={Object.keys(disputeTableHeaders)}
								headerObject={disputeTableHeaders}
								// isCheckbox={true}
								// checkboxName={'Activate'}
							/>
							<DhlDisputesTableRow
								tableData={tableData.filter(
									(dispute) => dispute.is_sent == true && dispute.is_open == false
								)}
								tableHeaders={Object.keys(disputeTableHeaders)}
								buttonName={'Activate'}
								felid_to_toggle={'is_open'}
							/>
						</table>
					</div>
				</>
			)}
		</>
	)
}

export default DhlDisputes
