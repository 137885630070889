import { Link } from 'react-router-dom'
import InvoiceIcon from '../../osm/components/InvoiceIcon'

const DhlInvoiceRows = ({ invoices }) => {
	const renderInvoiceRowsFrag = () => {
		return invoices.map(({ id, inv_no, vendor, inv_date, amount_stated, processed }) => {
			return (
				<div key={id} class="customer-card">
					<InvoiceIcon />
					<div class="customer-data">
						<Link to={`invoices/${id}`}>{inv_no}</Link>
					</div>
					<div class="customer-data">{vendor}</div>
					<div class="customer-data">{inv_date}</div>
					<div class="customer-data">{amount_stated}</div>
					<div class="customer-data">{processed.toString()}</div>
				</div>
			)
		})
	}
	return <>{renderInvoiceRowsFrag()}</>
}

export default DhlInvoiceRows
