import React, { useState } from 'react'
import useInitialLoad from '../hooks/useInitialLoad'
import { FetchWithAuth } from '../hooks/FetchWithAuth'
import { Switch, Route } from 'react-router-dom'
import TableHeader from '../pages/Carriers/dhl/components/TableHeader'
import TableRow from '../pages/Carriers/dhl/components/TableRow'
import EditShippingService from '../pages/Carriers/dhl/components/EditShippingService'
import CreateShippingService from '../pages/Carriers/dhl/components/CreateShippingService'
import { Link } from 'react-router-dom'

function ShippingServices({ carrier, carrierId }) {
	const { authFetch } = FetchWithAuth()

	const [servicesList, setServicesList] = useInitialLoad(
		`/pricing/shippingservices?carrier=${carrierId}`
	)
	const tableHeaders = ['code', 'name', 'terminus', 'max_weight_oz', 'min_weight_oz']

	return (
		<div className="shipping-services-page-container">
			<h1>Shipping Services</h1>
			<div className="add-service-button">
				<Link to={`/carriers/${carrier}/shipping_services/create`}>
					<button class="wiggle-button">
						<span class="bn54span">Add Service</span>
					</button>
				</Link>
			</div>
			<div id="invoice-table">
				{!servicesList ? (
					<i id="table-loader" class="fas fa-spinner check-icon loading-icon large"></i>
				) : (
					<div class="invoice_table">
						<table className="styled-table shipping-service">
							<TableHeader headers={tableHeaders} />

							<tbody>
								{servicesList.map((row, rowNumber) => {
									return (
										<TableRow
											headers={tableHeaders}
											row={row}
											rowNumber={rowNumber}
											carrier={carrier}
										/>
									)
								})}
							</tbody>
						</table>
						<Switch>
							<Route path={`/carriers/${carrier}/shipping_services/create`}>
								<CreateShippingService setServicesList={setServicesList} />
							</Route>
							<Route path={`/carriers/${carrier}/shipping_services/:id/edit`}>
								<EditShippingService
									servicesList={servicesList}
									setServicesList={setServicesList}
								/>
							</Route>
						</Switch>
					</div>
				)}
			</div>
		</div>
	)
}

export default ShippingServices
