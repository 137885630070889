import React, { useState } from 'react'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import Accordion from './Accordion'
import { Prompt } from 'react-router-dom'
import AccordionChildren from './AccordionChildren'
import isFileDownload from '../../../Functions/isFileDownload'
import downloadFile from '../../../Functions/downloadFile'

const RateCardTerm = ({
	rateCardTerms,
	rateCards,
	setRateCardTerms,
	setTermToUpdate,
}) => {
	const { authFetch } = FetchWithAuth()
	const [currentlyAdding, setCurrentlyAdding] = useState({
		isAdding: false,
		serviceID: '',
		termID: '',
	})

	const handleDownload = async (id, serviceName) => {
		const response = await authFetch(`${BASEURL}/pricing/ratecardtermservices/${id}`)
		if (response.ok && isFileDownload(response)) downloadFile(response)
	}

	function convertStringToDateObject(string) {
		const date = new Date(string)
		return date
	}

	function accordionContentFrag(term) {
		return term.services_list.map((service, i) => {
			return (
				<AccordionChildren
					service={service}
					term={term}
					handleDownload={handleDownload}
					setRateCardTerms={setRateCardTerms}
					currentlyAdding={currentlyAdding}
					setCurrentlyAdding={setCurrentlyAdding}
				/>
			)
		})
	}

	function renderRateCards() {
		rateCardTerms
			.sort(
				(a, b) =>
					convertStringToDateObject(a.start_date) -
					convertStringToDateObject(b.start_date)
			)
			.reverse()
		return rateCardTerms.map((term, i) => (
			<Accordion
				title={'Tier'}
				value={rateCards.tier}
				term={term}
				setTermToUpdate={setTermToUpdate}
			>
				{accordionContentFrag(term)}
			</Accordion>
		))
	}
	return (
		<>
			{renderRateCards()}{' '}
			<Prompt
				when={currentlyAdding.isAdding}
				message={() =>
					'Are you sure you want to leave this page?. all fils which are currently being added will be lost'
				}
			/>
		</>
	)
}

export default RateCardTerm
