import React from 'react'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase.js'

const TableHeader = ({
	headers,
	isCheckbox = false,
	checkboxName,
	headerObject = {},
}) => {
	const styleType = isCheckbox ? 'table-header-checkbox-tooltip' : ''
	return (
		<thead id="invoice-table-header">
			<tr className={styleType}>
				{headers.map((header) => (
					<th key={header}>
						{snakeCaseToTitleCase(
							// line allows you to either have a key value pare which allows
							// for more customizations when it comes to naming your header names
							headerObject[header] ? `${headerObject[header]}` : header
						)}
					</th>
				))}
				<span className="error-table-header-text">{checkboxName}</span>
			</tr>
		</thead>
	)
}

export default TableHeader
