import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import QuickbooksCustomerInfo from './QuickbooksCustomerInfo'
import InvoicesToSendToQuickbooksList from './InvoicesToSendToQuickbooksList'
import MessageBanner from '../../../../components/MessageBanner'

const CreateQuickbooksInvoices = () => {
	const history = useHistory()
	const { authFetch } = FetchWithAuth()
	const [invoices, setInvoices] = useState()
	const [loading, setLoading] = useState(false)
	const [access_token, setAccess_token] = useState('')
	const [responseMessage, setResponseMessage] = useState([])
	// if this page is clicked from the link the data will be passed
	// by the history api and if it is shared as a link the data will be fetched
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			const response = await authFetch(
				`${BASEURL}/carriers/dhl/customer_invoices/sums?sent=false`
			)
			if (response.ok) {
				const jsonResponse = await response.json()
				setInvoices(jsonResponse)
			}
			setLoading(false)
		}
		if (history.location.state != undefined) {
			setInvoices(history.location.state.sums)
		} else {
			fetchData()
		}
	}, [])

	return !invoices ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : (
		<>
			{responseMessage.length > 0 ? (
				<MessageBanner
					message={responseMessage}
					setResponseMessage={setResponseMessage}
				/>
			) : null}
			<div className="quickbooks_page_warper">
				<div className="token_upload_warper">
					<label className="">
						<a
							href="https://developer.intuit.com/app/developer/playground"
							target="blank_"
						>
							Submit Access Token
						</a>
					</label>

					<textarea
						name="access_token"
						rows="3"
						value={access_token}
						onChange={(e) => {
							setAccess_token(e.target.value)
						}}
					></textarea>
				</div>
				<div className="quickbooks-section-warper">
					<div className="create_invoices_section">
						<InvoicesToSendToQuickbooksList
							invoices={invoices}
							access_token={access_token}
							setResponseMessage={setResponseMessage}
						/>
					</div>
					<div className="user_info_section">
						<QuickbooksCustomerInfo
							access_token={access_token}
							setResponseMessage={setResponseMessage}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateQuickbooksInvoices
