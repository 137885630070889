import { useState } from 'react'
import TableHeader from './TableHeader'
import CustomerInvoiceRows from './CustomerInvoiceRows'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { invoiceTableHeaders } from './inviceTableHeaders'
const WeeklyInvoices = ({ setReload, setEditedFields, setResponseMessage, week }) => {
	const [opened, setOpened] = useState(false)
	const [weeklyInvoices, setWeeklyInvoices] = useState()
	const [loading, setLoading] = useState(false)
	const { authFetch } = FetchWithAuth()

	async function fetchAndSethWeeklyInvoices(date) {
		setLoading(true)
		const request = await authFetch(
			`${BASEURL}/carriers/dhl/customer_invoices/sums?sent=true&billed_date=${date}`
		)
		if (request.ok) {
			const jsonData = await request.json()
			setWeeklyInvoices(jsonData)
		}
		setLoading(false)
	}

	function handleClick() {
		setOpened(!opened)
		if (!weeklyInvoices) {
			console.log('fetching')
			fetchAndSethWeeklyInvoices(week.billed_date)
		}
	}

	function renderWeeklyInvoices(date) {
		return (
			<table style={{ display: opened ? 'initial' : 'none' }}>
				<TableHeader
					tableData={weeklyInvoices}
					headers={Object.keys(invoiceTableHeaders)}
					headerObject={invoiceTableHeaders}
				/>
				<CustomerInvoiceRows
					tableData={weeklyInvoices}
					headers={Object.keys(invoiceTableHeaders)}
					setEditedFields={setEditedFields}
					setResponseMessage={setResponseMessage}
					rowButtonName={'UnSend'}
					setReload={setReload}
					canHighlightRow={false}
					setSums={setWeeklyInvoices}
				/>
			</table>
		)
	}
	function renderWeeklyInvoiceWrapper() {
		return (
			<div className="weeksInvoicesWarper">
				<div
					className="weeksSummery"
					onClick={() => {
						handleClick()
					}}
				>
					<div className="weeksSummeryInfo">
						<span>Billing Data: </span>
						{week.billed_date}
					</div>
					<div className="weeksSummeryInfo">
						{loading ? <i className="fas fa-spinner check-icon loading-icon"></i> : null}
					</div>
					<div className="weeksSummeryInfo">
						<span>Invoice Quantity: </span>
						{week.qty}
					</div>
				</div>
				{!weeklyInvoices ? null : renderWeeklyInvoices(week.billed_date)}
			</div>
		)
	}

	return <>{renderWeeklyInvoiceWrapper()}</>
}

export default WeeklyInvoices
