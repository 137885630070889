import { useState } from 'react'
import { BASEURL } from '../../../global-resources'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import downloadCSVfromJsonResponse from '../../../Functions/downloadCSVfromJsonResponse'

export default function InvoiceList({ invoices }) {
	const { authFetch } = FetchWithAuth()
	async function handleDownload(invoiceId) {
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/customer_invoices/${invoiceId}/download`
		)
		const data = await response.json()
		if (response.ok) {
			downloadCSVfromJsonResponse(data.file)
		}
	}

	function renderFrag() {
		let reversedArray = [...invoices].reverse()
		return reversedArray.map((invoice) => {
			return (
				<div className="customer-invoice-card" onClick={() => handleDownload(invoice.id)}>
					<span className="invoice-id">
						{invoice.quickbooks_invoice_number
							? invoice.quickbooks_invoice_number
							: 'No Data'}
					</span>
					<span className="invoice-data">
						{invoice.billed_date ? invoice.billed_date : 'No Data'}
					</span>
					<span className="invoice-total">${invoice.total_charges.toLocaleString()}</span>
				</div>
			)
		})
	}
	return renderFrag()
}
