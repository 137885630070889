import React from 'react'

const InvoiceIcon = () => {
	return (
		<div class="customer-data icon">
			<i class="fa-solid fa-file-invoice-dollar"></i>
		</div>
	)
}

export default InvoiceIcon
