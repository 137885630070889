function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div className="errorFallback-div">
			<p>Something went wrong. Please try agin later</p>
			<pre>Message for Dev is ({error.message})</pre>
			{/* <button onClick={resetErrorBoundary}>Try again</button> */}
		</div>
	)
}

export default ErrorFallback
