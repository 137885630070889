import { useState } from 'react'
import ShipmentSearch from './ShipmentSearch'
import ShipmentSearchResults from './ShipmentSearchResults'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import ShipmentDetailView from './ShipmentDetailView'
import MessageBanner from '../../../../components/MessageBanner'
import ShipmentLookUpLeftSide from './ShipmentLookUpLeftSide'

function ShipmentsLookUp() {
	const [shipmentsSearchResults, setShipmentsSearchResults] = useState([fillerData])
	//const [invoices, setInvoices, setReload, loading] = useInitialLoad('/api/dhl/invoices') // ?limit=1
	const [responseMessage, setResponseMessage] = useState([])

	return (
		<>
			<div className="shipment-error-page_dhl">
				<ShipmentLookUpLeftSide
					shipmentsSearchResults={shipmentsSearchResults}
					setShipmentsSearchResults={setShipmentsSearchResults}
					setResponseMessage={setResponseMessage}
					//lastInvoiceId={invoices[0].id} // this is used to only look for a invoice in the last few invoices
				/>
				<div className="shipment-data">
					<ShipmentDetailView
						shipmentsSearchResults={shipmentsSearchResults}
						setShipmentsSearchResults={setShipmentsSearchResults}
					/>
				</div>
			</div>

			{responseMessage.length < 1 ? null : (
				<MessageBanner
					message={responseMessage}
					setResponseMessage={setResponseMessage}
				/>
			)}
		</>
	)
}

export default ShipmentsLookUp

const fillerData = {
	id: '',
	created: '',
	modified: '',
	row_type: '',
	sold_to: '',
	inv_posnr: '',
	bol_number: '',
	billing_reference_1: null,
	billing_reference_2: null,
	shippoint: '',
	pickupfrom: '',
	ship_date: '',
	putime: '',
	internal_tracking_num: '',
	customer_confirmation_number: '',
	delivery_confirmation_number: '',
	name: null,
	address1: '',
	address2: null,
	city: '',
	state: '',
	zip_code: '',
	country: '',
	service_code: '',
	material_or_vas_desc: '',
	actweight: '',
	uom_actweight: '',
	billweight: '',
	uom_billwgt: '',
	quantity: '',
	uom_quantity: null,
	zone: '',
	our_cost: '',
	custref: '',
	custref2: null,
	zzws1pri_dropoff: null,
	zzws2pri_sort: null,
	zzws3pri_stamp: null,
	zzws4pri_machine: null,
	zzws5pri_manifest: null,
	zzws6pri_bpm: null,
	zzws7pri_new: null,
	zzws8pri_new: null,
	zzws9pri_new: null,
	zsc1_content_endorsement: null,
	zsc2_unassignable_addrs: null,
	zsc3_special_handling: null,
	zsc4_late_arrival: null,
	zsc5_usps_qualif: null,
	zsc6_client_srd: null,
	zsc7_sc_irreg: null,
	zrm1_ret_unassn_chg: null,
	zrm2_ret_unprocess_chg: null,
	zrm3_ret_recall_disc_chg: null,
	zrm4_ret_dup_mail_chg: null,
	zrm5_ret_cont_assur_chg: null,
	zrm6_move_update_return: null,
	zrm7_gst_tax: null,
	zrm8_hst_tax: null,
	zrm9_pst_tax: null,
	zrm10_vat_tax: null,
	zrm11_duties: null,
	zrm12_tax: null,
	zrm13_paper_invoice_feenew: null,
	zrm14_screening_feenew: null,
	zrm15_non_auto_flats: null,
	zrm16_orm_d: null,
	zfuel: '',
	min_pickupchg: null,
	originaldelcon: null,
	dim_weight: null,
	uomdim_wt: null,
	height: null,
	length: null,
	width: null,
	uom_dims: null,
	peaksurcharge: '',
	reserved_future_use: null,
	extra_length_surcharge: null,
	extra_volume_surcharge: null,
	reserved_future_use4: null,
	reserved_future_use5: null,
	invoice_id: null,
	line_type: '',
	formatted_service_code: '',
	terminus: '',
	return_flag: null,
	flags: null,
	formatted_zip_code: '',
	zip3: null,
	verified_zone: '',
	zone_description: '',
	service_provider: '',
	final_height_computed: null,
	final_length_computed: null,
	final_width_computed: null,
	final_height_manual: null,
	final_length_manual: null,
	final_width_manual: null,
	final_height: null,
	final_length: null,
	final_width: null,
	total_cubic_inches: null,
	calculated_dimensional_weight: null,
	actual_weight: '',
	bill_weight: '',
	dimensional_weight: null,
	final_weight: '',
	final_weight_type: '',
	pricing_period: '',
	original_delivery_confirmation_number: '',
	pickup_number: null,
	variance_pickup_number: '',
	pickup_number_errors: null,
	manual_pickup_number: null,
	pickup_number_override: null,
	final_pickup_number: '',
	pickup_number_source: '',
	customer_id: null,
	customer_id_flag: null,
	shipping_service_id_computed: null,
	shipping_service_id_manual: null,
	shipping_service_id: null,
	shipping_service_weight_computed: null,
	shipping_service_weight_manual: null,
	shipping_service_weight: null,
	tier_id: null,
	tier_term_id: null,
	rate_card_id: null,
	customer_rate_card_zone: '',
	customer_rate_card_bill_weight: '',
	customer_package_cost: '',
	customer_package_cost_flags: null,
	dhl_tier_id: null,
	dhl_term_id: null,
	dhl_rate_card_id: null,
	dhl_rate_card_zone: '',
	dhl_rate_card_bill_weight: '',
	calculated_our_cost: '',
	our_package_cost_flags: null,
	comp_tier_id: null,
	comp_term_id: null,
	comp_rate_card_id: null,
	comp_rate_card_bill_weight: '',
	comp_rate_card_zone: '',
	comp_package_cost: '',
	comp_package_cost_flags: null,
	usps_provider_surcharge: null,
	domestic_per_lb_surcharge: null,
	domestic_extra_length_surcharge: null,
	domestic_extra_volume_surcharge: null,
	au_nz_surcharge: null,
	sum_of_misc_surcharges: null,
	total_surcharges: null,
	customer_total_surcharges: null,
	billable_amount: null,
	billed_amount: null,
	billable_balance: null,
	dhl_total_charges_stated: null,
	dhl_credits: null,
	dhl_total_charges_calculated: null,
	profit: null,
	missing_zip_code_disputable: null,
	missing_customer_disputable: null,
	non_applicable_surcharges_disputable: null,
	package_charge_disputable: null,
	domestic_per_lb_disputable: null,
	domestic_extra_length_disputable: null,
	domestic_extra_volume_disputable: null,
	au_nz_per_lb_disputable: null,
	usps_provider_surcharge_disputable: null,
	total_disputables: null,
	total_disputed: null,
	disputable_balance: null,
	flag_summary: [''],
	has_flags: false,
	processed: true,
}
