import useOffsetDimensions from '../../../../hooks/useOffsetDimensions'
import DhlShipmentErrorDetailCategory from './DhlShipmentErrorDetailCategory'
import { useParams } from 'react-router-dom'
import {
	categoryOrderAndNames,
	dhlErrorShipmentsTableMap,
} from '../dhlErrorShipmentsTableMap'

const DhlShipmentErrorDetail = ({ shipmentData, setShipmentsData }) => {
	const { ref, divWidth } = useOffsetDimensions()
	const { id } = useParams()
	function renderInfoSections() {
		return categoryOrderAndNames.map((categoryHeader, categoryIndex) => {
			return (
				<DhlShipmentErrorDetailCategory
					shipmentData={shipmentData[id]}
					categoryHeader={categoryHeader}
					categoryIndex={categoryIndex}
					selectedCard={id}
					setShipmentsSearchResults={setShipmentsData}
				/>
			)
		})
	}

	return shipmentData.length < 1 ? null : (
		<>
			<div ref={ref} className="error-shipment-header-warper">
				<div className="error-shipment-headers" style={{ minWidth: divWidth }}>
					<div className="error-shipment-header-name">Stated</div>
					<div className="error-shipment-header-name">Calculated</div>
					<div className="error-shipment-header-name">Manual</div>
					{/* <div className="error-shipment-header-name final">Final</div> */}
				</div>
			</div>

			<div className="shipment-details-container">{renderInfoSections()}</div>
		</>
	)
}

export default DhlShipmentErrorDetail
