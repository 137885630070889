// export const errorTableHeaders = [
// 	'id',
// 	'final_height',
// 	'final_length',
// 	'final_width',
// 	'final_length_manual',
// 	'final_height_manual',
// 	'final_width_manual',
// 	'manual_pickup_number',
// 	'shipping_service_weight_manual',
// 	'shipping_service_id_manual',
// 	'customer_rate_card_bill_weight',
// 	'ship_date',
// 	'service_code',
// 	'original_delivery_confirmation_number',
// 	'customer_confirmation_number',
// 	'delivery_confirmation_number',
// 	'country',
// 	'formatted_zip_code',
// 	'bill_weight', // ad oz to the end
// 	'shipping_service_weight',
// 	'tier_id',
// 	'tier_term_id',
// 	'rate_card_id',
// 	'customer_rate_card_zone',
// 	'customer_rate_card_bill_weight',
// 	'customer_package_cost',
// 	'customer_package_cost_flags',
// 	'our_package_cost_flags',
// 	'comp_package_cost_flags',
// 	'dhl_tier_id',
// 	'dhl_term_id',
// 	'dhl_rate_card_id',
// 	'dhl_rate_card_zone',
// 	'dhl_rate_card_bill_weight',
// 	'calculated_our_cost',
// 	'comp_tier_id',
// 	'comp_term_id',
// 	'comp_rate_card_id',
// 	'comp_rate_card_bill_weight',
// 	'comp_rate_card_zone',
// 	'comp_package_cost',
// 	'zsc7_sc_irreg',
// 	'zfuel',
// 	'dimensional_weight',
// 	'calculated_dimensional_weight',
// 	'peaksurcharge',
// 	'verified_zone',
// 	'domestic_extra_length_surcharge',
// 	'domestic_extra_volume_surcharge',
// 	'non_applicable_surcharges_disputable',
// 	'package_charge_disputable',
// 	'domestic_per_lb_disputable',
// 	'au_nz_per_lb_disputable',
// 	'domestic_extra_length_disputable',
// 	'domestic_extra_volume_disputable',
// 	'usps_provider_surcharge_disputable',
// ]

export let errorTableHeaders = {
	// id: 'Invoice #',
	original_delivery_confirmation_number: 'Orignal Delivery #',
	customer_confirmation_number: 'Customer Conf #',
	delivery_confirmation_number: 'Delivery Conf #',
	country: 'Country',
	formatted_zip_code: 'Zip Code',
	bill_weight: 'DHL Bill Weight', // ad oz to the end
	shipping_service_weight: 'Bill Weight',
	zsc7_sc_irreg: 'DIM SC',
	zfuel: 'Fuel SC',
	final_height: 'Final Height',
	final_length: 'Final Weight',
	final_width: 'Final Width',
	dimensional_weight: 'DHL Dim Weight',
	dhl_credits: 'DHL Credit',
	calculated_dimensional_weight: 'A1S Dim Weight',
	final_weight: 'Final Weight',
	final_weight_type: 'Final Weight Type',
	peaksurcharge: 'Peak SC',
	verified_zone: 'Zone',
	tier_id: 'tier_id',
	tier_term_id: 'tier_term_id',
	rate_card_id: 'rate_card_id',
	dhl_tier_id: 'dhl_tier_id',
	dhl_term_id: 'dhl_term_id',
	dhl_rate_card_id: 'dhl_rate_card_id',
	dhl_rate_card_zone: 'dhl_rate_card_zone',
	dhl_rate_card_bill_weight: 'dhl_rate_card_bill_weight',
	calculated_our_cost: 'calculated_our_cost',
	our_package_cost_flags: 'our_package_cost_flags',
	comp_tier_id: 'comp_tier_id',
	comp_term_id: 'comp_term_id',
	comp_rate_card_id: 'comp_rate_card_id',
	comp_rate_card_bill_weight: 'comp_rate_card_bill_weight',
	comp_rate_card_zone: 'comp_rate_card_zone',
	comp_package_cost: 'comp_package_cost',
	comp_package_cost_flags: 'comp_package_cost_flags',
	domestic_extra_length_surcharge: 'Length Surcharge',
	domestic_extra_volume_surcharge: 'Volume Surcharge',
	customer_package_cost_flags: 'customer cost flags',
	our_package_cost_flags: 'our cost flags',
	comp_package_cost_flags: 'comp cost flags',
	non_applicable_surcharges_disputable: 'non_applicable_surcharges_disputable',
	package_charge_disputable: 'package_charge_disputable',
	domestic_per_lb_disputable: 'domestic_per_lb_disputable',
	missing_customer_disputable: 'Missing Customer Disputable',
	au_nz_per_lb_disputable: 'au_nz_per_lb_disputable',
	domestic_extra_length_disputable: 'domestic_extra_length_disputable',
	domestic_extra_volume_disputable: 'domestic_extra_volume_disputable',
	usps_provider_surcharge_disputable: 'usps_provider_surcharge_disputable',
	customer_rate_card_zone: 'customer_rate_card_zone',
	customer_rate_card_bill_weight: 'customer_rate_card_bill_weight',
	customer_package_cost: 'customer_package_cost',
	customer_package_cost_flags: 'customer_package_cost_flags',
	final_length_manual: 'Final Length Manual',
	final_height_manual: 'Final Height Manual',
	final_width_manual: 'Final Width Manual',
	shipping_service_weight_manual: 'Bill Weight Manual',
}
