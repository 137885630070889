export const invoiceTableHeaders = {
	customer_invoice_id: 'customer_invoice_id',
	account_name: 'account_name',
	account_number: 'account_number',
	billing_period: 'billing_period',
	shipment_qty: 'shipment_qty',
	payment_type: 'payment_type',
	fee_rate: 'fee_rate',
	total_charge: 'total_charge',
	fee_amount: 'fee_amount',
	fee_description: 'fee_description',
	savings: 'savings',
	surcharge: 'surcharge',
	charge: 'charge',
	USPS: 'USPS',
	total_DHL_invoice: 'total_DHL_invoice',
	profit: 'profit',
	quickbooks_invoice_number: 'quickbooks_invoice_number',
}
