import React, { useContext } from 'react'
import { StateContext } from '../context/context-init'

//main page once logged in
const Dashboard = () => {
	return (
		<div>
			<h2>Welcome</h2>
			<p>
				There's nothing here yet, but just you wait...
				<br />
				Try clicking Invoices
			</p>
		</div>
	)
}

export default Dashboard
