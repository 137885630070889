import React, { useState } from 'react'
import { useParams } from 'react-router'
import { BASEURL } from '../../../../global-resources'
import { useAuthFetch } from '../../../../hooks/useAuthFetch'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase.js'
import { Link } from 'react-router-dom'
function ProcessCardWithErrorTableLink({
	methodName,
	queryParam,
	processedCount,
	processableCount,
	setProcessInfo,
	includeCheckbox = true,
}) {
	const [checked, setChecked] = useState(false)
	const { id } = useParams()
	const { authFetch } = useAuthFetch()
	const url = `/api/dhl/invoices/${id}/process`
	const [loading, setLoading] = useState(false)

	const handleClick = async (e) => {
		const { name } = e.target
		setLoading(true)
		const response = await authFetch(BASEURL + url, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ method: name, overwrite: checked }),
		})
		setLoading(false)

		setProcessInfo((currentState) => {
			return { ...currentState, ...response }
		})
	}

	return (
		<div className="invoice_card_with_errors_container">
			<div class="invoice_cards_with_errors">
				{processedCount == processableCount && processedCount != 0 ? (
					<div class="container__ribbon">
						<span>Complete</span>
					</div>
				) : null}

				<button
					className="process_invoice_card_button"
					onClick={handleClick}
					name={methodName}
					disabled={loading}
				>
					{loading ? (
						<i class="fas fa-spinner check-icon loading-icon"></i>
					) : (
						snakeCaseToTitleCase(methodName)
					)}
				</button>
				<div className="processed_and_checkbox">
					<span class="processedCount">
						{processedCount}/{processableCount} Processed
					</span>

					{includeCheckbox ? (
						<label class="check-box-container">
							Reprocess
							<input
								name={'get_pickup_number'}
								type="checkbox"
								checked={checked ? 'checked' : null}
								onChange={() => setChecked((checked) => !checked)}
							></input>
							<span class="checkmark"></span>
						</label>
					) : null}
				</div>
				<button className="process_invoice_card_button">
					<Link to={`/carriers/dhl/invoices/${id}/errors/${queryParam}`}>
						Toggle missing info
					</Link>
				</button>
			</div>
		</div>
	)
}

export default ProcessCardWithErrorTableLink
