export const debounce = (fn, delay) => {
	let timeOutId
	return function (...args) {
		if (timeOutId) {
			clearTimeout(timeOutId)
		}
		timeOutId = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}
