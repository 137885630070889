import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import css from 'classnames'

// example of this component use can be found on below
function MessageBanner({ children, message = [], setResponseMessage }) {
	const renderElAlert = function () {
		return React.cloneElement(children)
	}
	function handleClick(e) {
		e.stopPropagation() // this will prevent any side effect from accruing if this components parent has a onClick attached to it
		setResponseMessage([])
	}

	useEffect(() => {
		// this use effect will remove the message banner 3sec after it is initially created
		const timer = setTimeout(() => {
			setResponseMessage([])
		}, 3000)

		return () => clearTimeout(timer)
	}, [])
	const type =
		message[0].includes('Successfully') || message.includes('Successfully')
			? 'success'
			: 'error'
	const calloutType = message[0].includes('Successfully') ? 'Success' : 'Error'
	return message.length > 0 ? (
		<div className={css(style.alert, style[type])}>
			<span className={style.closebtn} onClick={(e) => handleClick(e)}>
				&times;
			</span>
			<strong className={style.callout}>{calloutType}:</strong>
			{children ? children : message}
			{/* renderElAlert() */}
		</div>
	) : null
}

export default MessageBanner

/* <MessageBanner
                // type="error"     // component will default to error unless the word 'Successfully' is included in the message
                // calloutType="Error"
                message={errorMessage}
                setResponseMessage={setErrorMessage}
/> */
///////////////////////////// OR
