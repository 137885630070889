import React from "react";
import { useLocation, Link } from "react-router-dom";

function NavBarLink({ children, to, isActive=true}) {
    const location = useLocation()
    const match = location.pathname.indexOf(to)
    return(
        <span className={!match ? 'nav-link-glow' : ''}>
            <Link to={to} className={!isActive ? 'disable-parent-drop-down_link': ''}>
                {children}
            </Link>
        </span>
    )
}

export default NavBarLink