import { Children } from 'react'
import useInitialLoad from '../../../hooks/useInitialLoad'
import classNames from 'classnames'

const TierTableRow = ({ row, handleIfLink, children }) => {
	const url = `/customers?carrier_services__${row.terminus}_rate_card=${row.id}&field=id&field=name`
	const [customerList, _] = useInitialLoad(url)

	return !customerList ? null : (
		<tr
			onClick={handleIfLink}
			className={classNames({ highlight: true, hasCustomers: customerList.length < 1 })}
		>
			{children}
		</tr>
	)
}

export default TierTableRow
