import React, { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import TableHeader from './TableHeader'
import UnprocessedTableRows from './UnproccesdTableRows'

const UnBilledHeaders = {
	ship_date: 'Ship Data',
	original_delivery_confirmation_number: 'Original delivery confirmation number',
	customer__name: 'Customer Name'
}

const UnBilled = () => {
	const [responseMessage, setResponseMessage] = useState([])
	const [currentOffset, setOffset] = useState(0)
	const [pageLimit, setPageLimit] = useState(100)
	const [tableData, setTableData, setReload, loading] = useInitialLoad(
		`/carriers/dhl/invoicelines?billable_balance__ne=0&field=original_delivery_confirmation_number&field=ship_date&field=customer__name&limit=${pageLimit}&offset=${currentOffset}`
	)

	const fetchNextPage = (offset) => {
		if (offset >= 0) {
			setOffset(offset)
			setReload((prev) => prev + 1)
		}
	}

	return !tableData ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : !tableData.length > 0 ? (
		'No Data'
	) : (
		<>
			<div className="pagination-effects">
				<button
					className="paginate-button"
					onClick={() => fetchNextPage(currentOffset - pageLimit)}
					disabled={currentOffset == 0}
				>
					Prev
				</button>
				<div className="paginate-page-number">
					Page <b>{currentOffset / pageLimit + 1}</b>
				</div>
				<button
					className="paginate-button"
					onClick={() => fetchNextPage(currentOffset + pageLimit)}
					disabled={tableData.length < pageLimit}
				>
					Next
				</button>
			</div>
			<div className="unprocessed-shipments-table error-table">
				<table>
					<TableHeader
						headers={Object.keys(UnBilledHeaders)}
						headerObject={UnBilledHeaders}
					/>
					<UnprocessedTableRows
						tableData={tableData}
						tableHeaders={Object.keys(UnBilledHeaders)}
					/>
				</table>
			</div>
		</>
	)
}

export default UnBilled
