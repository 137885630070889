import React, { useState } from 'react'
import { useFormik } from 'formik'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import _ from 'lodash'
import OsmFileUpload from './OsmFileUpload'
import { useHistory } from 'react-router-dom'

const NewInvoice = ({ setInvoices }) => {
	const { authFetch } = FetchWithAuth()
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState()
	const [success, setSuccess] = useState(false)
	const formik = useFormik({
		initialValues: {
			date: '',
			invoice_total: '',
			invoice_number: '',
			shipments_file: '',
			invoice_file: '',
		},
		onSubmit: async (values) => {
			const formData = new FormData()
			formData.set('shipments_file', formik.values.shipments_file)
			formData.set('invoice_file', formik.values.invoice_file)
			formData.set('date', formik.values.date)
			formData.set('invoice_total', formik.values.invoice_total)
			formData.set('invoice_number', formik.values.invoice_number)
			const url = `${BASEURL}/osm/invoices`
			console.log(formData)
			const response = await authFetch(url, {
				m: 'POST',
				b: formData,
			})
			const data = await response.json()
			if (response.status !== 400 && !response.ok) {
				setErrors(response.statusText)
			}

			if (response.status === 400) {
				const errorMessage = Object.values(data)[0][0]
				setErrors(errorMessage)
			}
			if (response.ok) {
				setSuccess(true)
				setInvoices((prevState) => {
					const newState = _.cloneDeep(prevState)
					newState.push(data)
					return newState
				})
				history.push(`/carriers/osm/invoices/${data.id}`)
			}
		},
	})

	return (
		<form onSubmit={formik.handleSubmit} style={{ marginTop: '4%' }}>
			<div class="side-by-side-input-fields osm-side-by-side">
				<div class="right-input-fields">
					<label htmlFor="invoice_number">Invoice Number</label>
					<input
						className={
							!formik.errors.invoice_number
								? 'valid-input feedback-input'
								: 'feedback-input'
						}
						id="invoice_number"
						name="invoice_number"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invoice_number}
					/>
				</div>
				<div class="left-input-fields">
					<label htmlFor="terminus">Invoice Total</label>
					<input
						className={
							!formik.errors.invoice_total
								? 'valid-input feedback-input'
								: 'feedback-input'
						}
						id="invoice_total"
						name="invoice_total"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invoice_total}
					/>
				</div>
			</div>
			<div className="centerd-inputs">
				<div>Upload Invoice Lines File</div>
				<OsmFileUpload
					setErrors={setErrors}
					setSuccess={setSuccess}
					formik={formik}
					stateName={'shipments_file'}
					allowedFileTypes={'.csv,.xlsx'}
				/>
				<div>Upload Invoice PDF</div>
				<OsmFileUpload
					setErrors={setErrors}
					setSuccess={setSuccess}
					formik={formik}
					stateName={'invoice_file'}
					allowedFileTypes={'.pdf'}
				/>
				<div>
					<label htmlFor="date">date</label>
					<input
						className={
							!formik.errors.date ? 'valid-input feedback-input' : 'feedback-input'
						}
						id="date"
						name="date"
						type="date"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.date}
					/>
				</div>
			</div>

			<div className="centered-button">
				<button disabled={formik.values.invoice_number === ''} type="submit">
					{loading ? (
						<i className="fas fa-spinner check-icon loading-icon"></i>
					) : errors ? (
						<span className="error-text">{errors}</span>
					) : success ? (
						<span className="success-text">Success</span>
					) : (
						'Upload'
					)}
				</button>
			</div>
		</form>
	)
}

export default NewInvoice
