import React, { useState, useRef } from 'react'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'

const OsmFileUpload = ({
	setErrors,
	setSuccess,
	formik,
	stateName,
	allowedFileTypes,
}) => {
	const fileInputRef = useRef(null)

	const removeFile = () => {
		setErrors('')
		setSuccess(false)
		fileInputRef.current.value = ''
		formik.setFieldValue([stateName], '')
	}

	const handleFileChange = (e) => {
		const file = e.target.files

		formik.setFieldValue([stateName], file[0])
		setErrors('')
	}

	return (
		<>
			<div className="file-upload-warper osm-file-upload-warper">
				<div
					className={'file-upload osm-file-upload'}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<div className="upload-file osm-upload-file">
						<label htmlFor={stateName} className="custom-file-upload">
							{'Update'}
						</label>
						<input
							name={stateName}
							id={stateName}
							type="file"
							accept={allowedFileTypes}
							ref={fileInputRef}
							onChange={(e) => handleFileChange(e)}
						/>
						<input id="file-upload" type="file"></input>

						<label className="file-name">
							{formik.values[stateName].name
								? formik.values[stateName].name
								: 'File Name'}
						</label>
						<label className="custom-file-upload" onClick={removeFile}>
							Cancel
						</label>
					</div>
				</div>
			</div>
		</>
	)
}

export default OsmFileUpload
