import { useState, useEffect } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import _ from 'lodash'
import { BASEURL } from '../../../../global-resources'

const InvoicesToSendToQuickbooksList = ({
	invoices,
	access_token,
	setResponseMessage,
}) => {
	const { authFetch } = FetchWithAuth()
	const [invoices_to_create, set_invoices_to_create] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const idsArray = invoices.map((invoice) => {
			return invoice.customer_invoice_id
		})
		set_invoices_to_create(idsArray)
	}, [invoices])

	async function handleFetch() {
		setLoading(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/create_quickbooks_dhl_invoices`,
			{
				m: 'POST',
				h: { 'Content-Type': 'application/json' },
				b: JSON.stringify({ access_token, invoices_to_create }),
			}
		)

		if (response.ok) {
			const jsonResponse = await response.json()
			if (jsonResponse.fault == undefined) {
				if (jsonResponse.length === 0) {
					setResponseMessage(['no invoices to create'])
				} else {
					if (jsonResponse.includes('AuthenticationFailed')) {
						setResponseMessage([
							' AuthenticationFailed Token was incorrect or has expired',
						])
					} else {
						setResponseMessage(['Successfully Create ', jsonResponse.length, ' Invoices'])
					}
				}
			} else {
				setResponseMessage(['Quckbooks ', jsonResponse.fault.type, ' Error'])
			}
		} else {
			setResponseMessage([response.statusText])
		}
		setLoading(false)
		// console.log(response)
	}

	function handleClick(e) {
		// IF THE CHECKBOX IS CHECKED THE RETURN VALUE IS FALSE AND IF IT IS NOT CHECKED IT WILL RETURN TRUE
		const targetID = parseInt(e.target.id)
		if (!e.target.checked) {
			set_invoices_to_create((prevState) => {
				let newState = _.cloneDeep(prevState)
				newState.splice(newState.indexOf(targetID), 1)
				return newState
			})
		} else {
			set_invoices_to_create((prevState) => {
				let newState = _.cloneDeep(prevState)
				newState.push(targetID)
				return newState
			})
		}
	}

	function renderListFrag() {
		// REMOVE THE SLICE WHEN WE ARE GETTING THE CORRECT DATA
		return invoices.map((invoice) => {
			return (
				<div className="invoice_card_q">
					<input
						id={invoice.customer_invoice_id}
						type="checkbox"
						checked={invoices_to_create.includes(invoice.customer_invoice_id)}
						onClick={(e) => handleClick(e)}
					></input>
					<div>{invoice.account_name}</div>
					<div>${invoice.total_charge}</div>
				</div>
			)
		})
	}

	return (
		<div className="">
			<button disabled={access_token.length != 991} onClick={handleFetch}>
				{loading ? (
					<i class="fas fa-spinner check-icon loading-icon"></i>
				) : (
					'Create Invoices'
				)}
			</button>

			<div className="invoice_list_q">{renderListFrag()}</div>
		</div>
	)
}

export default InvoicesToSendToQuickbooksList
