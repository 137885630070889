import { createContext, useContext, useState, useEffect } from 'react'
import { debounce } from '../Functions/debounce'
const HeaderContext = createContext()
export default HeaderContext

export const HeaderContextProvider = ({ children }) => {
	const [prevScrollPos, setPrevScrollPos] = useState(0)
	const [visible, setVisible] = useState(true)
	const handleScroll = debounce(() => {
		const currentScrollPos = window.pageYOffset

		setVisible(
			(prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) ||
				currentScrollPos < 10
		)

		setPrevScrollPos(currentScrollPos)
	}, 110)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [prevScrollPos, visible, handleScroll])
	let value = { prevScrollPos, setPrevScrollPos, visible, setVisible }

	return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}
