import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import EditTier from './EditTier'
import EditCarrierService from './EditCarrierService'
import PropTypes from 'prop-types'
import { number } from 'prop-types'

function CustomerCarrierCards({ carrierServices, setCarrierServices, customer_id }) {
	return carrierServices.map((carrier, i) => (
		<div className="carrier-card" key={i}>
			<div className="carrier-card-header">
				<div>{carrier.carrier_name}</div>
			</div>
			<div className="carrier-card-link-list">
				<div className="carrier-card_link-headers">
					<div className="carrier-card-links-width">rate card</div>
					<div className="carrier-card-links-width">tier</div>
					<div className="carrier-card-links-width">action</div>
				</div>
				<div className="carrier-card_links">
					<div className="carrier-card_link_terminus carrier-card-links-width">
						International
					</div>
					{carrier.international_rate_card_tier ? (
						<>
							<Link
								className="carrier-card-links-width pop-out-link"
								to={`/ratecards/${carrier.international_rate_card}`}
							>
								{carrier.international_rate_card_tier}
							</Link>
							<Link
								to={`/customers/${customer_id}/carrier_services/${carrier.id}/ratecard/international/switch`}
								className="carrier-card-links-width  pop-out-link"
							>
								Switch
							</Link>
						</>
					) : (
						<Link
							to={`/customers/${customer_id}/carrier_services/${carrier.id}/ratecard/international/add`}
							className="carrier-card-links-width  pop-out-link"
						>
							Add
						</Link>
					)}
				</div>
				<div className="carrier-card_links">
					<div className="carrier-card_link_terminus carrier-card-links-width">
						Domestic
					</div>
					{carrier.domestic_rate_card_tier ? (
						<>
							<Link
								className="carrier-card-links-width pop-out-link"
								to={`/ratecards/${carrier.domestic_rate_card}`}
							>
								{carrier.domestic_rate_card_tier}
							</Link>
							<Link
								to={`/customers/${customer_id}/carrier_services/${carrier.id}/ratecard/domestic/switch`}
								className="carrier-card-links-width pop-out-link"
							>
								Switch
							</Link>
						</>
					) : (
						<Link
							to={`/customers/${customer_id}/carrier_services/${carrier.id}/ratecard/domestic/add`}
							className="carrier-card-links-width pop-out-link"
						>
							Add
						</Link>
					)}
				</div>
			</div>
			<div className="carrier-card_info-edit">
				<div className="carier-card_info carrier-card-links-width">
					<div className="card-info-title">{carrier.shipper_id_name}</div>
					<div className="card-info-value">{carrier.shipper_id_value}</div>
				</div>
				<Link
					to={`/customers/${customer_id}/customer-carriers/${carrier.id}/edit`}
					className="carrier-card-edit carrier-card-links-width pop-out-link"
				>
					<i className="fas fa-pencil-alt"></i>
					<span>Edit</span>
				</Link>
				<div className="carier-card_info carrier-card-links-width">
					<div className="card-info-title">Mailer Id</div>
					<div className="card-info-value">{carrier.mailer_id}</div>
				</div>
			</div>
			<Switch>
				<Route
					path={`/customers/${customer_id}/carrier_services/${carrier.id}/ratecard/:terminus/:method`}
				>
					<EditTier
						carrierId={carrier.carrier}
						carrierName={carrier.carrier_name}
						carrierServiceId={carrier.id}
						setCarrierServices={setCarrierServices}
					/>
				</Route>

				<Route path={`/customers/${customer_id}/customer-carriers/${carrier.id}/edit`}>
					<EditCarrierService
						carrierServiceId={carrier.id}
						carrier={carrier}
						setCarrierServices={setCarrierServices}
					/>
				</Route>
			</Switch>
		</div>
	))
}

CustomerCarrierCards.propTypes = {
	carrierServices: PropTypes.array,
	customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default CustomerCarrierCards
