import useOffsetDimensions from '../../../../hooks/useOffsetDimensions'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import DhlDisputesList from './DhlDisputesList'

const ShipmentLookUpLeftSide = ({
	shipmentsSearchResults,
	setShipmentsSearchResults,
	setResponseMessage,
}) => {
	const { ref, divWidth } = useOffsetDimensions()
	const { authFetch } = FetchWithAuth()
	const [searchInput, setSearchInput] = useState('')
	const [searchBy, setSearchBy] = useState('customer_confirmation_number')
	const [loading, setLoading] = useState(false)

	const handleChange = (e) => {
		setSearchInput(e.target.value)
	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/invoicelines/search?tracking_number=${searchInput}`
		)

		setLoading(false)

		if (!response.ok) {
			setResponseMessage(response.statusText)
		}
		const json_response = await response.json()

		if (!json_response.length > 0) {
			setResponseMessage('No Shipments found')
		} else {
			setShipmentsSearchResults(json_response)
		}
	}

	return (
		<div className="shipments-list" ref={ref}>
			<div className="shipments-list-header" style={{ minWidth: divWidth }}>
				Search For A Shipment
			</div>
			<div className="shipment-list-container">
				<div className="search-container">
					<form className="searchForm" role="search">
						<label className="searchFormLabel" for="search">
							Search for stuff
						</label>
						<input
							className="searchFormInput"
							id="search"
							type="search"
							placeholder="Search..."
							value={searchInput}
							onChange={handleChange}
						/>
						<button className="searchFormButton" onClick={handleSubmit}>
							{loading ? <i class="fas fa-spinner check-icon loading-icon"></i> : 'Go'}
						</button>
					</form>
				</div>
			</div>
			<div className="shipments-disputes">disputes</div>
			<DhlDisputesList shipmentsSearchResults={shipmentsSearchResults} />
		</div>
	)
}

export default ShipmentLookUpLeftSide
