import React from "react";
import { Switch, Route } from 'react-router-dom'
import FileQuoter from "./components/FileQuoter";
import './quoter.css'

function QuoterRouts() {
    return(
        <>
            <Switch>
                <Route path='/quoter/file-quote'><FileQuoter/></Route>
            </Switch>
        </>
    )
}

export default QuoterRouts