import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import DhlShipmentsErrorsList from './DhlShipmentsErrorsList'
import DhlShipmentErrorDetail from './DhlShipmentErrorDetail'
import MessageBanner from '../../../../components/MessageBanner'

const DhlShipmentErrorsPage = () => {
	const [shipmentsData, setShipmentsData, setReload, loading, onLine] = useInitialLoad(
		`/carriers/dhl/invoicelines?has_flags=true&processed=false` // must be processed=false
	)

	const [responseMessage, setResponseMessage] = useState([])

	if (shipmentsData) {
		if (shipmentsData.length < 1 && loading == false)
			return (
				<div className="error-table_submit-button">
					<h1 style={{ marginTop: '5%' }}>Sorry No Data 😭</h1>
				</div>
			)
	}
	return loading ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : !shipmentsData ? null : (
		<>
			<div className="shipment-error-page_dhl">
				<DhlShipmentsErrorsList
					shipmentData={shipmentsData}
					setShipmentsData={setShipmentsData}
					setResponseMessage={setResponseMessage}
				/>

				<div className="shipment-data">
					<DhlShipmentErrorDetail
						shipmentData={shipmentsData}
						setShipmentsData={setShipmentsData}
					/>
				</div>
			</div>
			{responseMessage.length < 1 ? null : (
				<MessageBanner
					message={responseMessage}
					setResponseMessage={setResponseMessage}
				/>
			)}
		</>
	)
}

export default DhlShipmentErrorsPage
