import { useContext } from 'react'
import { useAuth2 } from '../context/auth-context'
import NavBarLink from './NavBarLink'
import HeaderContext from '../context/HeaderVisibleContext'
const NavComponent = () => {
	const { logout } = useAuth2()
	const { visible } = useContext(HeaderContext)
	const styles = {
		active: {
			visibility: 'visible',
			top: '0'
		},
		hidden: {
			visibility: 'hidden',
			top: '0',
			transform: 'translateY(-100%)'
		}
	}
	//style={{ top: visible ? '0' : '-90px' }}
	return (
		<div id="navbar" className="header" style={visible ? styles.active : styles.hidden}>
			<nav className="nav-bar">
				<a href="/" className="header-logo">
					<img src="/allin1logo.png" />
				</a>
				<ul className="header-nav">
					<li>
						<NavBarLink to="/customers">Customers</NavBarLink>
					</li>
					<li>
						<NavBarLink to="/pricing" isActive={false}>
							Pricing
						</NavBarLink>
						<ul>
							<li>
								<NavBarLink to="/pricing/tiers">Tier List</NavBarLink>
							</li>
							{/* <li>
								<NavBarLink to="/pricing/somthingEls">coming!</NavBarLink>
							</li> */}
						</ul>
					</li>
					<li>
						<NavBarLink to="/carriers" isActive={false}>
							Carriers
						</NavBarLink>
						<ul>
							<li>
								<NavBarLink to="/carriers/dhl">DHL</NavBarLink>
							</li>
							<li>
								<NavBarLink to="/carriers/osm">OSM</NavBarLink>
							</li>
						</ul>
					</li>
					<li>
						<NavBarLink to="/zenventory">Zenventory</NavBarLink>
					</li>
					<li>
						<NavBarLink to="/quoter" isActive={false}>
							Quoter
						</NavBarLink>
						<ul>
							<li>
								<NavBarLink to="/quoter/file-quote">File Quote</NavBarLink>
							</li>
						</ul>
					</li>
					{/* <li>
						<NavBarLink to="/customer-invoice">Customer Invoices</NavBarLink>
					</li> */}
					<li onClick={() => logout()}>logout</li>
				</ul>
			</nav>
		</div>
	)
}

export default NavComponent
