import { useContext } from 'react'
import { Link } from 'react-router-dom'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import HeaderContext from '../../../../context/HeaderVisibleContext'
import '../style/errorTable.css'
const DhlMenuLinks = () => {
	// const [shipmentsData, setShipmentsData] = useInitialLoad(
	// 	'/carriers/dhl/invoicelines/process'
	// )
	// console.log(shipmentsData)
	const { prevScrollPos, setPrevScrollPos, visible, setVisible } =
		useContext(HeaderContext)
	//carrier-menu-buttons

	//shipmentsData
	return !true ? null : (
		<div className="dhl-menu-bar_container" style={{ top: visible ? '8vh' : '-2px' }}>
			<ul className="dhl-menu-bar">
				<li>
					<Link className=" " to={`/carriers/dhl/shipping_services`}>
						Shipping Services
					</Link>
				</li>
				<li>
					<Link className="" to={`/carriers/dhl/invoice_list`}>
						Invoices
					</Link>
				</li>
				<li>
					<Link className=" button-badge" to={`/carriers/dhl/unprocessed_shipments_list`}>
						Unprocessed Shipments
						{/* <span
							className="button-badge-data"
							style={{
								marginLeft:
									shipmentsData.unprocessed_shipments.toString().length > 3
										? '-3%'
										: '-1%',
							}}
						>
							{shipmentsData.unprocessed_shipments.toLocaleString()}
						</span> */}
					</Link>
				</li>
				<li>
					<Link className="" to={`/carriers/dhl/disputes`}>
						Disputes
					</Link>
				</li>
				<li>
					<Link className="" to={`/carriers/dhl/create_customer_invoices`}>
						Create Invoices
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default DhlMenuLinks
