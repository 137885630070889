import { useLayoutEffect, useEffect, useRef, useState } from 'react'

const useOffsetDimensions = () => {
	const ref = useRef(null)
	const [divWidth, setDivWidth] = useState('')
	const [divHeight, setDivHeight] = useState('')

	const handleResize = () => {
		setDivWidth(ref.current.offsetWidth)
		setDivHeight(ref.current.offsetHeight)
	}
	useEffect(() => {
		if (ref.current) window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [ref])

	useLayoutEffect(() => {
		setDivWidth(ref.current.offsetWidth)
		setDivHeight(ref.current.offsetHeight)
	}, [])

	return { ref, divWidth, divHeight }
}

export default useOffsetDimensions
