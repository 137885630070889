import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useAuthFetch } from '../../../../hooks/useAuthFetch'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import { BASEURL } from '../../../../global-resources'
import ProcessCard from './ProcessCard'
// THERE IS A LOOT OF UNUSED CODE HERE THAT CAN BE DELETED AROUND 11/01/222
import ShowErrorsButton from './ShowErrors.Button'
import ProcessCardWithErrorToggle from './ProcessCardWithErrorToggle'
import ErrorsAccordionContent from './ErrorsAccordionContent'
import MissingTierAccordion from './MissingTiersAccordion'
import MissingTermsAccordion from './MissingTermsAccordion'
import MissingRateCardAccordion from './MisssingRateCardAccordion'
import MissingZoneAccordion from './MissingZoneAccordion'
import MissingWeightAccordion from './MissingWeightWeight'
import MissingPriceAccordion from './MissingPriceAccordion'
import MissingTiersAccordionGetOurCalculatedCost from './MissingTiersAccordionGetOurCalculatedCost'
import ProcessCardWithErrorTableLink from './ProcessCardWithErrorTableLink'
const InvoiceProcessButtons = () => {
	const { id } = useParams()

	/// url's for get_customer_package_cost
	const get_customer_package_cost_tiers_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=tier_id',
		'&field=customer_id',
		'&field=customer__name',
		'&field=terminus',
		'&count=id',
	].join('')

	const get_customer_package_cost_terms_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=tier_term_id',
		'&field=tier_id',
		'&field=tier__tier',
		'&min=ship_date',
		'&max=ship_date',
		'&count=id',
	].join('')

	const get_customer_package_cost_rate_card_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=rate_card_id',
		'&field=tier_id',
		'&field=tier__tier',
		'&field=tier_term__start_date',
		'&field=shipping_service__name',
		'&count=id',
	].join('')

	const get_customer_package_cost_zone_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=customer_rate_card_zone',
		'&field=rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=verified_zone',
		'&count=id',
	].join('')
	const get_customer_package_cost_weight_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=customer_rate_card_bill_weight',
		'&field=rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=final_weight',
		'&count=id',
	].join('')
	const get_customer_package_cost_price_missingTiersUrl = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&customer_package_cost_flags=customer_package_cost',
		'&field=rate_card_id',
		'&field=shipping_service__name',
		'&field=final_weight',
		'&field=verified_zone',
		'&count=id',
	].join('')

	// URL'S FOR get_our_calculated_cost
	const get_our_calculated_cost_tiers_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=dhl_tier_id',
		'&field=terminus',
		'&count=invoice_id',
	].join('')
	const get_our_calculated_cost_terms_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=dhl_term_id',
		'&field=tier_id',
		'&field=tier__tier',
		'&max=ship_date',
		'&min=ship_date',
		'&count=invoice_id',
	].join('')
	const get_our_calculated_cost_rate_card_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=dhl_rate_card_id',
		'&field=tier_id',
		'&field=tier__tier',
		'&field=dhl_term__start_date',
		'&field=shipping_service__name',
		'&count=invoice_id',
	].join('')

	const get_our_calculated_cost_zone_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=dhl_rate_card_zone',
		'&field=dhl_rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=verified_zone',
		'&count=invoice_id',
	].join('')

	const get_our_calculated_cost_weight_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=dhl_rate_card_bill_weight',
		'&field=dhl_rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=final_weight',
		'&count=invoice_id',
	].join('')

	const get_our_calculated_cost_price_url = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&our_package_cost_flags=calculated_our_cost',
		'&field=dhl_rate_card_id',
		'&field=shipping_service__name',
		'&field=final_weight',
		'&field=verified_zone',
		'&count=invoice_id',
	].join('')

	//URL'S get_comparison_package_cost
	const get_comparison_package_cost_tier = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_tier_id',
		'&field=terminus',
		'&count=invoice_id',
	].join('')

	const get_comparison_package_cost_terms = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_term_id',
		'&field=comp_tier_id',
		'&field=comp_tier__tier',
		'&max=ship_date',
		'&min=ship_date',
		'&count=invoice_id',
	].join('')

	const get_comparison_package_cost_rate_card = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_rate_card_id',
		'&field=comp_tier_id',
		'&field=comp_tier__tier',
		'&field=comp_term__start_date',
		'&field=shipping_service__name',
		'&count=invoice_id',
	].join('')

	const get_comparison_package_cost_zone = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_rate_card_zone',
		'&field=comp_rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=verified_zone',
		'&count=invoice_id',
	].join('')
	const get_comparison_package_cost_weight = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_rate_card_bill_weight',
		'&field=comp_rate_card_id',
		'&field=shipping_service__name',
		'&groupconcat=final_weight',
		'&count=invoice_id',
	].join('')
	const get_comparison_package_cost_price = [
		'/carriers/dhl/invoicelines',
		`?invoice_id=${id}`,
		'&comp_package_cost_flags=comp_package_cost',
		'&field=comp_rate_card_id',
		'&field=shipping_service__name',
		'&field=final_weight',
		'&field=verified_zone',
		'&count=invoice_id',
	].join('')

	const { authFetch } = useAuthFetch()
	const url = `/api/dhl/invoices/${id}/process`
	const [processInfo, setProcessInfo] = useInitialLoad(url)

	const handleClick = async (e) => {
		const { name } = e.target
		const response = await authFetch(BASEURL + url, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ method: name }),
		})
		setProcessInfo({ ...processInfo, ...response })
	}

	return !processInfo ? (
		<h3>Loading</h3>
	) : (
		<div class="invoice-actions">
			<ProcessCard
				methodName={'load_lines'}
				processedCount={processInfo.load_lines}
				processableCount={processInfo.load_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
				includeCheckbox={false}
			/>
			{/* <ProcessCard
				methodName={'get_line_type'}
				processedCount={processInfo.get_line_type}
				processableCount={processInfo.load_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_terminus'}
				processedCount={processInfo.get_terminus}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'check_for_returns'}
				processedCount={processInfo.check_for_returns}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_formatted_zip_code'}
				processedCount={processInfo.get_formatted_zip_code}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_service_provider'}
				processedCount={processInfo.get_service_provider}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'verify_zone'}
				processedCount={processInfo.verify_zone}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_zone_description'}
				processedCount={processInfo.get_zone_description}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_original_delivery_confirmation_number'}
				processedCount={processInfo.get_original_delivery_confirmation_number}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_pickup_number'}
				processedCount={processInfo.get_pickup_number}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'check_pickup_number'}
				processedCount={processInfo.check_pickup_number}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'override_pickup_number'}
				processedCount={processInfo.override_pickup_number}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_customer'}
				processedCount={processInfo.get_customer}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
			>
				{processInfo.get_customer > 0 ? (
					<ShowErrorsButton methodName={'get_customer'} />
				) : null}
			</ProcessCard>
			<ProcessCard
				methodName={'get_final_dimensions'}
				processedCount={processInfo.get_final_dimensions}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_final_weight'}
				processedCount={processInfo.get_final_weight}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'convert_international_weights'}
				processedCount={processInfo.convert_international_weights}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/>
			<ProcessCard
				methodName={'get_shipping_service'}
				processedCount={processInfo.get_shipping_service}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			/> */}

			{/* <ProcessCardWithErrorToggle
				methodName={'get_customer_package_cost'}
				processedCount={processInfo.get_customer_package_cost}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			>
				<ErrorsAccordionContent>
					<MissingTierAccordion
						missing_url={get_customer_package_cost_tiers_missingTiersUrl}
					/>
					<MissingTermsAccordion
						missing_url={get_customer_package_cost_terms_missingTiersUrl}
						tierIdName={'tier_id'}
						tierName={'tier__tier'}
					/>
					<MissingRateCardAccordion
						missing_url={get_customer_package_cost_rate_card_missingTiersUrl}
						termDateName={'tier_term__start_date'}
						tierIdName={'tier_id'}
						tierName={'tier__tier'}
					/>
					<MissingZoneAccordion
						missing_url={get_customer_package_cost_zone_missingTiersUrl}
						rateCardIdName={'rate_card_id'}
					/>
					<MissingWeightAccordion
						missing_url={get_customer_package_cost_weight_missingTiersUrl}
						rateCardName={'rate_card_id'}
					/>
					<MissingPriceAccordion
						missing_url={get_customer_package_cost_price_missingTiersUrl}
					/>
				</ErrorsAccordionContent>
			</ProcessCardWithErrorToggle>

			<ProcessCardWithErrorToggle
				methodName={'get_comparison_package_cost'}
				processedCount={processInfo.get_comparison_package_cost}
				processableCount={processInfo.processable_lines}
				setProcessInfo={setProcessInfo}
				handleClick={handleClick}
			>
				<ErrorsAccordionContent>
					<MissingTiersAccordionGetOurCalculatedCost
						missing_url={get_comparison_package_cost_tier}
					/>
					<MissingTermsAccordion
						missing_url={get_comparison_package_cost_terms}
						tierIdName={'comp_tier_id'}
						tierName={'comp_tier__tier'}
					/>
					<MissingRateCardAccordion
						missing_url={get_comparison_package_cost_rate_card}
						termDateName={'comp_term__start_date'}
						tierIdName={'comp_tier_id'}
						tierName={'comp_tier__tier'}
					/>
					<MissingZoneAccordion
						missing_url={get_comparison_package_cost_zone}
						rateCardIdName={'comp_rate_card_id'}
					/>
					<MissingWeightAccordion
						missing_url={get_comparison_package_cost_weight}
						rateCardName={'comp_rate_card_id'}
					/>
					<MissingPriceAccordion missing_url={get_comparison_package_cost_price} />
				</ErrorsAccordionContent>
			</ProcessCardWithErrorToggle> */}
		</div>
	)
}

export default InvoiceProcessButtons
