import React, { useState } from 'react'
import PopUpPage from '../../../components/PopUpPage'
import useInitialLoad from '../../../hooks/useInitialLoad'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import AddCarrierServiceForm from './AddCarrierServiceForm'

function AddCarrierService({ customer_id, setCarrierServices }) {
	const { authFetch } = FetchWithAuth()
	const [loading, setLoading] = useState(false)
	const [carriers, setCarriers] = useInitialLoad(
		`/carriers?exclude_customer=${customer_id}`
	)

	async function handleSubmit(formData) {
		const data = {
			carrier: formData.carrierID,
			customer: customer_id,
			mailer_id: formData.mailerID,
			shipper_id_value: formData.shipperIdValue,
			active_flag: formData.is_active,
		}

		setLoading(true)
		const response = await authFetch(`${BASEURL}/pricing/customer-carriers`, {
			m: 'POST',
			b: JSON.stringify(data),
			h: { 'Content-Type': 'application/json' },
		})
		setLoading(false)
		const responseData = await response.json()
		console.log(responseData)
		if (response.ok) {
			setCarrierServices((initialState) => {
				initialState.push(responseData)
				return initialState
			})
		}
	}

	return (
		<PopUpPage>
			<div className="add-carrier">
				<h1>Add Carrier</h1>
				{!carriers ? (
					<i id="table-loader" class="fas fa-spinner check-icon loading-icon large"></i>
				) : (
					<AddCarrierServiceForm carriers={carriers} handleSubmit={handleSubmit} />
				)}
			</div>
		</PopUpPage>
	)
}

export default AddCarrierService
