import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import PopUpPage from '../../../../components/PopUpPage'
import { BASEURL } from '../../../../global-resources'
import _ from 'lodash'
function EditShippingService({ servicesList, setServicesList }) {
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	const [Loading, setLoading] = useState(false)
	const serviceIndex = servicesList.findIndex((service) => {
		return service.id === parseInt(id)
	})

	const { code, name, terminus, min_weight_oz, max_weight_oz, weights } =
		servicesList[serviceIndex]

	const validate = (values) => {
		const errors = {}
		if (!values.max_weight_oz) {
			return
		} else if (values.max_weight_oz.toString().length > 15) {
			errors.max_weight_oz = 'Oops max weight exceeded 15 digits'
		}
		return errors
	}
	const formik = useFormik({
		initialValues: {
			name: name,
			code: code,
			terminus: terminus,
			min_weight_oz: min_weight_oz,
			max_weight_oz: max_weight_oz,
			weights: weights ? WeightsFormatToBeDisplayed() : ''
		},
		validate,
		onSubmit: async (values) => {
			setLoading(true)
			const url = `${BASEURL}/pricing/shippingservices/${id}`

			const newValues = _.cloneDeep(values)
			newValues['weights'] = formatWeightsToSubmit(newValues.weights)
			const response = await authFetch(url, {
				m: 'PATCH',
				b: JSON.stringify(newValues),
				h: { 'Content-Type': 'application/json' }
			})
			console.log(response)
			if (response.ok) {
				const result = await response.json()
				setServicesList((prevState) => {
					const newState = [...prevState]
					newState[serviceIndex] = result
					return newState
				})
			}
			setLoading(false)
		}
	})

	function formatWeightsToSubmit(arrayOfWeights) {
		let format1 = arrayOfWeights.replace(/[\n\r]/g, ' ') // remove all line br if there is
		console.log(format1)
		let format2 = format1.split(',')
		let format3 = format2.map((string) => {
			return string.split(' ').filter((n) => n)
		})
		let format4 = format3[0].map((str) => parseInt(str))
		return format4
	}
	// this function was made to do something a bit different however it is kept here since the end result is the same
	function WeightsFormatToBeDisplayed() {
		// formats all string with i there a ',' or a 'br line' in between the digest to a array of digit string
		let makeString = weights.join(',')
		let format1 = makeString.replace(/[\n\r]/g, ' ') // remove all line br if there is
		let format2 = format1.split(',')
		let format3 = format2.map((string) => {
			return string.split(' ').filter((n) => n)
		})
		let format4 = format3.join().split(',')
		return format4.join('\n')
	}

	return (
		<PopUpPage>
			<h1>Edit Shipping Services</h1>
			<form onSubmit={formik.handleSubmit}>
				<div className="centered-input">
					<label htmlFor="name">name</label>
					<input
						className={
							!formik.errors.name ? 'valid-input feedback-input' : 'feedback-input'
						}
						id="name"
						name="name"
						type="text"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.name}
					/>
				</div>
				<div class="side-by-side-input-fields">
					<div class="right-input-fields">
						<label htmlFor="code">Code</label>
						<input
							className={
								!formik.errors.code ? 'valid-input feedback-input' : 'feedback-input'
							}
							id="code"
							name="code"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.code}
						/>

						<label htmlFor="terminus">terminus</label>
						<input
							className={
								!formik.errors.terminus ? 'valid-input feedback-input' : 'feedback-input'
							}
							id="terminus"
							name="terminus"
							type="text"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.terminus}
						/>
					</div>
					<div class="left-input-fields">
						<label htmlFor="min_weight_oz">min weight oz</label>
						<input
							className={
								!formik.errors.min_weight_oz
									? 'valid-input feedback-input'
									: 'feedback-input'
							}
							id="min_weight_oz"
							name="min_weight_oz"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.min_weight_oz}
						/>
						<label htmlFor="max_weight_oz">max weight oz</label>
						<input
							className={
								!formik.errors.max_weight_oz
									? 'valid-input feedback-input'
									: 'feedback-input'
							}
							id="max_weight_oz"
							name="max_weight_oz"
							type="number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.max_weight_oz}
						/>
						{formik.touched.max_weight_oz && formik.errors.max_weight_oz ? (
							<div className="form-error-message">{formik.errors.max_weight_oz}</div>
						) : null}
					</div>
				</div>
				<div className="centered-input">
					<label htmlFor="weights">Weights</label>

					<textarea
						rows={formik.values.weights ? formik.values.weights.length / 2 : 2}
						className={
							!formik.errors.terminus
								? 'valid-input feedback-input text-input-like-input'
								: 'feedback-input text-input-like-input'
						}
						id="weights"
						name="weights"
						type="text"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.weights}
					/>
				</div>
				<div className="centered-button">
					{Loading ? (
						<i class="fas fa-spinner check-icon loading-icon large"></i>
					) : (
						<button type="submit">Submit</button>
					)}
				</div>
			</form>
		</PopUpPage>
	)
}

export default EditShippingService
