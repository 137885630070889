import React from 'react'

const UnprocessedTableRows = ({ tableData, tableHeaders }) => {
	function formatDateString(dateToFormat) {
		const stringWithDelimiters = new Date(
			dateToFormat.replace(/(\d+)(\d{2})(\d{2})/g, '$1/$2/$3')
		).toLocaleDateString('en-us')
		return stringWithDelimiters
	}
	const createTableRow = () => {
		return tableData.map((dataObject, objectIndex) => {
			return (
				<tr key={objectIndex}>
					{tableHeaders.map((key, headerIndex) => (
						<td className="unprocessed-table-cell">
							{/* {key == 'ship_date' ? formatDateString(dataObject[key])} */}
							{dataObject[key]
								? key == 'ship_date'
									? formatDateString(dataObject[key])
									: dataObject[key]
								: 'No Data'}
						</td>
					))}
				</tr>
			)
		})
	}
	return <tbody>{createTableRow()}</tbody>
}

export default UnprocessedTableRows
