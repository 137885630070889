//https://evandromacedo.github.io/react-simple-snackbar/
export const options1 = {
	position: 'bottom-right',
	// style: {
	// 	backgroundColor: 'midnightblue',
	// 	border: '2px solid lightgreen',
	// 	color: 'lightblue',
	// 	fontFamily: 'Menlo, monospace',
	// 	fontSize: '20px',
	// 	textAlign: 'center',
	// },
	// closeStyle: {
	// 	color: 'lightcoral',
	// 	fontSize: '16px',
	// },
}
