import { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import MessageBanner from '../../../../components/MessageBanner'
import { useHistory } from 'react-router-dom'
import { copyTextToClipboard } from '../../../../hooks/copyText'
import { useSnackbar } from 'react-simple-snackbar'
import { options1 } from '../../../../settingFiles/snackbarSettings'
//https://evandromacedo.github.io/react-simple-snackbar/

const DhlDisputesTableCell = ({
	td,
	dataObject,
	tableHeaders,
	columHeader,
	buttonName,
	felid_to_toggle,
}) => {
	const [loading, setLoading] = useState(false)
	const [RemovedFromDisputes, setRemovedFromDisputes] = useState(false)
	const [messageResponse, setMessageResponse] = useState([])
	const { authFetch } = FetchWithAuth()
	const [openSnackbar, closeSnackbar] = useSnackbar(options1)
	let history = useHistory()
	const handleClick = async (e) => {
		e.stopPropagation()
		setLoading(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/disputes/${dataObject.id}`,
			{
				m: 'PATCH',
				h: { 'Content-Type': 'application/json' },
				b: JSON.stringify({ [felid_to_toggle]: !dataObject[felid_to_toggle] }),
			}
		)

		setLoading(false)
		if (response.ok) {
			setMessageResponse(['Successfully Updated Dispute'])
			setRemovedFromDisputes(true)
		} else {
			setMessageResponse([response.statusText])
		}
	}
	const handleNotePage = () => {
		history.push(`/carriers/dhl/disputes/${dataObject.id}/note`)
	}
	function handleCopy() {
		copyTextToClipboard(td)
		openSnackbar(<h2>{`${td}: Copied to Clipboard`}</h2>, [2000])
	}
	return (
		<>
			{messageResponse.length > 0 ? (
				<>
					<MessageBanner
						message={Object.values(messageResponse)}
						setResponseMessage={setMessageResponse}
					></MessageBanner>
				</>
			) : null}
			<td className="error-table-tooltip" onClick={() => handleCopy()}>
				{columHeader != 'notes' ? (
					<input className="dead-input editable-table-input" value={td} type="text" />
				) : (
					<div className={'table-cell-note'} onClick={(e) => handleNotePage()}>
						{td}
					</div>
				)}

				{tableHeaders.indexOf(columHeader) == tableHeaders.length - 1 ? (
					<button
						disabled={RemovedFromDisputes}
						onClick={(e) => handleClick(e)}
						className="error-table-tooltip-button"
					>
						{loading ? (
							<i class="fas fa-spinner check-icon loading-icon"></i>
						) : RemovedFromDisputes ? (
							'X'
						) : (
							buttonName
						)}
					</button>
				) : null}
			</td>
		</>
	)
}

export default DhlDisputesTableCell
