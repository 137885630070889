import React from "react";
import EditableDecimalField from "../../../../components/Invoices/EditableDecimalField";

const InfoSection = ({info, url, toggleProcessed}) => {
    
    return(
     
        <div class="order_info">
            <div style={{marginLeft: '3%', fontWeight:'bold'}}>
                Invoice: <span style={{fontSize: '.7em'}}>{info.inv_no}</span>
            </div>
            <div class="order_info_data">
                ID: <span class='order_info_data_values'>{info.id}</span>
            </div>
            <div class="order_info_data">
                Carrier: <span class='order_info_data_values'>{info.vendor}</span>
            </div>
            <div class="order_info_data">
                Invoice Date: <span class='order_info_data_values'>{info.inv_date}</span>
            </div>
            <div class="order_info_data">
                Has File: <span class='order_info_data_values'>{info.file ? 'Yes' : 'No'}</span>
            </div>
          
            <EditableDecimalField url={url} field={{amount_stated: info.amount_stated}}/>
            <div class="order_info_data edit-enabled">Processed: 
                <span class='order_info_data_values'>{info.processed ? 'Yes' : 'No'}</span> 
                <span onClick={toggleProcessed} class="fas fa-cogs edit-icons"></span> 
            </div>
        </div>
    )
}

export default InfoSection