const validate = (values) => {
	const errors = {}
	if (!values.name) {
		errors.name = 'Required Felid'
	} else if (values.name.length > 100) {
		errors.name = 'Must be 15 characters or less'
	}

	// if (!values.billing_contact_name) {
	//   errors.billing_contact_name = 'Required Felid';
	// } else if (values.billing_contact_name.length > 50) {
	//   errors.billing_contact_name = 'Must be 50 characters or less';
	// }

	//   if (!values.fee) {
	//     errors.fee = 'Required Felid';
	//   } else if (!(parseFloat(values.fee) >= 0 && parseFloat(values.fee) < 0.1)) {
	//     errors.fee = 'Must be a positive number no greater then 0.1';
	//   }

	// if (!values.billing_contact_phone) {
	//   errors.billing_contact_phone = 'Required Felid';
	// } else if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(values.billing_contact_phone)) {
	//   errors.billing_contact_phone = 'Pleas enter a valid phone number';
	// }
	if (values.billing_contact_email) {
		if (
			values.billing_contact_email.length > 0 &&
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.billing_contact_email)
		) {
			errors.billing_contact_email = 'Invalid email address'
		}
	}

	return errors
}

export const formatPhoneNumber = (value) => {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value
	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '')
	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length
	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
	}
	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
		6,
		10
	)}`
}

export default validate
