import * as React from 'react'
import { BASEURL } from '../global-resources'

const AuthContext = React.createContext()

const AuthProvider = (props) => {
	const authToken = () => localStorage.getItem('token')
	const isLoggedIn = () => !!authToken()

	const [loggedIn, setLoggedIn] = React.useState(isLoggedIn())

	const setToken = (token) => {
		if (token) {
			localStorage.setItem('token', token)
		}
	}

	const login = async (credentials) => {
		const response = await fetch(BASEURL + '/api/token', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(credentials),
		})
		switch (response.status) {
			case 401:
				logout()
				break
			case 403:
				logout()
				break
			case 200:
				const { access } = await response.json()
				setToken(access)
				setLoggedIn(!!authToken())
			default:
				break
		}
	}

	const logout = () => {
		localStorage.removeItem('token')
		setLoggedIn(!!authToken())
	}

	return (
		<AuthContext.Provider value={{ login, logout, loggedIn, authToken }} {...props} />
	)
}

const useAuth2 = () => React.useContext(AuthContext)

export { AuthProvider, useAuth2 }
