import React, { useState } from 'react'
import { useParams } from 'react-router'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase.js'
import MessageBanner from '../../../../components/MessageBanner'
function ProcessCard({
	methodName,
	processedCount,
	processableCount,
	setProcessInfo,
	includeCheckbox = true,
	children,
}) {
	const [checked, setChecked] = useState(false)
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	// const url = `/api/dhl/invoices/${id}/process`
	const url = `/carriers/dhl/invoices/${id}/load_lines`
	const [loading, setLoading] = useState(false)
	const [responseMessage, setResponseMessage] = useState([])
	const handleClick = async (e) => {
		const { name } = e.target

		setLoading(true)
		const response = await authFetch(BASEURL + url, {
			m: 'PATCH', // was POST
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ method: name, overwrite: checked }),
		})

		if (response.ok != undefined && !response.ok) {
			setLoading(false)
			setResponseMessage([response.statusText])
		}
		setLoading(false)
		const data = await response.json()
		setProcessInfo((currentState) => {
			return { ...currentState, ...data }
		})
	}

	return (
		<>
			{responseMessage.length > 0 ? (
				<MessageBanner
					type="error"
					message={responseMessage[0]}
					calloutType="Error"
					setResponseMessage={setResponseMessage}
				/>
			) : null}
			<div class="invoice_cards_new">
				{processedCount == processableCount && processedCount != 0 ? (
					<div class="container__ribbon">
						<span>Complete</span>
					</div>
				) : null}

				<button
					class="invoiceGeneratorButtons"
					onClick={handleClick}
					disabled={loading}
					name={methodName}
				>
					{loading ? (
						<i class="fas fa-spinner check-icon loading-icon"></i>
					) : (
						snakeCaseToTitleCase(methodName)
					)}
				</button>

				<span class="processedCount">
					{processedCount}/{processableCount} Processed
				</span>

				{includeCheckbox ? (
					<label class="check-box-container">
						Reprocess
						<input
							name={'get_pickup_number'}
							type="checkbox"
							checked={checked ? 'checked' : null}
							onChange={() => setChecked((checked) => !checked)}
						></input>
						<span class="checkmark"></span>
					</label>
				) : null}
				{children}
			</div>
		</>
	)
}

export default ProcessCard
