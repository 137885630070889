import { useAuth2 } from '../context/auth-context'

export const useAuthFetch = () => {
	const { logout, authToken } = useAuth2()

	const authFetch = async (url, { m = 'GET', h, b } = {}) => {
		const headers = {
			Authorization: `Bearer ${authToken()}`,
			Accept: '*/*',
			...h,
		}
		const response = await fetch(url, {
			method: m,
			headers,
			body: b,
		})
		if (response.status === 401) {
			return logout()
		}
		if (response.status === 403) {
			return logout()
		}

		if (response.headers.get('content-type') === 'application/json') {
			return await response.json()
		}
		return response
	}

	return {
		authFetch,
	}
}
