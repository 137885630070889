import { useState, useEffect } from 'react'
import { useAuthFetch } from '../../../../hooks/useAuthFetch'
import { BASEURL } from '../../../../global-resources'
import { useHistory, useLocation } from 'react-router-dom'

import TierTableRow from '../../../CarrierPricing/components/TierTableRow'

// some constants
const editableColumns = ['pickup_number_override']
const dhlLinkFelid = ['customer_confirmation_number']
const url = '/api/dhl/invoices/lines/'
// Start of component
const TableRow = ({ headers, row, rowNumber, isRowAlink = false, carrier = 'dhl' }) => {
	const [data, setData] = useState(row)
	const [edited, setEdited] = useState({})
	const [loading, setLoading] = useState(false)
	const { authFetch } = useAuthFetch()
	const location = useLocation()
	let history = useHistory()

	function onChange(id, newValue, header) {
		setData({ ...data, [header]: newValue })
		setEdited({ ...edited, [header]: true })
	}

	useEffect(() => {
		setData(row)
	}, [row])

	const submitUpdatedData = async (columName) => {
		setLoading(true)
		const body = { [columName]: data[columName] }
		const response = await authFetch(BASEURL + `${url}${data.id}`, {
			m: 'PATCH',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify(body),
		})
		setData(response)

		setEdited({ ...edited, [columName]: false })
		setLoading(false)
	}

	function handleIfLink() {
		if (location.pathname.indexOf('tiers') != -1) history.push(`/ratecards/${row.id}`)
		if (location.pathname.indexOf('shipping_services') != -1)
			history.push(`/carriers/${carrier}/shipping_services/${row.id}/edit`)
	}

	const rowDataFragment = headers.map((header, columNumber) => {
		return (
			<td
				//    FROM LINE 39 TILL AROUND 64 IS ALL DEALING WITH THE EDITABLE Field
				className={
					editableColumns.includes(headers[columNumber]) ? 'editable-field' : null
				}
			>
				{editableColumns.includes(headers[columNumber]) ? (
					<div class="inputWithIcon">
						{edited[headers[columNumber]] && !loading ? (
							<i
								class="fas fa-check check-icon"
								onClick={() => submitUpdatedData(headers[columNumber])}
							></i>
						) : edited[headers[columNumber]] && loading ? (
							<i class="fas fa-spinner check-icon loading-icon"></i>
						) : null}
						<input
							type="text"
							value={data[headers[columNumber]]}
							id={data.id}
							onChange={(e) =>
								onChange(e.target.id, e.target.value, headers[columNumber])
							}
						/>
					</div>
				) : dhlLinkFelid.includes(headers[columNumber]) ? (
					<a
						href={`https://webtrack.dhlglobalmail.com/?trackingnumber=${
							data[headers[columNumber]]
						}`}
						target="_blank"
					>
						{data[headers[columNumber]]}
					</a>
				) : (
					data[headers[columNumber]]
				)}
			</td>
		)
	})
	return (
		<TierTableRow handleIfLink={handleIfLink} row={row}>
			{rowDataFragment}
		</TierTableRow>
	)
}

export default TableRow
