import CustomerInvoiceCells from './CustomerInvoiceCells'
import classNames from 'classnames'

const CustomerInvoiceRows = ({
	tableData,
	headers,
	setEditedFields,
	setResponseMessage,
	rowButtonName,
	// setReload,
	submitCorrectedCells,
	canHighlightRow,
	setSums,
}) => {
	function sortByPaymentType() {
		return tableData.sort((a, b) => {
			return a.payment_type.charCodeAt(0) - b.payment_type.charCodeAt(0)
		})
	}

	function sortByBillingPeriod() {
		return sortByPaymentType().sort((a, b) => {
			let c = new Date(a.billing_period),
				d = new Date(b.billing_period)
			return d - c
		})
	}

	function createTableRow() {
		return sortByBillingPeriod().map((dataObject, objectIndex) => {
			return (
				<tr
					key={objectIndex}
					className={classNames({
						'highlight-row':
							(canHighlightRow && dataObject.sent == true) ||
							(!canHighlightRow && dataObject.sent == false),
					})}
				>
					{headers.map((key) => (
						<CustomerInvoiceCells
							td={dataObject[key]}
							uniqueKeyProp={`${dataObject.customer_confirmation_number}-${key}`}
							disableEdit_bol={key != 'quickbooks_invoice_number'}
							invoiceId={dataObject.customer_invoice_id}
							columHeader={key}
							headers={headers}
							setEditedFields={setEditedFields}
							dataObject={dataObject}
							setResponseMessage={setResponseMessage}
							rowButtonName={rowButtonName}
							// setReload={setReload}
							submitCorrectedCells={submitCorrectedCells}
							setSums={setSums}
							objectIndex={objectIndex}
						/>
					))}
				</tr>
			)
		})
	}

	return <tbody>{createTableRow()}</tbody>
}

export default CustomerInvoiceRows
