import React, { useState } from 'react'

import { BASEURL } from '../../../global-resources'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
// import RateCardTerm from './components/RateCardTerm'
import RateCardTerm from './RateCardTerm'
import _ from 'lodash'

function CreateEditRateCard({
	rateCards,
	setOldeState,
	handleDateChange,
	handleSubmit,
	handleTierChange,
	setTermToUpdate,
	loading,
	updateTierInputRef,
	termToUpdate,
	startDate,
	setRateCardTerms,
	rateCardTerms,
}) {
	const [tierLoader, setTierLoader] = useState(false)

	const { authFetch } = FetchWithAuth()
	const handleTierSubmit = async (e) => {
		setTierLoader(true)
		const response = await authFetch(`${BASEURL}/pricing/ratecards/${rateCards.id}`, {
			m: 'PATCH',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ tier: rateCards.tier }),
		})
		const data = await response.json()
		setOldeState(rateCards.tier)
		setTimeout(() => {
			setTierLoader(false)
		}, 3000)
	}
	const handleXButton = () => {
		setTermToUpdate({ ...termToUpdate, term_id: '', term_start_date: '' })
	}

	const iconFrag = () => {
		if (document.activeElement !== updateTierInputRef.current && tierLoader === false) {
			return (
				<i
					className="fas fa-pencil-alt update-tier-button"
					onClick={() => updateTierInputRef.current.focus()}
				></i>
			)
		} else {
			return (
				<i
					className={
						tierLoader ? 'far fa-paper-plane paper-plane_animate' : 'far fa-paper-plane'
					}
					onClick={handleTierSubmit}
				></i>
			)
		}
	}
	return (
		<div className="rate-cards">
			<h1 className="rate-card-header" onClick={(e) => e.stopPropagation()}>
				{`${rateCards.carrier_name} ${rateCards.terminus} Rate Card: Tier`}
				<input
					ref={updateTierInputRef}
					className="tier-input_term"
					type="text"
					id="termID"
					value={rateCards.tier}
					onChange={(e) => handleTierChange(e)}
				></input>
				{iconFrag()}
			</h1>

			<div className="add-term">
				<form
					onSubmit={(e) => handleSubmit(e)}
					className={termToUpdate.term_id ? 'create-term-form' : null}
				>
					{termToUpdate.term_id ? (
						<div className="close_update-term">
							<i class="fas fa-times" onClick={handleXButton}></i>
						</div>
					) : null}
					<div>
						<span className="create-term-title">
							{termToUpdate.term_id
								? `Update  ${termToUpdate.term_start_date}`
								: 'Create A Term'}
						</span>
					</div>

					<input
						className="term_date-picker"
						value={startDate}
						type="date"
						id="startDate"
						name="startDate"
						onChange={(e) => handleDateChange(e)}
					/>

					<button
						disabled={!/([0-9]{4})-([0-9]{2})-([0-9]{2})/.test(startDate)}
						className="create-term_button"
					>
						{loading ? (
							<i className="fas fa-spinner check-icon loading-icon"></i>
						) : termToUpdate.term_id ? (
							'Update Term'
						) : (
							'Create Term'
						)}
					</button>
				</form>
			</div>
			<div className="carrier-accordions">
				<RateCardTerm
					rateCardTerms={rateCardTerms}
					setRateCardTerms={setRateCardTerms}
					rateCards={rateCards}
					setTermToUpdate={setTermToUpdate}
				/>
			</div>
		</div>
	)
}

export default CreateEditRateCard
