import React, { useState, useRef } from 'react'
import { useParams, Prompt } from 'react-router-dom'
import useInitialLoad from '../../hooks/useInitialLoad'
import { BASEURL } from '../../global-resources'
import { FetchWithAuth } from '../../hooks/FetchWithAuth'
import RateCardTerm from './components/RateCardTerm'
import _ from 'lodash'
import CreateEditRateCard from './components/CreateEditRateCard'
import CustomersUsingGivenTier from './components/CustomersUsingGivenTier'

const RateCards = () => {
	const { id } = useParams()
	const [rateCards, setRateCards] = useInitialLoad(`/pricing/ratecards/${id}`)
	const [startDate, setStartDate] = useState('')
	const [loading, setLoading] = useState(false)
	const { authFetch } = FetchWithAuth()
	const updateTierInputRef = useRef(null)
	const [oldState, setOldeState] = useState('')
	const [rateCardTerms, setRateCardTerms] = useInitialLoad(
		`/pricing/ratecardterms?tier=${id}`
	)

	const [termToUpdate, setTermToUpdate] = useState({
		term_id: '',
		term_start_date: '',
	})
	const submitTerm = async () => {
		if (termToUpdate.term_id) {
			updateTerm()
		} else {
			createTerm()
		}
	}
	const updateTerm = async () => {
		setLoading(true)

		const response = await authFetch(
			`${BASEURL}/pricing/ratecardterms/${termToUpdate.term_id}`,
			{
				m: 'PATCH',
				h: { 'Content-Type': 'application/json' },
				b: JSON.stringify({ start_date: startDate }),
			}
		)
		const data = await response.json()
		if (response.ok) {
			setTermToUpdate({ term_id: '', term_start_date: '' })
			setRateCardTerms((prevState) => {
				const rateCardIndex = prevState.findIndex((term) => {
					return term.id === termToUpdate.term_id
				})
				const newState = _.cloneDeep(prevState)
				newState[rateCardIndex]['start_date'] = startDate
				return newState
			})
		}
		setLoading(false)
	}

	const createTerm = async () => {
		setLoading(true)
		const response = await authFetch(`${BASEURL}/pricing/ratecardterms`, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ start_date: startDate, tier: id }),
		})
		const data = await response.json()
		setRateCardTerms([...rateCardTerms, data])
		setLoading(false)
	}

	const handleTierChange = (e) => {
		if (!oldState) setOldeState(rateCards.tier)
		setRateCards({ ...rateCards, tier: e.target.value })
	}
	const handleDateChange = (e) => {
		setStartDate(e.target.value)
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		submitTerm()
	}

	const isTierInFocus = () => {
		if (document.activeElement !== updateTierInputRef.current && oldState)
			setRateCards({ ...rateCards, tier: oldState })
	}

	return !rateCards ? null : !rateCardTerms ? null : (
		<div onClick={isTierInFocus} className="rate-card-page">
			<CreateEditRateCard
				rateCards={rateCards}
				setOldeState={setOldeState}
				handleDateChange={handleDateChange}
				handleSubmit={handleSubmit}
				handleTierChange={handleTierChange}
				setTermToUpdate={setTermToUpdate}
				loading={loading}
				updateTierInputRef={updateTierInputRef}
				termToUpdate={termToUpdate}
				startDate={startDate}
				setRateCardTerms={setRateCardTerms}
				rateCardTerms={rateCardTerms}
			/>
			<CustomersUsingGivenTier
				tier_terminus={rateCards.terminus}
				tier_id={rateCards.id}
			/>
		</div>
	)
}

export default RateCards
