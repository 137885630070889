const downloadFile = async (response) => {
	const blob = await response.blob()
	let a = document.createElement('a')
	a.href = URL.createObjectURL(blob)
	a.download = `${response.headers.get('filename')}`
	a.click()
	a.parentNode.removeChild(a)
}

export default downloadFile
