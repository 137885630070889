import React, { useState } from 'react'
import PopUpPage from '../../../../components/PopUpPage'
import { useParams } from 'react-router-dom'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import _ from 'lodash'
import MessageBanner from '../../../../components/MessageBanner'

const DisputesPopUp = ({ tableData, setTableData }) => {
	let { id } = useParams()
	const [shipmentInfo, setShipmentNote] = useState(
		tableData.filter((shipment) => {
			if (shipment.id == id) return shipment
		})[0]
	)
	const [formData, setFormData] = useState({
		notes: shipmentInfo.notes ? shipmentInfo.notes : null,
		is_sent: shipmentInfo.is_sent,
		is_open: shipmentInfo.is_open,
	})

	const [loading, setLoading] = useState(false)
	const [responseMessage, setResponseMessage] = useState([])

	const { authFetch } = FetchWithAuth()
	async function handleSubmit() {
		setLoading(true)
		const response = await authFetch(`${BASEURL}/carriers/dhl/disputes/${id}`, {
			m: 'PATCH',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify(formData),
		})
		setLoading(false)
		if (response.ok) {
			setTableData((prevState) => {
				const shipmentIndex = prevState.findIndex((shipment) => {
					return shipment.id == id
				})
				const newState = _.cloneDeep(prevState)
				newState[shipmentIndex]['notes'] = formData.notes
				return newState
			})
			setResponseMessage(['Note Updated Successfully'])
		} else {
			setResponseMessage([response.statusText])
		}
	}
	function handleChange(e) {
		setFormData({ ...formData, [e.target.name]: e.target.value ? e.target.value : null })
	}

	return (
		<>
			{!responseMessage.length > 0 ? null : (
				<MessageBanner
					message={responseMessage}
					setResponseMessage={setResponseMessage}
				></MessageBanner>
			)}
			<PopUpPage>
				<div className="add-dispute-note">
					<lable>Note For Shipment invoice {shipmentInfo.invoice_line_id}</lable>
					<textarea
						type="text"
						name="notes"
						class="valid-input feedback-input"
						value={formData.notes}
						onChange={(e) => handleChange(e)}
					></textarea>
					<div className="side-by-side-checkbox">
						<lable
							className="check-box-container"
							onClick={() => setFormData({ ...formData, is_sent: !formData.is_sent })}
						>
							was sent to DHL
							<input
								className="feedback-input"
								type="checkbox"
								name="is_sent"
								checked={formData.is_sent}
							></input>
							<span className="checkmark"></span>
						</lable>

						<lable
							className="check-box-container"
							onClick={() => setFormData({ ...formData, is_open: !formData.is_open })}
						>
							is open dispute
							<input type="checkbox" name="is_open" checked={formData.is_open}></input>
							<span className="checkmark"></span>
						</lable>
					</div>

					<button
						class="tier_submit-button"
						onClick={() => handleSubmit(formData)}
						disabled={false}
					>
						{loading ? <i class="fas fa-spinner check-icon loading-icon"></i> : 'Submit'}
					</button>
				</div>
			</PopUpPage>
		</>
	)
}

export default DisputesPopUp
