import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './fonts/fontawesome-free-6.0.0-web/css/all.min.css'
import './App.css'
import { useLoggedIn } from './context/context-loggedIn'
import { ProtectedRoutes } from './Auth/ProtectedRoutes'
import { PublicRoutes } from './Auth/PublicRoutes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { HeaderContextProvider } from './context/HeaderVisibleContext'
const queryClient = new QueryClient()

const App = () => {
	const loggedIn = useLoggedIn()
	return (
		<QueryClientProvider client={queryClient}>
			<HeaderContextProvider>
				{loggedIn ? <ProtectedRoutes /> : <PublicRoutes />}
			</HeaderContextProvider>
		</QueryClientProvider>
	)
}

export default App
