import React from 'react'
import { Link } from 'react-router-dom'

const OsmPage = () => {
	return (
		<div className="osm-page_wrapper">
			<h1>this is the new osm page</h1>
			{/* class name add-carrier-service is being borrowed till page wire frame is complete*/}
			<div className="osm-page-link-container">
				<Link className="carrier-menu-buttons" to={`/carriers/osm/legacy-process`}>
					legacy process
				</Link>
				<Link className="carrier-menu-buttons" to={`/carriers/osm/invoices`}>
					Invoice List
				</Link>
				<Link to={`/carriers/osm/shipping_services`} className="carrier-menu-buttons">
					Shipping Services
				</Link>
			</div>
		</div>
	)
}

export default OsmPage
