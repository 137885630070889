import { Switch, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import EditCustomer from './EditCustomer'
import CustomerCarriers from './CustomerCarriers'
import customers from '../style/customers.css'
import CustomerInvoices from './CustomerInvoices'
import useInitialLoad from '../../../hooks/useInitialLoad'
import CustomerInvoiceChart from '../../Carriers/dhl/components/CustomerInvoiceChart'
function CustomerPage() {
	const { id } = useParams()
	const [customer, setCustomer] = useInitialLoad(`/customers/${id}`)
	const [invoices, setInvoices, Reload, loading] = useInitialLoad(
		`/carriers/dhl/customer_invoices?customer_id=${id}&offset=0&limit=53`
	)

	function formatString(timeString) {
		const formattedDate = new Date(timeString)
		return formattedDate.toLocaleDateString()
	}
	return !customer || !invoices ? null : (
		<div className="customer-page">
			<div className="customer-info-container">
				<div className="customer-info-card">
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Company Name</span>
						</div>
						<div className="customer-value-data">
							<span>{customer.name}</span>
						</div>
					</div>

					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Billing Name</span>
						</div>
						<div className="customer-value-data">
							<span>
								{customer.billing_contact_name
									? customer.billing_contact_name
									: 'No Data'}
							</span>
						</div>
					</div>
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Billing Number</span>
						</div>
						<div className="customer-value-data">
							<span>
								{customer.billing_contact_phone
									? customer.billing_contact_phone
									: 'No Data'}
							</span>
						</div>
					</div>
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Billing Email</span>
						</div>
						<div className="customer-value-data">
							<span>
								{customer.billing_contact_email
									? customer.billing_contact_email
									: 'No Data'}
							</span>
						</div>
					</div>
				</div>
				<div className="customer-info-card">
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>
								Credit Card Fee
								{customer.fee_description ? ' / Description' : null}
							</span>
						</div>
						<div className="customer-value-data">
							<span>
								{customer.fee ? customer.fee : 'No Data'}

								{customer.fee_description ? (
									<>
										<span> ({customer.fee_description})</span>
									</>
								) : null}
							</span>
						</div>
					</div>
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Payment Method</span>
						</div>
						<div className="customer-value-data">
							<span>{customer.payment_method}</span>
						</div>
					</div>
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Mailer ID</span>
						</div>
						<div className="customer-value-data">
							<span>{customer.mailer_id}</span>
						</div>
					</div>
					<div className="customer-data-continuer">
						<div className="customer-key-data">
							<span>Active Account</span>
						</div>
						<div className="customer-value-data">
							<span>{customer.active ? 'True' : 'False'}</span>
						</div>
					</div>
				</div>

				<div className="customer-info-edit-section">
					<div>
						<span className="created-date">Created at:</span>
						<span>{formatString(customer.created)}</span>
					</div>
					<Link to={`/customers/${customer.id}/edit`} className="edit-button">
						<i className="fas fa-pencil-alt"></i>
						<span>Edit</span>
					</Link>
					<div>
						<span className="last-modified-date">Last Modified:</span>
						<span className="time">{formatString(customer.modified)}</span>
					</div>
				</div>
			</div>
			<div className="carriers-invoices">
				<CustomerInvoices customer_id={id} invoices={invoices} />
				<CustomerCarriers customer_id={id} />
			</div>
			{invoices.length < 1 ? null : (
				<div className="invoice-chart">
					<CustomerInvoiceChart invoices={invoices} />
				</div>
			)}

			<Switch>
				<Route path="/customers/:id(\d+)/edit">
					<EditCustomer customer={customer} setCustomer={setCustomer} />
				</Route>
			</Switch>
		</div>
	)
}

export default CustomerPage
