import React, { useState } from "react"

function Accordion({ title, value, term, setTermToUpdate, children }) {
  const [isOpen, setIsOpen] = useState(false)
  
  function handleClick() {
      setTermToUpdate((prevState) => {
        return {term_id: term.id, term_start_date: term.start_date}
      })
  }


  return (
    <div class="accordion" key={term.id}>
      <div
        class="accordion-title"
        onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <div>
          <span>
            <b> {title}: </b>
          </span>
          <span> {value}</span>
        </div>

        <div className="accordion-input" onClick={(e) => e.stopPropagation()}>
          <div class="order_info_data edit-enabled">
            start Date:
            <span class="order_info_data_values"> {term.start_date}</span>
            <span
              onClick={() => handleClick()}
              class="fas fa-pencil-alt edit-icons"></span>
          </div>
        </div>

        <div class="fas fa-sort-down arrow-toggle" aria-expanded={isOpen}></div>
      </div>

      <div className="accordion-content" aria-expanded={isOpen}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
