import React, { useState, useEffect } from 'react'
import { ZenventoryTable } from './ZenventoryTable'
import { FetchWithAuth } from '../../hooks/FetchWithAuth'
import { BASEURL } from '../../global-resources'

const Zenventory = () => {
	const [logData, setLogData] = useState([])
	const { authFetch } = FetchWithAuth()

	useEffect(() => {
		const fetchNSet = async () => {
			const response = await authFetch(BASEURL + '/api/dhl/zenventory_log')
			const data = await response.json()
			setLogData(data)
		}
		fetchNSet()
	}, [])

	const handleClick = async (e) => {
		e.preventDefault()
		alert("I didn't set this up yet")
	}

	return (
		<div className="zenventory-page">
			<h2>Zenventory Logs</h2>
			<button onClick={handleClick}>Run Zenventory</button>
			<ZenventoryTable data={logData} />
		</div>
	)
}

export default Zenventory
