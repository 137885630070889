import { Switch, Route } from 'react-router-dom'
import osm from './osm.css'
import DhlRoutes from './dhl/DhlRoutes'
import OsmRoutes from './osm/OsmRoutes'

const CarrierRoutes = () => {
	return (
		<Switch>
			<Route path={'/carriers/dhl'}>
				<DhlRoutes />
			</Route>
			<Route path={'/carriers/osm'}>
				<OsmRoutes />
			</Route>
		</Switch>
	)
}

export default CarrierRoutes
