import { useState } from 'react'
import { useFormik } from 'formik'
import _ from 'lodash'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import downloadCSVfromJsonResponse from '../../../../Functions/downloadCSVfromJsonResponse'
import { copyTextToClipboard } from '../../../../hooks/copyText'
import { useSnackbar } from 'react-simple-snackbar'
import { options1 } from '../../../../settingFiles/snackbarSettings'
const CustomerInvoiceCells = ({
	td,
	uniqueKeyProp,
	disableEdit_bol,
	invoiceId,
	columHeader,
	headers,
	setEditedFields,
	dataObject,
	setResponseMessage,
	rowButtonName,
	submitCorrectedCells,
	setSums,
	objectIndex,
}) => {
	const [loading, setLoading] = useState(false)
	const [invoiceSent, setInvoiceSent] = useState(false)
	const { authFetch } = FetchWithAuth()
	const [openSnackbar, closeSnackbar] = useSnackbar(options1)

	const formik = useFormik({
		initialValues: {
			[uniqueKeyProp]: td ? td : null,
		},
	})
	async function handleDownload(invoiceId) {
		if (columHeader === 'customer_invoice_id') {
			const response = await authFetch(
				`${BASEURL}/carriers/dhl/customer_invoices/${invoiceId}/download`
			)
			const data = await response.json()
			if (response.ok) {
				downloadCSVfromJsonResponse(data.file)
			}
		}
	}
	const handleBlur = (valueName) => {
		// What is happening??   we check if the value has changed from it initial state before adding its cell content to be submitted
		// if the value was changed before formiks.touched value will be set to true and all subsequent edits will be added to be submitted
		if (
			formik.initialValues[valueName] != formik.values[valueName] ||
			Object.values(formik.touched)[0] === true
		) {
			formik.setTouched({ [valueName]: true })

			setEditedFields((prevState) => {
				const newState = _.cloneDeep(prevState)
				const shipmentIndex = newState.findIndex((shipment) => {
					return shipment.id === invoiceId
				})
				// if this shipment was not edited yet create a new object for it in our array else add to existing object
				if (shipmentIndex === -1) {
					newState.push({ id: invoiceId, [columHeader]: formik.values[uniqueKeyProp] })
				} else {
					newState[shipmentIndex][columHeader] = formik.values[uniqueKeyProp]
					// if the user typed something and reversed his decision we remove the value from editedFields state
					if (newState[shipmentIndex][columHeader] == formik.initialValues[valueName]) {
						delete newState[shipmentIndex][columHeader]
						// if it is the last cell in the object delete the entire object
						if (Object.keys(newState[shipmentIndex]).length < 2) {
							newState.splice(shipmentIndex, 1)
						}
					}
				}
				return newState
			})
		}
	}

	const handleInputChange = (e) => {
		if (e.target.value === '') {
			formik.setFieldValue([e.target.name], null)
		} else {
			formik.setFieldValue([e.target.name], e.target.value)
		}
	}

	function handleCopy(dataToCopy) {
		copyTextToClipboard(dataToCopy)
		openSnackbar(<h2>{`${dataToCopy}: Copied to Clipboard`}</h2>, [2000])
	}

	function sevenDaysFromNow(td) {
		var date = new Date(td)
		date.setDate(date.getDate() + 7)
		return date.toLocaleDateString()
	}
	const handleClick = async () => {
		setSums((prevState) => {
			console.log('in prevState')
			let newState = _.cloneDeep(prevState)
			newState[objectIndex]['sent'] = !newState[objectIndex]['sent']
			console.log(newState)
			return newState
		})

		setLoading(true)
		// if the rowButtonName is not 'send' we are summing it is 'unsend and we want to mark it as false'
		const body = JSON.stringify({ sent: rowButtonName == 'Send' ? true : false })
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/customer_invoices/${invoiceId}`,
			{
				m: 'PATCH',
				b: body,
				h: { 'Content-Type': 'application/json' },
			}
		)
		setLoading(false)
		if (response.ok) {
			setResponseMessage(['Successfully Updated Invoice Status'])
			setInvoiceSent(true)
			// if it is the send button submit the quickbooks number at the same time
			if (rowButtonName == 'Send') submitCorrectedCells()
			// setReload((prevState) => prevState + 1)
		} else {
			setResponseMessage([response.statusText])
		}
	}
	{
	}

	return (
		<>
			<td
				className="error-table-tooltip"
				onClick={() => handleDownload(dataObject.customer_invoice_id)}
			>
				{/* this condition is to compensate the back end not returning a proper start and finish date. we take the date and ad 7 days to it to get the second date value */}
				{disableEdit_bol &&
				columHeader != 'billing_period' &&
				columHeader != 'customer_invoice_id' ? (
					<div onClick={() => handleCopy(td)}>{td}</div>
				) : columHeader === 'billing_period' ? (
					<>
						<div
							onClick={() =>
								handleCopy(
									`Billing Period ${new Date(
										td.split('-').join('/')
									).toLocaleDateString()} - ${sevenDaysFromNow(td)}`
								)
							}
						>
							<span className="invoice-billing-period-start">
								{`${new Date(td.split('-').join('/')).toLocaleDateString()} `}
							</span>
							-
							<span className="invoice-billing-period-end">{`${sevenDaysFromNow(
								td
							)}`}</span>
						</div>
					</>
				) : columHeader === 'customer_invoice_id' ? (
					<>
						{td}
						<i class="fa-solid fa-file-arrow-down"></i>
					</>
				) : (
					<input
						className={'editable-table-input'}
						id={uniqueKeyProp}
						name={uniqueKeyProp}
						type={disableEdit_bol ? 'text' : 'number'}
						onChange={(e) => handleInputChange(e)}
						value={formik.values[uniqueKeyProp]}
						onBlur={() => handleBlur(uniqueKeyProp)}
						disabled={disableEdit_bol}
					/>
				)}
				{headers.indexOf(columHeader) == headers.length - 1 ? (
					<button
						disabled={invoiceSent}
						onClick={() => handleClick()}
						className="error-table-tooltip-button"
					>
						{loading ? (
							<i class="fas fa-spinner check-icon loading-icon"></i>
						) : invoiceSent ? (
							'X'
						) : (
							rowButtonName
						)}
					</button>
				) : null}
			</td>
		</>
	)
}

export default CustomerInvoiceCells
