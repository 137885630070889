import { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import validate, { formatPhoneNumber } from '../functions/FormValidations'
import { FetchWithAuth } from '../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../global-resources'
import snakeCaseToTitleCase from '../../../Functions/snakeCaseToTitleCase.js'
import _ from 'lodash'
import ErrorBoundary from '../../../components/ErrorBoundary'

function AddNewCustomer({ customers, setCustomers }) {
	const [isLoading, setIsLoading] = useState(false)
	const [errors, setErrors] = useState({})
	const { authFetch } = FetchWithAuth()
	const history = useHistory()

	//https://formik.org/docs/tutorial
	const formik = useFormik({
		initialValues: {
			billing_contact_name: '',
			billing_contact_phone: '',
			billing_contact_email: '',
			name: '',
			payment_method: 'ACH',
			fee: 0.02,
			mailer_id: '',
			fee_description: '',
		},
		validate,
		onSubmit: (values) => {
			createCustomer()
		},
	})
	function onPhoneNumberChange(n) {
		const number = n.target.value
		formik.setFieldValue('billing_contact_phone', formatPhoneNumber(number))
	}

	const handleResponse = async (response) => {
		const data = await response.json()
		if (response.status === 201) {
			setCustomers((prevState) => {
				const newState = _.cloneDeep(prevState)
				newState.push(data)
				return newState
			})
			history.push(`/customers/${response.id}`)
		} else {
			console.log(data)
			setErrors(data)
		}
		setIsLoading(false)
	}

	function createCustomer() {
		setIsLoading(true)
		authFetch(`${BASEURL}/customers/`, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify(formik.values),
		}).then((response) => handleResponse(response))
	}

	function errorsFrag() {
		const objectKeys = Object.keys(errors)
		let formattedErrors = objectKeys.map((keyName) => {
			return (
				<p>
					<span class="error-name">{snakeCaseToTitleCase(keyName)} </span>:{' '}
					<span class="error-value">{errors[keyName]}</span>
				</p>
			)
		})
		return formattedErrors
	}

	return (
		<div class="window-overlay" onClick={() => window.history.go(-1)}>
			<div class="window" onClick={(e) => e.stopPropagation()}>
				<div class="sign-up-form">
					<a href="javascript:history.back()">
						<i class="far fa-times-circle"></i>
					</a>
					<ErrorBoundary>
						<form onSubmit={formik.handleSubmit}>
							<div class="side-by-side-input-fields">
								<div class="right-input-fields">
									<label htmlFor="name">Company Name</label>
									<input
										class={
											!formik.errors.name
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="name"
										name="name"
										type="text"
										{...formik.getFieldProps('name')}
									/>

									{formik.touched.name && formik.errors.name ? (
										<div class="form-error-message">{formik.errors.name}</div>
									) : null}

									<label htmlFor="name">Billing Name</label>
									<input
										class={
											!formik.errors.billing_contact_name
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="billing_contact_name"
										name="billing_contact_name"
										type="text"
										{...formik.getFieldProps('billing_contact_name')}
									/>

									{formik.touched.billing_contact_name &&
									formik.errors.billing_contact_name ? (
										<div class="form-error-message">
											{formik.errors.billing_contact_name}
										</div>
									) : null}

									<label htmlFor="fee">Credit Card Fee</label>
									<input
										class={
											!formik.errors.fee ? 'valid-input feedback-input' : 'feedback-input'
										}
										id="fee"
										name=" fee"
										type="text"
										{...formik.getFieldProps('fee')}
									/>
									{formik.touched.fee && formik.errors.fee ? (
										<div class="form-error-message">{formik.errors.fee}</div>
									) : null}
									<label htmlFor="fee">Fee Description</label>
									<input
										class={
											!formik.errors.fee_description
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="fee_description"
										name="fee_description"
										type="text"
										{...formik.getFieldProps('fee_description')}
									/>
								</div>

								<div class="left-input-fields">
									<label htmlFor="billing_contact_phone">Customer Phone Number</label>
									<input
										class={
											!formik.errors.billing_contact_phone
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="billing_contact_phone"
										name="billing_contact_phone"
										type="text"
										onChange={(n) => onPhoneNumberChange(n)}
										onBlur={formik.handleBlur}
										value={formik.values.billing_contact_phone}
										// {...formik.getFieldProps('billing_contact_phone')}
									/>

									{formik.touched.billing_contact_phone &&
									formik.errors.billing_contact_phone ? (
										<div class="form-error-message">
											{formik.errors.billing_contact_phone}
										</div>
									) : null}

									<label htmlFor="billing_contact_email">Billing Email</label>
									<input
										class={
											!formik.errors.billing_contact_email
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="billing_contact_email"
										name="billing_contact_email"
										type="billing_contact_email"
										{...formik.getFieldProps('billing_contact_email')}
									/>

									{formik.touched.billing_contact_email &&
									formik.errors.billing_contact_email ? (
										<div class="form-error-message">
											{formik.errors.billing_contact_email}
										</div>
									) : null}

									<label htmlFor="email">Mailer id</label>
									<input
										class={
											!formik.errors.email
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										id="mailer_id"
										name="mailer_id"
										type="mailer_id"
										{...formik.getFieldProps('mailer_id')}
									/>
									<label htmlFor="paymentMethod">Payment Method</label>
									<select
										style={{ width: '110%' }}
										class={
											!formik.errors.paymentMethod
												? 'valid-input feedback-input'
												: 'feedback-input'
										}
										name="paymentMethod"
										id="paymentMethod"
										{...formik.getFieldProps('paymentMethod')}
									>
										<option>ACH</option>
										<option>Credit Card</option>
									</select>
								</div>
							</div>

							<div class="bottom-center-of-form">
								{/* <label htmlFor="paymentMethod">Payment Method</label>
								<select
									class={
										!formik.errors.paymentMethod
											? 'valid-input feedback-input'
											: 'feedback-input'
									}
									name="paymentMethod"
									id="paymentMethod"
									{...formik.getFieldProps('paymentMethod')}
								>
									<option>ACH</option>
									<option>Credit Card</option>
								</select> */}

								<button type="submit">
									{isLoading ? (
										<i class="fas fa-spinner check-icon loading-icon"></i>
									) : (
										'Submit'
									)}
								</button>
								{errorsFrag()}
							</div>
						</form>
					</ErrorBoundary>
				</div>
			</div>
		</div>
	)
}

export default AddNewCustomer
