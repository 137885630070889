import React from 'react'
import InvoiceList from './InvoiceList'

const CustomerInvoices = ({ customer_id, invoices }) => {
	// this is only necessary because the useInitialLoad default state is false instead of a empty array
	if (!invoices) {
		return <h1>No Data</h1>
	}
	return (
		<fieldset className="invoices-container">
			<legend>Invoices</legend>
			{invoices.length < 1 ? (
				'No Invoices'
			) : (
				<div className="customer-invoice-list">
					<div className="card-headers">
						<span className="invoice-id">Invoice Id</span>
						<span className="invoice-data">Date</span>
						<span className="invoice-total">Amount</span>
					</div>
					<InvoiceList invoices={invoices} />
				</div>
			)}
		</fieldset>
	)
}

export default CustomerInvoices
