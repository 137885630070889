import useOffsetDimensions from '../../../../hooks/useOffsetDimensions'
import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
const DhlShipmentsErrorsList = ({ shipmentData, setResponseMessage }) => {
	const { ref, divWidth } = useOffsetDimensions()
	const { authFetch } = FetchWithAuth()
	const history = useHistory()
	const { id } = useParams()
	const [loading, setLoading] = useState()
	const [shipmentsDisputed, setShipmentsDisputed] = useState([])
	function handleClick(newIndex) {
		history.push(`/carriers/dhl/invoices/errors/${newIndex}`)
	}

	const handleDispute = async (e, shipmentIndex) => {
		e.stopPropagation()
		setLoading(true)
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/disputes/create?invoice_line_id=${shipmentData[shipmentIndex].id}`,
			{
				m: 'POST',
				h: { 'Content-Type': 'application/json' },
			}
		)
		setLoading(false)

		if (response.ok) {
			setResponseMessage(['Successfully Created Dispute For Invoice'])
			setShipmentsDisputed((state) => [...state, shipmentData[shipmentIndex].id])
		} else {
			setResponseMessage([response.statusText])
		}
	}

	function formatDateString(dateToFormat) {
		const stringWithDelimiters = new Date(
			dateToFormat.replace(/(\d+)(\d{2})(\d{2})/g, '$1/$2/$3')
		).toLocaleDateString('en-us')
		return stringWithDelimiters
	}
	function renderShipmentFrag() {
		return shipmentData.map((shipment, index) => {
			return (
				<div
					className="shipment-card"
					onClick={() => handleClick(index)}
					style={{
						backgroundColor: index == id ? 'rgb(125 223 186 / 67%)' : 'rgb(219 208 216)',
					}}
				>
					<div className="shipment-title1-warper">
						<div className="shipment-card-title">delivery conf:</div>
						<div className="shipment-card-data">
							{shipment.delivery_confirmation_number}
						</div>
					</div>
					<div className="shipment-card-title">customer conf: </div>
					<div className="shipment-card-data">
						{shipment.customer_confirmation_number}
					</div>

					<div className="shipment-title-dates-warper">
						<div className="shipment-title-warper">
							<div className="shipment-data-title">Ship Date</div>
							<div className="shipment-data-detail">
								{shipmentData[index].ship_date
									? formatDateString(shipmentData[index].ship_date)
									: 'No Data'}
							</div>
						</div>

						<button
							disabled={shipmentsDisputed.includes(shipmentData[index].id)}
							onClick={(e) => handleDispute(e, index)}
							className="dhl-dispute-button"
						>
							{loading ? (
								<i class="fas fa-spinner check-icon loading-icon"></i>
							) : shipmentsDisputed.includes(shipmentData[index].id) ? (
								'X'
							) : (
								'Dispute'
							)}
						</button>
						<div className="shipment-title-warper">
							<div className="shipment-data-title">Ship period</div>
							<div className="shipment-data-detail">
								{shipmentData[index].pricing_period
									? formatDateString(shipmentData[index].pricing_period)
									: 'No Data'}
							</div>
						</div>
					</div>
				</div>
			)
		})
	}

	return shipmentData.length < 1 ? null : (
		<div className="shipments-list" ref={ref}>
			<div className="shipments-list-header" style={{ minWidth: divWidth - 8 }}>
				Shipments
			</div>
			<div className="shipment-list-container">{renderShipmentFrag()}</div>
		</div>
	)
}

export default DhlShipmentsErrorsList
