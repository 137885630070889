//folder for all API request Functions

import { BASEURL } from '../global-resources'
import axios from 'axios'

export async function submitFormData(formDataToSend) {
    //for new customer form - takes data, sends to api, handles errors and returns response
    return new Promise(resolve => setTimeout(resolve, 2000, { status: 200, message: 'successful form submission' }))
}

export function submitServerRequest(requestType) {

    //let url = BASEURL + '/dhl/';

    const getUrl = (requestType) => {
        switch (requestType) {
            case 'scan emails':
                console.log('scan');
                return 'process_emails'
            case 'ftp scan':
                console.log('ftp');
                return 'scan_ftp'
            case 'scheduler':
                console.log('scheduler');
                return 'schedule'
            case 'file upload status':
                console.log('file upload status');
                return 'gmail_reports_statuses'
            case 'fetch all invoices':
                return 'invoices'
            case 'fetch zenventory logs':
                return 'zenventory_log'
            default:
                break
        }
    }

    return axiosRequest(getUrl(requestType))
}

export const axiosRequest = (path, method = 'get', body = {}, config = {}) => {
    //function to handle all requests to include auth etc.n
    const url = BASEURL + '/api/dhl/' + path
    config.timeout = 300000
    body = method === 'get' ? null : body;

    return new Promise((resolve, reject) => {
        axios[method](url, body, config)
            .then(response => {
                console.log(response);
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    }
    )
}