import React, { useState, useEffect } from 'react'
import TableHeader from './TableHeader'
import { errorTableHeaders } from './errorTableHeaders'
import UnprocessedTableRows from './UnproccesdTableRows'
import useInitialLoad from '../../../../hooks/useInitialLoad'

const UnprocessedShipmentsTable = ({
	offset,
	setOffset,
	pageLimit,
	tableData,
	setReload,
	loading,
}) => {
	const fetchNextPage = (offset) => {
		if (offset >= 0) {
			setOffset(offset)
			setReload((prev) => prev + 1)
		}
	}
	if (tableData) {
		if (tableData.length < 1 && loading == false)
			return (
				<div className="error-table_submit-button">
					<h1>Sorry No Data 😭</h1>
				</div>
			)
	}
	return !tableData ? (
		<i className="fas fa-spinner check-icon loading-icon large"></i>
	) : (
		<>
			<div className="paginate-buttons">
				<div className="table-title-refresh">
					<div
						className="paginate-table-refresh refresh-tooltip"
						onClick={() => setReload((prev) => prev + 1)}
					>
						Unprocessed Shipments
						<span class="refresh-tooltiptext">Click to Refresh Table</span>
					</div>
				</div>
				<div className="pagination-effects">
					<button
						className="paginate-button"
						onClick={() => fetchNextPage(offset - pageLimit)}
						disabled={offset == 0}
					>
						Prev
					</button>
					<div className="paginate-page-number">
						Page <b>{offset / pageLimit + 1}</b>
					</div>
					<button
						className="paginate-button"
						onClick={() => fetchNextPage(offset + pageLimit)}
						disabled={tableData.length < pageLimit}
					>
						Next
					</button>
				</div>
			</div>
			<div className="unprocessed-shipments-table error-table">
				<table>
					<TableHeader
						headers={Object.keys(errorTableHeaders)}
						headerObject={errorTableHeaders}
					/>
					<UnprocessedTableRows
						tableData={tableData}
						tableHeaders={Object.keys(errorTableHeaders)}
					/>
				</table>
			</div>
		</>
	)
}

export default UnprocessedShipmentsTable
