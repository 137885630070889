import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import Dashboard from '../components/Dashboard'
import NavComponent from '../components/NavComponent'
import NoInternet from '../components/NoInternet'
import Zenventory from '../pages/Zenventory/Zenventory'

import CustomersRouts from '../pages/Customers/CustomersRouts'
import RateCardRouts from '../pages/CarrierPricing/RateCardRouts'

import BackButton from '../components/BackButton'
import CarrierRoutes from '../pages/Carriers/CarrierRoutes'
import QuoterRouts from '../pages/quoter/QuoterRouts'

export const ProtectedRoutes = () => {
	return (
		<>
			<SnackbarProvider>
				<NavComponent />
				{/* <BackButton /> */}
				<Switch>
					<Route path="/customers">
						<CustomersRouts />
					</Route>
					<Route path="/carriers">
						<CarrierRoutes />
					</Route>
					<Route path="/pricing">
						<RateCardRouts />
					</Route>
					<Route path="/ratecards">
						<RateCardRouts />
					</Route>
					<Route path="/quoter">
						<QuoterRouts />
					</Route>
					<Route path="/zenventory">
						<Zenventory />
					</Route>
					<Route path="/home">
						<Dashboard />
					</Route>

					<Route path="/NoInternet">
						<NoInternet />
					</Route>
					<Route path="/">
						<Redirect to="/home" />
					</Route>
				</Switch>
			</SnackbarProvider>
		</>
	)
}
