function snakeCaseToTitleCase(name) {
	const nameWithoutUnderscores = name.replace(/_/g, ' ')
	const nameAsArray = nameWithoutUnderscores.split(' ')
	for (let i = 0; i < nameAsArray.length; i++) {
		nameAsArray[i] = nameAsArray[i][0].toUpperCase() + nameAsArray[i].substr(1)
	}
	return nameAsArray.join(' ')
}

export default snakeCaseToTitleCase
