import { useState } from 'react'
import { useAuthFetch } from '../../../../hooks/useAuthFetch'
import { BASEURL } from '../../../../global-resources'
import { DownloadCsvButton } from '../../../../components/IndividualComponents/DownloadCsvButton'

const OsmBillProcess = () => {
	const { authFetch } = useAuthFetch()
	const [invoice_number, setInvoice_number] = useState()
	const [load_to_db, setLoad_to_db] = useState('')
	const [trackingIdsToIgnore, set_tracking_ids_to_ignore] = useState('')
	const [invoices, setInvoices] = useState()
	const [downloadButton, setDownloadButton] = useState()

	const processOsm = async (e) => {
		e.preventDefault()
		// 1: remove Wight space form the string 2: turn the string into a array based on where the comma is
		let tracking_ids_to_ignore = JSON.stringify(
			trackingIdsToIgnore.replace(' ', '').replace('\n', '').split(',')
		)
		const formData = new FormData()
		const state = { invoice_number, load_to_db, invoices, tracking_ids_to_ignore }
		Object.entries(state).forEach(([key, value]) => {
			formData.set(key, value)
		})
		const url = BASEURL + '/api/osm/run'
		const response = await authFetch(url, {
			m: 'POST',
			b: formData,
		})

		if (response.headers.get('content-type') === 'text/csv');
		const blob = await response.blob()
		setDownloadButton(<DownloadCsvButton blob={blob} />)
	}

	return (
		<div style={{ marginTop: '11%' }}>
			<div>
				<h1>OSM Bill Process</h1>
			</div>
			<div>
				<form onSubmit={processOsm}>
					<input
						placeholder="Invoice Number"
						value={invoice_number}
						onChange={({ target }) => setInvoice_number(target.value)}
					/>
					<br></br>
					<input type="file" onChange={({ target }) => setInvoices(target.files[0])} />
					<br></br>
					<label>Write to SQL</label>
					<input
						type="checkbox"
						checked={load_to_db}
						onChange={({ target }) => setLoad_to_db(target.checked ? true : '')}
					/>
					<div>tracking ids to ignore (example input 1111,2222,3333)</div>
					<textarea
						rows="6"
						cols="45"
						value={trackingIdsToIgnore}
						onChange={({ target }) => set_tracking_ids_to_ignore(target.value)}
					></textarea>
					<br></br>
					<button variant="primary" type="submit">
						Submit
					</button>
				</form>
			</div>
			<div>{downloadButton ? downloadButton : null}</div>
		</div>
	)
}

export default OsmBillProcess
