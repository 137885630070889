import React, { useState } from 'react'
import { useFormik } from 'formik'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import _ from 'lodash'
import OsmFileUpload from './OsmFileUpload'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase.js'

const OsmInvoiceEdit = ({ invoice, setInvoice, id }) => {
	const { authFetch } = FetchWithAuth()
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState()
	const [success, setSuccess] = useState(false)

	const [fileToUpload, setFileToUpload] = useState()

	const createNewInvoice = async () => {
		const url = `${BASEURL}/osm/invoices`
		const response = await authFetch(url)
		return response.json()
	}

	const formik = useFormik({
		initialValues: {
			date: invoice.date,
			invoice_total: invoice.invoice_total,
			invoice_number: invoice.invoice_number,
			shipments_file: {
				name: invoice.shipments_file ? snakeCaseToTitleCase(invoice.shipments_file) : '',
			},
			invoice_file: {
				name: invoice.invoice_file ? snakeCaseToTitleCase(invoice.invoice_file) : '',
			},
		},
		onSubmit: async (values) => {
			const formData = new FormData()
			if (formik.initialValues.shipments_file.name != values.shipments_file.name)
				formData.set('shipments_file', values.shipments_file)
			if (formik.initialValues.invoice_file.name != values.invoice_file.name)
				formData.set('invoice_file', values.invoice_file)
			formData.set('date', values.date)
			formData.set('invoice_total', values.invoice_total)
			formData.set('invoice_number', values.invoice_number)

			const url = `${BASEURL}/osm/invoices/${id}`
			const response = await authFetch(url, {
				m: 'PATCH',
				b: formData,
			})
			const data = await response.json()
			if (response.status !== 400 && !response.ok) {
				setErrors(response.statusText)
			}

			if (response.status === 400) {
				const errorMessage = Object.values(data)[0][0]
				setErrors(errorMessage)
			}
			if (response.ok) {
				setFileToUpload('')
				setSuccess(true)
				setInvoice((prevState) => {
					return data
				})
			}
		},
	})
	return (
		<form onSubmit={formik.handleSubmit} style={{ marginTop: '4%' }}>
			<div class="side-by-side-input-fields osm-side-by-side">
				<div class="right-input-fields">
					<label htmlFor="invoice_number">Invoice Number</label>
					<input
						className={
							!formik.errors.invoice_number
								? 'valid-input feedback-input'
								: 'feedback-input'
						}
						id="invoice_number"
						name="invoice_number"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invoice_number}
					/>
				</div>
				<div class="left-input-fields">
					<label htmlFor="terminus">Invoice Total</label>
					<input
						className={
							!formik.errors.invoice_total
								? 'valid-input feedback-input'
								: 'feedback-input'
						}
						id="invoice_total"
						name="invoice_total"
						type="number"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invoice_total}
					/>
				</div>
			</div>
			<div className="centerd-inputs">
				<OsmFileUpload
					setErrors={setErrors}
					setSuccess={setSuccess}
					formik={formik}
					stateName={'shipments_file'}
					allowedFileTypes={'.csv,.xlsx'}
				/>
				<OsmFileUpload
					setErrors={setErrors}
					setSuccess={setSuccess}
					formik={formik}
					stateName={'invoice_file'}
					allowedFileTypes={'.pdf'}
				/>
				<div>
					<label htmlFor="date">date</label>
					<input
						className={
							!formik.errors.date
								? 'valid-input feedback-input osm-calender'
								: 'feedback-input osm-calender'
						}
						id="date"
						name="date"
						type="date"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.date}
					/>
				</div>
			</div>

			<div className="centered-button">
				<button disabled={formik.values.invoice_number === ''} type="submit">
					{loading ? (
						<i className="fas fa-spinner check-icon loading-icon"></i>
					) : errors ? (
						<span className="error-text">{errors}</span>
					) : success ? (
						<span className="success-text">Success</span>
					) : (
						'Upload'
					)}
				</button>
			</div>
		</form>
	)
}

export default OsmInvoiceEdit
