import React, { useState } from 'react'
import useInitialLoad from '../../../../hooks/useInitialLoad'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import PopUpPage from '../../../../components/PopUpPage'
import OsmInvoiceEdit from './OsmInvoiceEdit'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import downloadFile from '../../../../Functions/downloadFile'
import isFileDownload from '../../../../Functions/isFileDownload'

const OsmInvoice = () => {
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	const [invoice, setInvoice] = useInitialLoad(`/osm/invoices/${id}`)

	const handleInvoiceLineToDatabase = async () => {
		const response = await authFetch(`${BASEURL}/osm/invoices/${id}/load_lines`)
	}

	const handleClick = async (fileName) => {
		const response = await authFetch(`${BASEURL}/api/download/${invoice[fileName]}`)

		if (response.ok && isFileDownload(response)) {
			downloadFile(response)
		}
	}

	return !invoice ? null : (
		<div className="osm-invoice_page">
			<h1>Invoice Page</h1>
			{/* class name add-carrier-service is being borrowed till page wire frame is complete*/}
			<Link className="add-carrier-service" to={`/carriers/osm/invoices/${id}/edit`}>
				Edit Invoice {`${invoice.invoice_number}`}
			</Link>
			<Link className="add-carrier-service" onClick={() => handleClick('shipments_file')}>
				Download Invoice <b>Lines xl</b>
			</Link>
			<Link className="add-carrier-service" onClick={() => handleClick('invoice_file')}>
				Download Invoice <b>PDF</b>
			</Link>
			<br />
			<br />
			<Link className="add-carrier-service" onClick={handleInvoiceLineToDatabase}>
				Load Lines
			</Link>
			<Switch>
				<Route path={`/carriers/osm/invoices/${id}/edit`}>
					<PopUpPage>
						<OsmInvoiceEdit invoice={invoice} setInvoice={setInvoice} id={id} />
					</PopUpPage>
				</Route>
			</Switch>
		</div>
	)
}

export default OsmInvoice
