import ProfitReport from './ProfitReport'
import { Link } from 'react-router-dom'
const DhlPage = () => {
	return (
		<div className="dhl-page-wrapper">
			<h1>DHL</h1>
			<div className="carrier-page">
				<button>
					<Link to="/carriers/dhl/shipment-detail/0">Shipment Search</Link>
				</button>
				<ProfitReport />
			</div>
		</div>
	)
}

export default DhlPage
