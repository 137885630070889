import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import {AuthProvider} from './auth-context'
import { UserProvider } from './context-loggedIn'

const AppProviders = ({children}) => {
    return (
        <AuthProvider>
          <UserProvider >
            <BrowserRouter>
              {children}
            </BrowserRouter>
          </UserProvider>
        </AuthProvider>
      )
}

export default AppProviders