import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { useParams } from 'react-router'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import { BASEURL } from '../../../../global-resources'
import MessageBanner from '../../../../components/MessageBanner'
const LegacyInvoiceProcessButtons = () => {
	const { id } = useParams()
	const { authFetch } = FetchWithAuth()
	const url = `/api/dhl/invoices/${id}`
	const [processResults, setProcessResults] = useState()
	const [responseMessage, setResponseMessage] = useState([])
	const [loading, setLoading] = useState(false)

	const handleInvoiceProcess = async (e) => {
		const { name } = e.target
		setLoading(true)
		const response = await authFetch(BASEURL + url, {
			m: 'POST',
			h: { 'Content-Type': 'application/json' },
			b: JSON.stringify({ method: name }),
		})
		if (!response.ok) {
			setLoading(false)
			setResponseMessage([response.statusText])
		}
		const data = await response.json()
		setProcessResults(data)
	}

	return (
		<>
			{responseMessage.length > 0 ? (
				<MessageBanner
					type="error"
					// grab the first error in the array to be displayed
					message={responseMessage[0]}
					calloutType="Error"
					setResponseMessage={setResponseMessage}
				/>
			) : null}

			<div class="invoice-actions">
				<button class="invoice_cards" onClick={handleInvoiceProcess} name="dry_run">
					Dry Run
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="insert_to_sql_run"
				>
					SQL Run
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="correct_history_accounts"
				>
					Correct Historical Records
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="create_customer_shipments_list"
				>
					Create Customer Shipments List
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="create_sum_file"
				>
					Create Sum File
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="create_dispute_file"
				>
					Create Disputes File
				</button>
				<button
					class="invoice_cards"
					onClick={handleInvoiceProcess}
					name="update_profit_report"
				>
					Update Profit Report
				</button>
			</div>

			<div id="jsonResponse">
				{!processResults && loading === false ? null : !processResults &&
				  loading === true ? (
					<i id="table-loader" class="fas fa-spinner check-icon loading-icon large"></i>
				) : (
					<>
						<div style={{ marginLeft: '1%' }}>
							<ReactJson src={processResults} collapsed={true} name={false} />
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default LegacyInvoiceProcessButtons
