import { useState } from 'react'
import DhlShipmentErrorDetailEdit from './DhlShipmentErrorDetailEdit'
import MessageBanner from '../../../../components/MessageBanner'

import {
	categoryOrderAndNames,
	dhlErrorShipmentsTableMap,
} from '../dhlErrorShipmentsTableMap'

import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase'

const DhlShipmentErrorDetailCategory = ({
	shipmentData,
	categoryHeader,
	categoryIndex,
	selectedCard,
	setShipmentsSearchResults,
	isDetailPage,
}) => {
	const [responseMessage, setResponseMessage] = useState([])
	function renderRow() {
		let isFlaggedCell = false
		return dhlErrorShipmentsTableMap[categoryIndex][categoryHeader].map((tableRow) => {
			return (
				<div className="error-shipment-row">
					<div className="error-shipment-cell stated">
						<div className="cell-name">
							{tableRow[0] ? snakeCaseToTitleCase(tableRow[0]) : null}
						</div>
						<div className="cell-data">{shipmentData[tableRow[0]]}</div>
					</div>
					{
						(isFlaggedCell = shipmentData.flag_summery
							? shipmentData.flag_summary.includes(tableRow[1])
							: false)
					}
					<div
						className="error-shipment-cell calculated"
						style={{
							backgroundColor: isFlaggedCell ? 'rgb(255 150 150)' : 'transparent',
						}}
					>
						<div className="cell-name">
							{tableRow[1] ? snakeCaseToTitleCase(tableRow[1]) : null}
						</div>
						<div className="cell-data">{shipmentData[tableRow[1]]}</div>
					</div>

					{/* the manual row will be be turned into its own component to store all the 
					state associated with holding state of the cell and sending the post request to the server */}
					{
						(isFlaggedCell = shipmentData.flag_summery
							? shipmentData.flag_summary.includes(tableRow[2])
							: false)
					}
					<div className="error-shipment-cell manual">
						<div className="cell-name">
							{tableRow[2] ? snakeCaseToTitleCase(tableRow[2]) : null}
						</div>
						{tableRow[2] ? (
							<>
								<DhlShipmentErrorDetailEdit
									shipmentId={shipmentData.id}
									shipmentData={shipmentData}
									cellData={shipmentData[tableRow[2]]}
									cellName={tableRow[2]}
									selectedCard={selectedCard}
									setResponseMessage={setResponseMessage}
									setShipmentsSearchResults={setShipmentsSearchResults}
									highlight={isFlaggedCell}
									isDetailPage={isDetailPage}
								/>
							</>
						) : null}
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{responseMessage.length > 0 ? (
				<MessageBanner
					message={responseMessage[0]}
					setResponseMessage={setResponseMessage}
				/>
			) : null}
			<div className="category-box">
				<div className="error-section-header">{snakeCaseToTitleCase(categoryHeader)}</div>
				{renderRow()}
			</div>
		</>
	)
}

export default DhlShipmentErrorDetailCategory
