import { useAuth2 } from '../context/auth-context'
import { useQuery } from 'react-query'
import { BASEURL } from '../global-resources'
import { useState } from 'react'

export const FetchWithAuth = () => {
	const { logout, authToken } = useAuth2()
	const [loading, setLoading] = useState(false)
	const authFetch = async (url, { m = 'GET', h, b } = {}) => {
		const headers = {
			Authorization: `Bearer ${authToken()}`,
			Accept: '*/*',
			...h,
		}
		setLoading(true)
		const response = await fetch(url, {
			method: m,
			headers,
			body: b,
		})
		if (response.status === 401) {
			return logout()
		}
		if (response.status === 403) {
			return logout()
		}
		setLoading(false)
		return response
	}

	return { authFetch, useQuery, loading }
}

// EXAMPLE OF HOW TO USE.        FIRST IMPORT, THEN INSTANTIATE THE HOOK THEN, CREATE A FUNCTION WHITCH WILL CAN BE CALLED BY
// useQuery's SECOND ARGUMENT, THEN INSTANTIATE the useQuery hook itself as shown below.

// import { useFetchWithAuth } from '../../hooks'

// const { authFetch, useQuery } = useFetchWithAuth()

// const fetchMissingTierTerminus = async () => {
// 	const response = await authFetch(missing_url)
// 	console.log(response)
// 	return response.json()
// }

// const { isLoading, error, data } = useQuery('tierData', fetchMissingTierTerminus)
