import { useEffect, useState } from 'react'
import { BASEURL } from '../../../../global-resources'
import { FetchWithAuth } from '../../../../hooks/FetchWithAuth'
import snakeCaseToTitleCase from '../../../../Functions/snakeCaseToTitleCase'
const DhlDisputesList = ({ shipmentsSearchResults }) => {
	const { authFetch } = FetchWithAuth()
	const [disputes, setDisputes] = useState()
	// console.log(disputes)
	useEffect(() => {
		if (shipmentsSearchResults[0].id != undefined) {
			fetchDisputes()
		}
	}, [shipmentsSearchResults])

	async function fetchDisputes() {
		const response = await authFetch(
			`${BASEURL}/carriers/dhl/disputes?invoice_line_id=${shipmentsSearchResults[0].id}`
		)
		if (response.ok) {
			const jsonRes = await response.json()
			setDisputes(jsonRes)
		}
	}
	function renderDisputeListFrag() {
		return disputes.map((dispute, index) => {
			return (
				<div className="dispute-list-details-warper">
					<div className="dispute-list_details">
						<span className="dispute_detail_name">Dispute Name: </span>
						<span className="dispute_detail">
							{snakeCaseToTitleCase(disputes[index].dispute_name)}
						</span>
					</div>
					<div className="dispute-list_details">
						<span className="dispute_detail_name"></span>
						<span className="dispute_detail"></span>
					</div>
					<div className="dispute-list_details">
						<span className="dispute_detail_name">Reason: </span>
						<span className="dispute_detail">
							{disputes[index].reason ? disputes[index].reason : ' No Data'}
						</span>
					</div>
					<div className="dispute-list_details">
						<span className="dispute_detail_name">Stated Charge: </span>
						<span className="dispute_detail">
							{disputes[index].stated_charge ? disputes[index].stated_charge : ' No Data'}
						</span>
					</div>
					<div className="dispute-list_details">
						<span className="dispute_detail_name">Total Credit on Shipment</span>
						<span className="dispute_detail">
							{disputes[index].invoice_line__dhl_credits
								? disputes[index].invoice_line__dhl_credits
								: ' 0'}
						</span>
					</div>
				</div>
			)
		})
	}

	return !disputes ? 'No Disputes' : <div>{renderDisputeListFrag()}</div>
}

export default DhlDisputesList
